<template>
  <div class="pa-4">
    <v-row v-if="loading">
      <loadingComponent
        @closeDialog="closeLoading($event)"
        :loadingProps="loading"
        :messageDialogeProps="messageDialoge"
        :dialogProps="dialog"
        :errorProps="errorModal"
      />
    </v-row>
    <div class="mx-2" v-if="recievedResult && foundResultat == 'True' && listCompteurSelected.length > 0 && siret !== null">
        <PdfPresentation 
            :pdfData="resultBudgetSolar"
        />
    </div>
  <div class="d-flex flex-row justify-center" v-if="recievedResult">
    <v-card v-if="foundResultat == 'True'" class="my-4 pb-16" width="100%" color="white">
      <div class="d-flex flex-row justify-center" style="margin-bottom:30px">
        <v-card :style="`border-radius:0px;border:2px ${$colors[0]} solid`" class="ma-4" width="50%" min-height="100%">
          <v-toolbar
            
            :style="{background:$colors[0]}"
            dark>
            <span class="pl-0">Informations Globales</span>
          </v-toolbar>
          <div class="d-flex flex-row  ma-8">
            <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
              <v-icon class="white--text" size="40">mdi-poll</v-icon>
            </div>
            <div class="d-flex flex-column ml-4">
              <span>Consommation Globale</span>
              <span class="Poppins-Bold" style="font-size:20px">{{ formatNumber(resultBudgetSolar["sommeConsommation"]) }} KWh</span>
            </div>
          </div>
          <div class="d-flex flex-row  ma-8">
            <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
              <v-icon class="white--text" size="40">mdi-chart-bell-curve-cumulative</v-icon>
            </div>
            <div class="d-flex flex-column ml-4">
              <span>Production Globale</span>
              <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeProduction"]) }} KWh</span>
            </div>
          </div>
          <div class="d-flex flex-row  ma-8">
            <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
              <v-icon class="white--text" size="40">mdi-finance</v-icon>
            </div>
            <div class="d-flex flex-column ml-4">
              <span>Surplus Global</span>
              <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["Reste_A_Vendre"]) }} kWh</span>
            </div>
          </div>
          <div class="d-flex flex-row  ma-8">
            <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
              <v-icon class="white--text" size="40">mdi-timer-sand-complete</v-icon>
            </div>
            <div class="d-flex flex-column ml-4">
              <span>Heures de Surplus</span>
              <span class="Poppins-Bold" style="font-size:20px">{{ formatNumber(resultBudgetSolar["Nbr_heures_Vendre"]) }}</span>
            </div>
          </div>
        </v-card>
         <v-card :style="`border-radius:0px;border:2px ${$colors[0]} solid`" class="ma-4" width="50%" min-height="100%">
            <v-toolbar
              
              :style="{background:$colors[0]}"
              dark>
              <span class="pl-0">Autoconsommation </span>
            </v-toolbar>
            <div class="d-flex flex-row  ma-8">
              <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
                <v-icon class="white--text" size="40">mdi-city-variant-outline</v-icon>
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Autoconsommation Collective</span>
                <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeAutoCollectif"]) }} KWh</span>
              </div>
            </div>
            <div class="d-flex flex-row  ma-8">
              <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
                <v-icon class="white--text" size="40">mdi-domain</v-icon>
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Autoconsommation Individuelle</span>
                <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeAutoConsommerIndividuelle"]) }} KWh</span>
              </div>
            </div>
            <div class="d-flex flex-row  ma-8">
              <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
                <v-icon class="white--text" size="40">mdi-lightning-bolt</v-icon>
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Autoconsommation Totale</span>
                <span class="Poppins-Bold" style="font-size:20px">{{
                    formatNumber(resultBudgetSolar["sommeAutoCollectifEtIndividuelleGlobale"])
                  }} KWh</span>
              </div>
            </div>
            <div class="d-flex flex-row  ma-8">
              <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
                <v-icon class="white--text" size="40">mdi-currency-eur</v-icon>
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Budget Énergie Initial</span>
                <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeBudgetInitial"]) }} €/an</span>
              </div>
            </div>
        </v-card>
      </div>
      <v-card class="ma-4" :style="`border:2px solid ${$colors[0]}`">
      <v-toolbar
          
          :style="{background:$colors[0]}"
          dark>
          <span class="pl-0">Budget</span>
        </v-toolbar>
        <div style="height:inherit" class="d-flex flex-row justify-space-between  ma-8">
          <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
            <v-icon class="white--text" size="40">mdi-currency-eur</v-icon>
          </div>
          <div class="d-flex flex-column">
            <span>Budget Énergie Autoconsommation Collective</span>
            <span class="Poppins-Bold" style="font-size:20px">{{ formatNumber(resultBudgetSolar["sommeBudgetSolarAutoCollectif"]) }} €/an</span>
          </div>
          <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
            <v-icon class="white--text" size="40">mdi-currency-eur</v-icon>
          </div>
          <div class="d-flex flex-column">
            <!--<span>sommeTAXE</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeTAXE"]) }} €/an</span>
            <span>sommeFOURNITURE</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeFOURNITURE"]) }} €/an</span>
            <span>sommeCTA</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeCTA"]) }} €/an</span>
            <span>sommeCSPE</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeCSPE"]) }} €/an</span>
            <span>sommeTVA</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeTVA"]) }} €/an</span>
            <span>sommeTurpeValue</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeTurpeValue"]) }} €/an</span>-->
            <span>Budget Énergie Fournisseur & Turpe</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["Budget_energie_total"]) }} €/an</span>
          </div>
          <div class="d-flex flex-row justify-center align-items-center" :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`">
            <v-icon class="white--text" size="40">mdi-currency-eur</v-icon>
          </div>
          <div class="d-flex flex-column">
            <span>Budget Énergie Final</span>
            <span class="Poppins-Bold" style="font-size:20px">{{
                formatNumber(
                  resultBudgetSolar["Budget_energie_total"] +
                    resultBudgetSolar["sommeBudgetSolarAutoCollectif"]
                )
              }} €/an</span>
          </div>
        </div>
      </v-card>
      <div class="d-flex flex-row ma-4" v-if="recievedResult">
            <v-btn @click="showTable = !showTable" :color="$colors[0]" class="white--text">Voir Details</v-btn>
        </div>
    </v-card>
    
    <div v-else-if="foundResultat == 'False'" class="result-message-card">
      <p class="result-message-title">le calcule est en traitement lorsque le calcule va finir tu va recevoir un email</p>
    </div>
    
    <div v-else-if="foundResultat == 'enTraitement'" class="result-message-card">
      <p class="result-message-title">il y a au moins un calcule lancer avec c'est informations il faut attendre les resultat par email</p>
    </div>
  </div>
  <div class="pb-2" v-if="showTable && recievedResult && foundResultat == 'True'">
    <v-card class="pa-2">
    <div class="d-flex flex-column mb-4">
      <small class="text-muted">* ACC = Autoconsommation Collective</small>
      <small class="text-muted">* AC = Autoconsommation</small>
      <small class="text-muted">* FRN = Fournisseur</small>
    </div>
      <v-data-table
        :headers="headers"
        hide-default-header
        :items="Object.values(resultBudgetSolar['listeCompteur'])"
        :items-per-page="10"
      >
         <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th class="text-center" v-for="h in headers" style="border-left:1px solid white;border-right:1px solid white;width:9rem">
              <span>{{h.text}}</span>
            </th>
          </tr>
        </thead>
    </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.Numero_Compteur">
              <td class="text-center" style="min-width:130px">
                <div><span>{{ item.Numero_Compteur }}</span></div>
                <div><small class="text-muted">{{ item.typeProduction }}</small></div>
              </td>
              <td class="text-center" style="min-width:130px">
                <div><small class="text-muted">Conso: </small>{{ formatNumber(item.Consommation) }} Kwh</div>
                <div><small class="text-muted">Prod: </small>{{ formatNumber(item.Production) }} Kwh</div>
              </td>
              <td class="text-center" style="min-width:130px">
                <div><small class="text-muted">AC Global: </small>{{ formatNumber(item.Auto_Consommation_Total) }} Kwh</div>
                <div><small class="text-muted">AC Indiv: </small>{{ formatNumber(item.sommeAutoConsommerIndividuelle) }} Kwh</div>
                <div><small class="text-muted">AC Collec: </small>{{ formatNumber(item.sommeAutoConsommerIndividuelle) }} Kwh</div>
              </td>
              <td class="text-center" style="min-width:130px">
                <div><small class="text-muted">Conso Frn: </small>{{ formatNumber(item.Reste_A_Acheter) }} Kwh</div>
              </td>
              <td class="text-center" style="min-width:130px">
                <div><small class="text-muted">TAXE: </small>{{ formatNumber(item.TAXEInitial) }} €/an</div>
                <div><small class="text-muted">FOURNITURE: </small>{{ formatNumber(item.FOURNITUREInitial) }} €/an</div>
                <div><small class="text-muted">CTA: </small>{{ formatNumber(item.CTAInitial) }} €/an</div>
                <div><small class="text-muted">CSPE: </small>{{ formatNumber(item.CSPEInitial) }} €/an</div>
                <div><small class="text-muted">Acheminement: </small>{{ formatNumber(item.TurpeValueInitial) }} €/an</div>
                <div><small class="text-muted">TVA: </small>{{ formatNumber(item.TVAInitial) }} €/an</div>
              </td>
              <td class="text-center" style="min-width:130px">
                <div><small class="text-muted">Budget Init: </small>{{ formatNumber(item.budgetInitial) }} €/an</div>
              </td>
              <td class="text-center" style="min-width:130px">
                <div><small class="text-muted">TAXE: </small>{{ formatNumber(item.TAXE) }} €/an</div>
                <div><small class="text-muted">FOURNITURE: </small>{{ formatNumber(item.FOURNITURE) }} €/an</div>
                <div><small class="text-muted">CTA: </small>{{ formatNumber(item.CTA) }} €/an</div>
                <div><small class="text-muted">CSPE: </small>{{ formatNumber(item.CSPE) }} €/an</div>
                <div><small class="text-muted">Acheminement: </small>{{ formatNumber(item.TurpeValue) }} €/an</div>
                <div><small class="text-muted">TVA: </small>{{ formatNumber(item.TVA) }} €/an</div>
              </td>
              <td class="text-center" style="min-width:130px">
                <div><small class="text-muted">Budget FRN: </small>{{ formatNumber(item.Budget_Energie) }} €/an</div>
              </td>
              <td class="text-center" style="min-width:130px">
                <div><small class="text-muted">Budget ACC: </small>{{ formatNumber(item.budgetSolarAutoCollectif) }} €/an</div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      </v-card>
    </div>
  </div>
  
</template>
<script>
import axios from "@/plugins/axios";
import { background } from "vue-plotly";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import loadingComponent from "@/components/Loading";
import html2canvas from "html2canvas";
import PdfPresentation from "../pdfTemplates/PdfPresentation.vue";
export default {
  name: "viewResultatSolarCollectif",
  components: {
    loadingComponent,
    PdfPresentation,
  },
  data() {
    return {
      foundResultat: false,
      showTable: false,
      loading: false,
      messageDialoge: "",
      dialog: false,
      errorModal: "",
      siret: null,
      listCompteur: [],
      listCompteurSelected: [],
      listSiret: [],
      siretFound: false,
      recievedResult: false,
      resultBudgetSolar: {},
      headers: [
        {
          text: "Compteur",
          align: "center",
          sortable: false,
          thStyle:"border-right:1px solid white"
        },
        {
          text: "Type",
          align: "center",
          sortable: false,
        },
        {
          text: "Consommation Initial",
          align: "center",
          sortable: false,
        },
        {
          text: "Production",
          align: "center",
          sortable: false,
        },
        {
          text: "ACC Global",
          align: "center",
          sortable: false,
        },
        {
          text: "AC Individuelle",
          align: "center",
          sortable: false,
        },
        {
          text: "ACC",
          align: "center",
          sortable: false,
        },
        {
          text: "Conso FRN",
          align: "center",
          sortable: false,
        },
        {
          text: "Budget Initial",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    await this.sendPrixData();
  },
  methods: {
    
    formatNumber(input) {
      input = "" + input;
      // Remove non-numeric characters (comma or quotes) and parse as float
      const numericValue = parseFloat(input.replace(",", ""));

      // Check if the parsed value is a valid number
      if (!isNaN(numericValue)) {
        // Format the number using the French locale
        return numericValue.toLocaleString("fr-FR");
      } else {
        // Handle invalid input, for example, return the input as is
        return input;
      }
    },
    async sendPrixData() {
        this.recievedResult = false;
        this.loading = true;
        this.dialog = true;
        this.errorModal = false;
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getResultatSolarCollectif/",
          method: "POST",
          data: {
            token: this.$cookies.get("token"),
            //nomInstalation: this.nomInstalation,
            nomInstalation: this.$route.params.id,
            
          },
        })
        .then(async (response) => {
        if (response.data.length != 0) {
            this.foundResultat =  response.data.foundResultat
            this.recievedResult = true;
            this.resultBudgetSolar = response.data;
            this.loading = false;
            this.dialog = true;
            this.errorModal = true;
        } else {
            this.loading = false;
            this.dialog = false;
            this.errorModal = false;
        }
        })
        .catch((err) => {
        console.log(err);
        this.loading = false;
        this.dialog = false;
        this.errorModal = false;
        });
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input--selection-controls__input .v-icon{
  font-size:large;
}
::v-deep .v-input--selection-controls .v-radio>.v-label{
  margin-bottom:0px !important;
}
.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table th,
.styled-table td {
  width: 100px;
  min-width: 100px;
  border: 1px solid #ddd;
  padding: 8px;
}

.styled-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4ca8af;
  color: white;
}

.card_compteur_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(var(20, 218, 245), 0.1);
  padding: 16px;
  border-radius: 8px;
  .card_compteur {
    position: relative;
    display: block;
    overflow-x: auto;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 150ms;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 8px 24px rgba(89, 112, 145, 0.12);

    .compteur-table {
      width: 100%;
      display: table;
      border-collapse: collapse;
      box-sizing: border-box;
      text-indent: initial;
      border-spacing: 1px;
      border-radius: 6px;
      overflow: hidden;

      * {
        position: relative;
      }

      thead tr {
        background-color: rgb(4, 177, 161);
        font-size: 16px;
        color: #fff;
      }

      tbody tr {
        background-color: rgb(237, 242, 242);
        border-bottom: 1px solid rgb(221, 228, 228);
        &:last-child {
          border: 0;
        }
      }

      td,
      th {
        padding: 16px 8px;
        text-align: left;
        border: 1px solid rgb(221, 228, 228);
        min-width: 100px;
        &:first-child {
          width: 50px;
        }
      }
      th {
        font-size: 0.875rem;
        font-weight: 700;
      }
      td {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}

.v-data-table--loading .v-data-table__td {
  opacity: 1;
}

:deep(.v-data-table) {
  .v-table__wrapper > table tbody > tr > td {
    border-bottom: 1px solid #dee2e6 !important;
    border-left: 1px solid #dee2e6 !important;
    border-right: 0px solid !important;
  }
  .v-data-table__wrapper > table > thead {
    background-color: #04b1a1 !important;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    color: #fff !important;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    border-left: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
  }
}
</style>

<style lang="scss" scoped>
.result-message-card{
  width: 100%;
  background: white;
  margin-top: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0px 4px 8px 0px rgb(196 196 196 / 65%);
  .result-message-title{
    margin-bottom: 0px;
    color: #3aaab7;
  }
}
</style>
