import FormulaireCalcule from "../views/FormulaireCalcule";
import LoginComponent from "../views/Login";
import InfosCourbeCharge from "../components/InfosCourbeCharge";
import historiqueCalcul from "../views/historiqueCalcul";
import VTcalendar from "../views/CalendarVT/VTcalendar";
import VTCalendarIngenieur from "../views/TechCalendarVT/VTCalendarIngenieur";
import NouvelleOpportunite from "../views/NouvelleOpportunite";
// import sur les différentes views cotations
import ValidationDeCotation from "../views/DemandeDeCotation/ValidationDeCotation.vue";
import MonDemandeDeCotation from "../views/DemandeDeCotation/MonDemandeCotation.vue";
import DetailCompteur from "../views/DemandeDeCotation/DetailCompteur.vue";
import DetailCompteurSolar from "../views/solarCollectif/DetailCompteurSolar.vue";


import DetailSociete from "../views/DemandeDeCotation/DetailSociete.vue";
import DetailClientP from "../views/DemandeDeCotation/DetailClientP.vue";
import notFound from "../views/not-found";
import Vue from "vue";
import VueRouter from "vue-router";
import CookieService from "../services/cookie";
import Vendeurs from "../views/vendeurs/Vendeurs";

// import layout pour les routes avec noeuds (nested)
import ContratLayout from "@/layouts/ContratLayout.vue";
import CalendarLayout from "@/layouts/CalendarLayout";
import EquipeLayout from "@/layouts/EquipeLayout";
import DevisLayout from "@/layouts/DevisLayout.vue";
import VisiteTechniqueLayout from "@/layouts/VisiteTechniqueLayout.vue";
// import sur les différentes views équipes
import ajouterEquipe from "../views/equipe/ajouterEquipe.vue";
import listeEquipe from "../views/equipe/listeEquipe.vue";


// Devis
import EffectuerUnDevis from "@/views/devis/effectuerUnDevis.vue";
import tableDevis from "@/views/devis/table-devis.vue";
import listAPD from "@/views/devis/listAPD.vue";
import profilDevis from "@/views/devis/profilDevis.vue";
import profilAPD from "@/views/devis/profilAPD.vue";
import FourmulaireFaisabiliteNew from "@/views/formulaire-question-réponse/FourmulaireFaisabiliteNew.vue"
import FormulaireAvenantNew from "@/views/formulaire-question-réponse/FormulaireAvenantNew.vue"

// Visite Technique
import FormulaireVisiteTechnique from "@/views/VisiteTechnique/FormulaireVisiteTechnique.vue";
import ListeFormulaireVisiteTechnique from "@/views/VisiteTechnique/ListeFormulaireVisiteTechnique.vue";
import ProfilVisiteTechnique from "@/views/VisiteTechnique/ProfilVisiteTechnique.vue";
// Visite Installation
import ajoutVisiteInstallation from "@/views/VisiteInstallation/ajoutVisiteInstallation.vue";
import listeVisiteInstallation from "@/views/VisiteInstallation/listeVisiteInstallation.vue";
import ProfilVisiteInstallation from "@/views/VisiteInstallation/ProfilVisiteInstallation.vue";
// Calendar
import VInstallationcalendar from "@/views/CalendarVInstallation/VInstallationCalendar.vue";
import InstallationCalendar from "@/views/TechCalendarVT/InstallationCalendar.vue";
import VTChefIngCalendar from "@/views/ChefIngCalendarVT/VTCalendarChefIngenieur.vue"
import ChefIngVInstallationCalendar from "@/views/ChefIngCalendarVT/InstallationCalendarChefIng.vue"

// Contrat Travail
import AjouterUnEmployer from "@/views/ContratEmbauche/AjouterUnEmployer.vue"
import ListeContratEmbauche from "@/views/ContratEmbauche/ListeContratEmbauche.vue"

import Notifications from '@/views/notifications/Notifications.vue'

//solar
import PVGISform from '@/views/PVGIS/PVGISform.vue'
import results_PVGIS from '@/views/PVGIS/results_PVGIS.vue'
import SolarIndvPresentation from '@/views/PVGIS/pdfTemplates/presentationHTML/SolarIndvPresentation.vue'

//solar collectif
import NouvelleOpportuniteSolarCollectif from '@/views/solarCollectif/nouvelleOpportuniteSolarCollectif.vue'
import validationDemandeCotationSolar from '@/views/solarCollectif/validationDemandeCotationSolar.vue'
import CotationSolarCollectif from '@/views/solarCollectif/cotationSolar/cotationSolar.vue'
import viewResultatSolarCollectif from '@/views/solarCollectif/cotationSolar/viewResultatSolarCollectif.vue'
// import SolarCollectifPresentation from '@/views/solarCollectif/pdfTemplates/presentationHTML/SolarCollectifPresentation.vue'


// get userRole from cookie
Vue.use(VueRouter);
const routes = [
  
  //auth
  {
    path: "/login",
    component: LoginComponent,
    name: "Login",
  },
 
  //Nouvelle Opportunitée
  {
    path: "/nouvelle-opportunite",
    component: NouvelleOpportunite,
    name: "Nouvelle Opportunité",
    meta: {
      isLeftMenuItem: true,
      requiresAuth: true,
      roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
      icon: "mdi-cash-plus",
    },
  },
  //Validations de Cotations
  {
    path: "/validations-cotations",
    component: ValidationDeCotation,
    name: "Validations de Cotations",
    meta: {
      isLeftMenuItem: true,
      requiresAuth: true,
      roles: ["admin", "superadmin", "ADV","Chef équipe"],
      icon: "mdi-cash-check",
    },
  },
  //Mes Demande de Cotations
  {
    path: "/mes-demandes-cotations",
    component: MonDemandeDeCotation,
    name: "Mes Demandes de Cotations",
    meta: {
      isLeftMenuItem: true,
      requiresAuth: true,
      roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
      icon: "mdi-account-cash-outline",
    },
  },
  // Devis links
  {
    path: "/devis",
    component: DevisLayout,
    name: "Auto-Calc",
    meta: {
      isLeftMenuItem: true,
      requiresAuth: true,
      icon: "mdi-file-document-edit",
      roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur", "technicien", "ingenieur"],
    },

    children: [
      {
        path: "EffectuerUnDevis",
        component: EffectuerUnDevis,
        name: "Créer devis",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          icon: "mdi-file-document-edit",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      {
        path:"table-devis",
        component:tableDevis,
        name: "Tableau Devis",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          icon: "mdi-file-document-edit",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      {
        path:"list-apd",
        component: listAPD,
        name: "List APD",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          icon: "mdi-file-document-edit",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      {
        path:"profil-devis/:id",
        component: profilDevis,
        name: "Profil Devis",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          icon: "mdi-file-document-edit",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      {
        path:"profil-devis-apd/:id",
        component: profilAPD,
        name: "Profil Devis APD",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          icon: "mdi-file-document-edit",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      {
        path: "formulaire-validation/:id",
        component: FourmulaireFaisabiliteNew,
        name: "Formulaire Faisabilite",
        meta: {
          isLeftMenuItem: false,
          requiresAuth: true,
          icon: "mdi-file-table",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
        },
      },
      {
        path: "FormulaireAvenant",
        component: FormulaireAvenantNew,
        name: "Formulaire Avenant",
        meta: {
          isLeftMenuItem: false,
          requiresAuth: true,
          icon: "mdi-file-table",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
        },
      },
    ],
  },
  //solar
  {
    path: '/solar',
    name: 'Solar-Calc',
    component: PVGISform,
    props: true,
    meta: {
      isLeftMenuItem: true,
      requiresAuth: true,
      icon: 'mdi-home',
      roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
    },
  },
  {
    path: '/solar/resultat',
    name: 'results_PVGIS',
    component: results_PVGIS,
    props: true,
    meta: {
      isLeftMenuItem: false,
      requiresAuth: true,
      icon: 'mdi-home',
      roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
    },
  },
  {
    path: "/solar-indv-presentation/:token",
    component: SolarIndvPresentation,
    name: "Solar Individual Presentation",
  },
  // solar collectif link
  // {
  //   path: "/solar-collectif-presentation/:token",
  //   component: SolarCollectifPresentation,
  //   name: "Solar Collectif Presentation",
  // },
  {
    path: "/SolarCollectif",
    component: CalendarLayout,
    name: "Solar Collectif",
    meta: {
      isLeftMenuItem: true,
      requiresAuth: true,
      icon: "mdi-solar-power",
      roles: [
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "technicien",
        "ingenieur",
        "Prospecteur"
      ],
    },
    children: [
      {
        path: "nouvelle-opportunite",
        component: NouvelleOpportuniteSolarCollectif,
        name: "Nouvelle Opportunité",
        meta: {
          isLeftMenuItem: true,
          icon: "mdi-cash-plus",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      {
        path: "validationDemandeCotationSolar",
        component: validationDemandeCotationSolar,
        name: "Validations de Cotations",
        meta: {
          isLeftMenuItem: true,
          icon: "mdi-cash-check",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      {
        path: "CotationSolarCollectif",
        component: CotationSolarCollectif,
        name: "Cotation",
        meta: {
          isLeftMenuItem: true,
          icon: "mdi-calendar-cursor",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      {
        path: "viewResultatSolarCollectif/:id",
        component: viewResultatSolarCollectif,
        name: "Resultat Solar Collectif",
        meta: {
          isLeftMenuItem: false,
          icon: "mdi-calendar-cursor",
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        },
      },
      

      
    ],
  },
  //Detail Societe
  {
    path: "/detail-societe/:id",
    component: DetailSociete,
    name: "Detail Societe",
    props: true,
    meta: {
      isLeftMenuItem: false,
      roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
      requiresAuth: true,
      icon: "mdi-office-building-outline",
    },
  },
    //Detail client
    {
      path: "/detail-clientp/:id",
      component: DetailClientP,
      name: "Detail ClientP",
      props: true,
      meta: {
        isLeftMenuItem: false,
        roles: ["admin", "superadmin", "ADV", "Chef équipe", "Prospecteur"],
        requiresAuth: true,
        icon: "mdi-office-building-outline",
      },
    },
  //Detail Compteur
  {
    path: "/detail-compteur/:id",
    component: DetailCompteur,
    name: "Detail Compteur",
    props: true,
    meta: {
      isLeftMenuItem: false,
      requiresAuth: true,
      roles: ["admin", "superadmin", "ADV", "Chef équipe", "chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
      icon: "mdi-numeric",
    },
  },
  {
    path: "/detail-compteur-Solar/:id",
    component: DetailCompteurSolar,
    name: "Detail Compteur",
    props: true,
    meta: {
      isLeftMenuItem: false,
      requiresAuth: true,
      roles: ["admin", "superadmin", "ADV", "Chef équipe", "chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
      icon: "mdi-numeric",
    },
  },
  //Formulaire de Calcul
  {
    path: "/formulaire-calcul",
    component: FormulaireCalcule,
    name: "Formulaire de Calcul",
    meta: {
      isLeftMenuItem: false,
      requiresAuth: true,
      roles: ["admin", "superadmin", "Prospecteur", "ADV", "Chef équipe"],
      icon: "mdi-calculator",
    },
  },
  //Infos Courbe de Charge
  {
    path: "/infos-courbe",
    component: InfosCourbeCharge,
    name: "Infos Courbe de Charge",
    meta: {
      isLeftMenuItem: false,
      requiresAuth: true,
      roles: ["admin", "superadmin", "ADV", "Chef équipe"],
      icon: "mdi-file-chart",
    },
  },
  //Historique de Calcul
  {
    path: "/historique-calcul",
    component: historiqueCalcul,
    name: "Historique de Calcul",
    meta: {
      isLeftMenuItem: false,
      requiresAuth: true,
      roles: ["admin", "superadmin", "ADV", "Chef équipe"],
      icon: "mdi-file-table",
    },
  },
  // ****************************************
  // Visite Technique links
  {
    path: "/visite-technique",
    component: VisiteTechniqueLayout,
    name: "Visite Technique",
    meta: {
      isLeftMenuItem: false,
      requiresAuth: true,
      icon: "mdi-toolbox",
      roles: [
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "chefIngenieur",
        "technicien",
        "ingenieur",
      ],
    },
    children: [
      {
        path: "nouveau-formulaire",
        component: FormulaireVisiteTechnique,
        name: "Ajouter un formulaire",
        meta: {
          isLeftMenuItem: false,
          icon: "mdi-toolbox",
          roles: ["admin", "superadmin", "Prospecteur", "Chef équipe", "ADV"],
        },
      },
      {
        path: "profil/:id",
        component: ProfilVisiteTechnique,
        name: "Profil Visite Technique",
        meta: {
          isLeftMenuItem: false,
          icon: "mdi-toolbox",
          roles: [
            "admin",
            "superadmin",
            "ADV",
            "Chef équipe",
            "chefIngenieur",
            "technicien",
            "ingenieur",
          ],
        },
      },
    ],
  },
  //---- Visites Techniques liste
  {
    path: "/visite-technique/liste",
    component: ListeFormulaireVisiteTechnique,
    name: "Visites Techniques",
    meta: {
      isLeftMenuItem: true,
      icon: "mdi-toolbox",
      roles: [
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "chefIngenieur",
        "technicien",
        "ingenieur",
      ],
    },
  },
  // Visite Installation links
  {
    path: "/visite-installation/ajoutVisiteInstallation",
    component: ajoutVisiteInstallation,
    name: "Ajouter Visite Installation",
    meta: {
      isLeftMenuItem: false,
      icon: "mdi-hammer-screwdriver",
      roles: ["admin", "superadmin", "ADV", "Chef équipe"],
    },
  },
  //----- Visite Installation profil 
  {
    path: "/visite-installation/profil/:id",
    component: ProfilVisiteInstallation,
    name: "Profil Visite Installation",
    meta: {
      isLeftMenuItem: false,
      icon: "mdi-hammer-screwdriver",
      roles: [
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "chefIngenieur",
        "technicien",
        "ingenieur",
      ],
    },
  },
  //----- Visites Installations liste
  {
    path: "/visite-installation/liste",
    component: listeVisiteInstallation,
    name: "Visites d'installations",
    meta: {
      isLeftMenuItem: true,
      icon: "mdi-hammer-screwdriver",
      roles: [
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "chefIngenieur",
        "technicien",
        "ingenieur",
      ],
    },
  },
  // ****************************************
  // Calendrier links
  {
    path: "/Calendrier",
    component: CalendarLayout,
    name: "Prise de rendez-vous",
    meta: {
      isLeftMenuItem: true,
      requiresAuth: true,
      icon: "mdi-calendar-month",
      roles: [
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "technicien",
        "ingenieur",
        "chefIngenieur"
      ],
    },
    children: [
      {
        path: "VTcalendar",
        component: VTcalendar,
        name: "Visite Technique",
        meta: {
          isLeftMenuItem: true,
          icon: "mdi-calendar-cursor",
          roles: ["admin", "superadmin", "ADV", "Chef équipe"],
        },
      },
      {
        path: "ingenieurVTCalendar",
        component: VTCalendarIngenieur,
        name: "Visite Technique",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          roles: ["ingenieur", "technicien"],
          icon: "mdi-account-multiple-outline",
        },
      },
      {
        path: "chefIngVTCalendar",
        component: VTChefIngCalendar,
        name: "Visite Technique",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          roles: ["chefIngenieur"],
          icon: "mdi-account-multiple-outline",
        },

      },
      {
        path: "chefIngVInstallationCalendar",
        component: ChefIngVInstallationCalendar,
        name: "Visite d'Installation",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          roles: ["chefIngenieur"],
          icon: "mdi-account-multiple-outline",
        }
      },
      {
        path: "visiteInstallationCalendar",
        component: VInstallationcalendar,
        name: "Visite d'Installation",
        meta: {
          isLeftMenuItem: true,
          icon: "mdi-calendar-clock",
          roles: ["admin", "superadmin", "ADV", "Chef équipe"],
        },
      },
      {
        path: "installationTechCalendar",
        component: InstallationCalendar,
        name: "Visite d'installation",
        meta: {
          isLeftMenuItem: true,
          icon: "mdi-calendar-clock",
          roles: ["technicien", "ingenieur"],
        },
      },
    ],
  },
  // Personelle (ajouter equipe, list equipe, Vendeurs) links
  {
    path: "/Equipe",
    component: EquipeLayout,
    name: "Personnel",
    meta: {
      //isLeftMenuItem is visible on left menu or not
      isLeftMenuItem: true,
      requiresAuth: true,
      roles: ["Chef équipe", "admin", "superadmin", "ADV"],
      icon: "mdi-account-group",
    },
    children: [
      {
        path: "ajouterEquipe",
        name: "Ajouter une equipe",
        component: ajouterEquipe,
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          icon: "mdi-account-multiple-plus",
          roles: ["admin", "superadmin", "ADV"],
        },
      },
      {
        path: "gestion-equipes",
        name: "Equipes",
        component: listeEquipe,
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          icon: "mdi-account-group",
          roles: ["admin", "superadmin", "ADV"],
        },
      },
      {
        path: "vendeurs",
        name: "Vendeurs",
        component: Vendeurs,
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          roles: ["admin", "superadmin", "ADV", "Chef équipe"],
          icon: "mdi-account-multiple",
        },
      },
    ],
  },
  // Contrat Travail links
  {
    path: "/ContratTravail",
    component: ContratLayout,
    name: "Contrat de Travail",
    meta: {
      isLeftMenuItem: true,
      requiresAuth: false,
      icon: "mdi-file-sign",
      roles: ["admin", "superadmin"],
    },

    children: [
      {
        path: "Ajouter-Employer",
        component: AjouterUnEmployer,
        name: "Ajouter Un employé",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: false,
          roles: ["admin", "superadmin", "ADV", "Chef équipe", "chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
          icon: "mdi-account-tie-outline",
        },
      },
      {
        path: "Liste-Contrat-Embauche",
        component: ListeContratEmbauche,
        name: "Contrat d'embauche",
        meta: {
          isLeftMenuItem: true,
          requiresAuth: true,
          roles: ["admin", "superadmin"],
          icon: "mdi-text-box-multiple-outline",
        },
      },
    ],
  },

  // notifications
  {
    path: "/notifications",
    component: Notifications,
    name: "Notifications",
    props: true,
    meta: {
      isLeftMenuItem: false,
      requiresAuth: true,
    },
  },

  // not found 404 link
  { path: "*", component: notFound, name: "not-found" },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // get routes that require authentication
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // check user authenticated or not
  const isAuthenticated = CookieService.checkCookie();
  // get the user's role.
  let userRole = null;
  const compte = CookieService.getCookie('compte');
  if (compte && Object.keys(compte).length>0) {
    const user = JSON.parse(compte)
    userRole = user.type;
  }
  
  if (requiresAuth && (!isAuthenticated || !userRole)) {
    // If the route requires authentication and the user is not logged in
    // Redirect the user to the home page or the login page.
    next('/login');
  } else if (requiresAuth && isAuthenticated && userRole) {
    // If the route requires authentication and the user is logged in
    const requiredRoles = to.meta.roles;
    if (!requiredRoles || requiredRoles.includes(userRole)) {
      console.log(to)
      // If the user's role matches the required roles for the route
      next(); // Allow access to the route
    } else {
      // Redirect to the home page if the user's role doesn't match the required roles.
      next('/');
    }
  } else {
    // For non-protected routes, simply proceed to the next route.
    if (to.path === "/") {
      next({ name: "Login" });
    } else if (to.path === "/login" && isAuthenticated) {
      next("/nouvelle-opportunite");
    } else {
      next();
    }
  }

});

export default router;
