<template>
  <div>
    <div class="installation-item">
      <v-dialog v-model="deletePopup" max-width="500px">
        <v-card>
          <v-card-title class="headline">Supprimer Installation</v-card-title>
          <v-card-text>
            Etes vous sur de bien vouloir supprimer "{{ installation.name }}" ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="hideDeletePopup"
              >Annuler</v-btn
            >
            <v-btn color="red darken-1" text @click="confirmDelete"
              >Supprimer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="header" @click="toggleExpand">
        <p class="installation-title">{{ installation.name }} </p>
        <p class="installation-subtitle">
          <span>Total Watts Used:</span>
          <span class="ml-2">{{ totalWattsUsed }} Cwh <span v-if="production > 0"> / {{ production.toFixed(5) }} Kwh</span></span>
        </p>
      </div>
      <div v-if="selectedInstallation == installation.id" class="details">
        <p>
          <span class="label">Type:</span>
          <span class="value ml-2">{{ installation.type }}</span>
        </p>
        <p>
          <span class="label">Watts per Panel:</span>
          <span class="value ml-2">{{ installation.wattsPerPanel }} watts</span>
        </p>
        <p>
          <span class="label">Number of Panels:</span>
          <span class="value ml-2">{{ installation.nbrPanels }}</span>
        </p>
        <div class="d-flex flex-row align-center">
          <span class="label">Taille d'installation:</span>
          <span class="value ml-2">{{ polyGonesObject.area.toFixed(2) }} m²</span>

        </div>
        <p>
          <span class="label">Longitude:</span>
          <span class="value ml-2">{{ polyGonesObject.longitude }} </span>
        </p>
        <p>
          <span class="label">Latitude:</span>
          <span class="value ml-2">{{ polyGonesObject.latitude }} </span>
        </p>
        <p>
          <span class="label">Angle d'orientation: </span>
          <input
            v-model="panelDetails.angle"
            @input="updateOrientation"
            max="360"
            min="0"
            type="number"
          />
        </p>
        <p>
          <span class="label">Pourcentage de perte: </span>
          <input
          style="max-width: 100px"
            v-model="panelDetails.lossPercentage"
            @input="updateLossPercentage"
            type="number"
          />
        </p>
        <p>
          <span class="label">Puissance de crête: </span>
          <input
          style="max-width: 100px"

            v-model="panelDetails.peakPower"
            @input="updatePeakPower"
            type="number"
          />
        </p>
        <p >
          <span class="label">Production: </span>
          <span>{{ production }}</span>
        </p>
      </div>
      <div
        v-if="selectedInstallation == installation.id"
        class="installations-item-btns-wrapper"
      >
        <div
          v-if="drawed && installation.nbrPanels == 0"
          class="item-btn addPanelBtn"
          @click.stop="addPanel"
        >
          <span class="mdi mdi-solar-panel-large " style="font-size: 14px;"></span>
        </div>
  
        <div
          v-if="
            (polyGonesObject.polyGones.length === 0 ||
              (polyGonesObject.polyGones.length > 0 &&
                polyGonesObject.installationId == selectedInstallation)) &&
            !drawed
          "
          class="item-btn btnDrawStart"
          @click="startDrawing"
          @mouseover="showTooltip = true"
          @mouseleave="showTooltip = false"
        >
          <span class="mdi mdi-set-square icon-size"></span>
          <div v-if="showTooltip" class="tooltip">
            <span> Activate drawing mode </span>
          </div>
        </div>
  
        <!-- <button class="item-btn btnDrawStart deactivate" v-else>
          <span class="mdi mdi-set-square"></span>
          <div v-if="showTooltip" class="tooltip">
            <span> Activate drawing mode </span>
          </div>
        </button> -->
  
        <div
          v-if="
            (polyGonesObject.polyGones.length === 0 ||
              (polyGonesObject.polyGones.length > 0 &&
                polyGonesObject.installationId == selectedInstallation)) &&
            !drawed
          "
          class="item-btn btnDrawStop"
          @click="stopDrawing"
        >
          <span class="mdi mdi-cancel icon-size"></span>
        </div>
        <div
          class="item-btn btnDrawDelete"
          @click="showDeletePopup"
          @mouseover="showTooltipDelete = true"
          @mouseleave="showTooltipDelete = false"
        >
          <span class="mdi mdi-delete-outline icon-size"></span>
          <div v-if="showTooltipDelete" class="tooltip">
            <span> Delete Installation </span>
          </div>
        </div>
      </div>
      
    </div>

    <div
      class="prod-installation-btn"
      v-if="drawed && installation.nbrPanels > 0"
      @click="sendInstallation()"
    >
      <span class="mdi mdi-plus"></span>
      <span>Calculer Production</span>
    </div>
  </div>
   
  </template>
  
  <script>
  import axios from "axios";
  export default {
    

    props: ["installation", "map", "selectedInstallation"],
    data() {
      return {
        showTooltip: false,
        showTooltipDelete: false,
        showTooltipStopDraw: false,
        hasDrawnPolygon: false,
        production: 0,
        deletePopup: false, // Set to false initially
        isExpanded: false,
        polyGonesObject: {
          polyGones: [],
          area: 0,
          longitude: 0,
          latitude: 0,
          showPolygons: false,
          installationId: null,
        },
        drawingManager: null,
        drawed: false,
        panelDetails: {
          angle: this.installation.orientation,
          lossPercentage: this.installation.lossPercentage,
          peakPower: this.installation.peakPower,
        },
        production:null,
      };
    },
    mounted() {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        position: google.maps.ControlPosition.TOP_CENTER,
        polygonOptions: {
          draggable: false,
          strokeColor: "#0569db",
          fillColor: "#4c85c7",
          fillOpacity: 0.35,
          strokeWeight: 2,
          clickable: true,
          zIndex: 10,
        },

      });
      this.drawingManager.setMap(this.map);
      google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        (event) => {
          if (event.type == google.maps.drawing.OverlayType.POLYGON) {
            this.handlePolygonComplete(event.overlay);
          }
        }
      );

     
        },
   
    watch:{
      // watch only the area of the polygon
      "polyGonesObject.area": function (newVal, oldVal) {
        this.$emit("update-area", {
          installationId: this.installation.id,
          area: newVal,
          longitude: this.polyGonesObject.longitude,
          latitude: this.polyGonesObject.latitude,

        });
      },
      "polyGonesObject.longitude": function (newVal, oldVal) {
        this.$emit("update-area", {
          installationId: this.installation.id,
          area: this.polyGonesObject.area,
          longitude: newVal,
          latitude: this.polyGonesObject.latitude,

        });
      },
      "polyGonesObject.latitude": function (newVal, oldVal) {
        this.$emit("update-area", {
          installationId: this.installation.id,
          area: this.polyGonesObject.area,
          longitude: this.polyGonesObject.longitude,
          latitude: newVal,
        });
      },
      installation: {
        handler(newVal, oldVal) {
          console.log("newVal ----", newVal);
          // Mettez à jour la propriété que vous voulez ici
          // Par exemple, si vous avez une propriété `production` :
          this.production =newVal.production;
        },
        deep: true,
        immediate:true
      },
      production: {
      handler(newVal) {
        console.log("newVal", newVal);
        // Create a shallow copy of the installation object
        

        // Find the corresponding data in newVal
        const correspondingData = newVal.find(
          (item) => item.id === newInstallation.id
        );

        if (correspondingData) {
          // Add the new production attribute and update its value
          this.installation.production = correspondingData.year_production;
        }

        // Update the installation
        
        console.log("installation watchy", this.installation);
      },
      deep: true,
      immediate: true,
    }
      

    },
    computed: {
      installationSize() {
        return this.installation.nbrPanels * this.installation.size;
      },
      installationProduction() {
        return this.installation.production;
      },
      totalWattsUsed() {
        return this.installation.nbrPanels * this.installation.wattsPerPanel;
      },
    },
    methods: {
      handlePolygonComplete(polygon) {
        const area = google.maps.geometry.spherical.computeArea(
          polygon.getPath()
        );
        this.polyGonesObject.area = area;
  
        let overlay = new google.maps.OverlayView();
        overlay.onAdd = () => {
          let path = polygon.getPath();
          let bounds = new google.maps.LatLngBounds();
          path.forEach((point) => bounds.extend(point));
  
          let center = bounds.getCenter();
          this.polyGonesObject.latitude = center.lat();
          this.polyGonesObject.longitude = center.lng();
        };
        overlay.draw = function () {};
        overlay.setMap(this.map);
  
        this.polyGonesObject.polyGones.push(polygon);
        this.polyGonesObject.showPolygons = true;
        this.polyGonesObject.installationId = this.installation.id;
  
        this.$emit('polygon-complete', { installationId: this.installation.id, polygon });
  
        const bounds = new google.maps.LatLngBounds();
        polygon.getPath().forEach(function (point) {
          bounds.extend(point);
        });
  
        this.map.fitBounds(bounds);
        if (this.polyGonesObject.area > 0) {
          this.drawed = true;
        }
      },
      deletePolygon(polygon) {
        polygon.setMap(null);
        this.polyGonesObject.polyGones = this.polyGonesObject.polyGones.filter(
          (p) => p !== polygon
        );
        this.polyGonesObject.area = 0;
        this.polyGonesObject.latitude = 0;
        this.polyGonesObject.longitude = 0;
      },
      startDrawing() {
        this.showTooltip = false;
        this.drawingManager.setDrawingMode(
          google.maps.drawing.OverlayType.POLYGON
        );
        this.polyGonesObject.installationId = this.installation.id;
        this.hasDrawnPolygon = true;
        google.maps.event.addListener(
          this.drawingManager,
          "overlaycomplete",
          (event) => {
            if (event.type == google.maps.drawing.OverlayType.POLYGON) {
              this.drawingManager.setDrawingMode(null);
            }
          }
        );
      },
      stopDrawing() {
        this.drawingManager.setDrawingMode(null);
      },
      addPanel() {
        this.$emit("addPanneau");
      },
      updateOrientation() {
        if (this.panelDetails.angle < 0) {
          this.panelDetails.angle = 0;
        } else if (this.panelDetails.angle > 360) {
          this.panelDetails.angle = 360;
        }
        this.$emit("update-orientation", this.panelDetails.angle);
      },
      toggleExpand() {
        this.$emit("toggleExpand", this.installation.id);
        this.$emit("togglePanneu", {
          id: this.installation.id,
          isExpanded: this.isExpanded,
        });
      },
      updateLossPercentage() {
        this.$emit("update-lossPercentage", this.panelDetails.lossPercentage);
      },
      updatePeakPower() {
        this.$emit("update-peakPower", this.panelDetails.peakPower);
      },
      showDeletePopup() {
        this.deletePopup = true;
      },
      hideDeletePopup() {
        this.deletePopup = false;
      },
      confirmDelete() {
        this.$emit("delete-installation", this.installation.id);
        this.hideDeletePopup();
      },
      async sendInstallation() {
      // const selectedInstallationf = this.installations.find(installation => installation.id === this.selectedInstallation)
      // console.log("selectedInstallation", selectedInstallationf);
      
      await axios({
       
        url:  process.env.VUE_APP_URL_API_CLIENT + `getInstallationAndSendItToSolarApi/`,
        method: "POST",
        data: {
          installation: [this.installation],
          token: this.$store.getters.getUserToken,
        },
      })
        .then((response) => {
          console.log("response received ")
          const productionList = response.data.data;
          this.production = productionList[0].year_production;
        })
        .catch((error) => {
          console.log(error);
        });
    
    },
    },
  };
  </script>
  
  <style scoped>
  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
    background-color: #14324f;
    color: #fff;
    padding: 9px;
    border-radius: 10px;
    white-space: nowrap;
    z-index: 100;
    text-align: center !important;
  }
  
  .tooltip span {
    font-size: 14px;
    font-weight: 500;
    display: block !important;
  }
  
  .installation-item {
    position: relative;
    border: 2px solid #bbb;
    background-color: rgba(255, 255, 255);
    border-radius: 8px;
    display: flex;
    max-width: 300px;
    flex-direction: column;
    z-index: 100;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 16px;
  }
  .header .installation-title {
    font-size: 16px;
    font-weight: 700;
    color: #141414;
    margin-bottom: 8px;
  }
  .header .installation-subtitle {
    font-size: 11px;
    font-weight: 500;
    color: #5c5c5c;
  }
  .label {
    font-size: 12px;
    font-weight: 600;
    color: #1d799d;
  }
  .value {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid gray;
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
  }
  .details p {
    font-size: 14px;
    font-weight: 500;
    color: #727474;
  }
  
  .details input {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-left: 10px;
    padding: 3px;
  }
  p {
    margin: 0px;
  }
  
  .item-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .deactivate {
    /* Add any additional styles for the activate class here */
    background-color: #fff !important;
    color: #141414 !important;
  }
  
  .popup {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  /* Popup Content */
  .popup-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Close button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Popup header */
  .popup-content h2 {
    margin-top: 0;
    color: #333;
  }
  
  /* Popup actions */
  .popup-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Cancel button */
  .btn-cancel {
    background-color: transparent;
    border: none;
    color: #555;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Delete button */
  .btn-delete {
    background-color: #ff5252;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-delete:hover {
    background-color: #ff1744;
  }
  </style>
  
  <!-- add button -->
  <style scoped>
  .installations-item-btns-wrapper {
    position: absolute;
    left: calc(100% + 10px);
    top: 0px;
    width: fit-content;
  }
  
  .item-btn {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin-bottom: 10px;
    background: rgb(56, 56, 56);
    color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px #949494;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .addPanelBtn {
    font-size: 28px;
    background: rgb(4, 81, 117);
  }
  
  .btnDrawStart {
    font-size: 28px;
    background: rgb(3, 44, 80);
  }
  
  .btnDrawStop {
    font-size: 28px;
    background: rgb(233, 171, 38);
  }
  .prod-installation-btn {
  font-size: 14px;
  border: 2px solid #bbb;
  color: #fff;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  background-color: #f5a43b;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(60, 64, 67, 0.3);
}
  .btnDrawDelete {
    font-size: 28px;
    background: #f53247;
  }
  .icon-size{
    font-size: 20px
  }
  </style>
  