<template>
  <div class="tab-slider-container">
    <div class="tabs">
      <div
        v-for="option in computedOptions"
        :key="option.value"
        :class="['tab', { 'tab-selected': selectedTab === option.value }]"
        @click="selectTab(option.value)"
      >
        {{ option.label }}
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'TabSlider',
  props: {
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => [
        { label: 'Pro', value: 'Pro' },
        { label: 'Particulier', value: 'Particulier' }
      ]
    }
  },
  data() {
    return {
      selectedTab: this.value
    };
  },
  computed: {
    computedOptions() {
      return this.options.length ? this.options : [
        { label: 'Pro', value: 'Pro' },
        { label: 'Particulier', value: 'Particulier' }
      ];
    }
  },
  watch: {
    value(newVal) {
      this.selectedTab = newVal;
    },
    selectedTab(newVal) {
      this.$emit('input', newVal);
    }
  },
  methods: {
    selectTab(value) {
      this.selectedTab = value;
    }
  }
};
</script>

<style>
.tab-slider-container {
  width: 300px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  height: 38px;
  background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%);
  border-radius: 4px;

}

.tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11px; /* Reduced font size to 0.6 of original 14px */
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab-selected {
  background-color: rgba(255, 255, 255, 0.35);
}

.tab-content {
  margin-top: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
