<template>
  <div class="card mb-3">
    <div class="card-header">
      <div class="card-title">
        <h5>Liste des demandes de cotations</h5>
      </div>
    </div>
    <div class="card-body">
      <!-- DATA TABLE FOR COTATION -->
      <datatable
        id="table-validation-cotation"
        :columns="columns"
        :sortKey="sortKey"
        :sortOrders="sortOrders"
        @sort="sortBy"
        @search="rechercheData"
      >
        <template v-slot:header>
          <div style="width: 200px" class="float-end container-div mr-2">
            <select
              v-model="filterStatus"
              class="form-control"
              id="sel1"
              @change="filterByStatus"
            >
              <option value="tout">Toutes les demandes</option>
              <option value="1">acceptée</option>
              <option value="0">en attente</option>
              <option value="2">refusée</option>
            </select>
          </div>
        </template>
        <template v-slot:body>
          <tbody class="text-center" v-if="rowData.length > 0 && loadingTable == false">
            <tr v-for="(row, index) in rowData" :key="row?.id">
              <td>{{ row?.dateAjout }}</td>
              <td>{{ row?.compte?.nom + " " + row?.compte?.prenom }}</td>
              <!--<td>
                <v-tooltip v-if="row?.societe?.raison?.length >= 20" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <small class="td-table" v-bind="attrs" v-on="on">
                      {{ row?.societe?.raison?.substring(0, 20) + "..." }}
                    </small>
                  </template>
                  <span>{{ row?.societe?.raison }}</span>
                </v-tooltip>
                <small class="td-table" v-else>
                  <router-link :to="`/detail-societe/${row?.societe?.id}`">{{
                    row?.societe?.raison
                  }}</router-link>
                </small>
              </td>-->
              <td class="text-left">
                <div style="max-width: 200px">
                  <router-link :to="`/detail-compteur/${row?.Compteur[0]?.id}`">
                    <small>{{ row?.Compteur[0]?.numCompteur }}</small>
                  </router-link>
                  <v-icon
                    v-if="row?.Compteur?.length > 1"
                    data-toggle="collapse"
                    :data-target="'#multiCollapseCompteur' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseCompteur' + index"
                    right
                  >
                    mdi-menu-down
                  </v-icon>
                  <div
                    class="collapse multi-collapse"
                    :id="'multiCollapseCompteur' + index"
                  >
                    <div v-for="c in row?.Compteur">
                      <router-link :to="`/detail-compteur/${c.id}`">
                        <small>
                          {{ c.numCompteur }}
                        </small>
                      </router-link>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-left">
                <div style="max-width: 200px">
                  <div v-if="row?.ACD?.length > 0">
                    <small>
                      <a :href="row?.ACD[0]" target="_blank"
                        ><span class="text-body-4 blue--text">ACD 1</span></a
                      >
                    </small>
                    <v-icon
                      v-if="
                        row?.ACD.length > 1 ||
                        row?.Facture?.length > 0 ||
                        row?.Contrat?.length > 0
                      "
                      data-toggle="collapse"
                      :data-target="'#multiCollapseFile' + index"
                      aria-expanded="false"
                      :aria-controls="'multiCollapseFile' + index"
                    >
                      mdi-menu-down
                    </v-icon>
                  </div>
                  <div v-else-if="row?.Facture?.length > 0">
                    <small>
                      <a :href="row?.Facture[0]" target="_blank"
                        ><span class="text-body-4 blue--text">Facture 1</span></a
                      >
                    </small>
                    <v-icon
                      v-if="
                        row?.ACD?.length > 0 ||
                        row?.Facture?.length > 1 ||
                        row?.Contrat?.length > 0
                      "
                      data-toggle="collapse"
                      :data-target="'#multiCollapseFile' + index"
                      aria-expanded="false"
                      :aria-controls="'multiCollapseFile' + index"
                    >
                      mdi-menu-down
                    </v-icon>
                  </div>
                  <div v-else-if="row?.Contrat?.length > 0">
                    <small>
                      <a :href="row?.Contrat[0]" target="_blank"
                        ><span class="text-body-4 blue--text">Contrat 1</span></a
                      >
                    </small>
                    <v-icon
                      v-if="
                        row?.ACD?.length > 0 ||
                        row?.Facture?.length > 0 ||
                        row?.Contrat?.length > 1
                      "
                      data-toggle="collapse"
                      :data-target="'#multiCollapseFile' + index"
                      aria-expanded="false"
                      :aria-controls="'multiCollapseFile' + index"
                    >
                      mdi-menu-down
                    </v-icon>
                  </div>
                  <div class="collapse multi-collapse" :id="'multiCollapseFile' + index">
                    <div v-for="(acd, index) in row?.ACD">
                      <small>
                        <a :href="acd" target="_blank"
                          ><span class="text-body-4 blue--text"
                            >ACD {{ index + 1 }}</span
                          ></a
                        >
                      </small>
                    </div>
                    <div v-for="(Facture, index) in row?.Facture">
                      <small>
                        <a :href="Facture" target="_blank"
                          ><span class="text-body-4 blue--text"
                            >Facture {{ index + 1 }}</span
                          ></a
                        >
                      </small>
                    </div>
                    <div v-for="(Contrat, index) in row?.Contrat">
                      <small>
                        <a :href="Contrat" target="_blank"
                          ><span class="text-body-4 blue--text"
                            >Contrat {{ index + 1 }}</span
                          ></a
                        >
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{ row?.dateDevis?.split("-").reverse().join("/") }}</td>

              <td>
                <div class="d-flex flex-column align-items-center btn-group">
                  <div class="d-flex flex-row justify-content-center">
                    <small class="small-status" v-if="row.status == '0'"
                      ><v-icon dense style="color: rgb(46, 116, 245)"
                        >mdi-clock-outline</v-icon
                      ><b class="ml-2">en attente</b></small
                    >
                    <small class="small-status" v-if="row.status == '1'"
                      ><v-icon dense style="color: #00e676">mdi-check-circle</v-icon
                      ><b class="ml-2">acceptée</b></small
                    >
                    <small class="small-status" v-if="row.status == '2'"
                      ><v-icon dense style="color: #ff0000">mdi-close-circle</v-icon
                      ><b class="ml-2">refusée</b></small
                    >
                    <small class="small-status" v-if="row.status == '3'"
                      ><v-icon dense style="color: #ff0000">mdi-alert-outline</v-icon
                      ><b class="ml-2">erreur technique</b></small
                    >
                    <small class="small-status" v-if="row.status == '4'"
                      ><v-icon dense style="color: orange">mdi-progress-clock</v-icon
                      ><b class="ml-2">en traitement</b></small
                    >
                    <!-- new buttons -->
                    <small class="small-status" v-if="row.status == '5'"
                      ><v-icon dense style="color: rgb(45, 139, 36)">mdi-check-all</v-icon
                      ><b class="ml-2">accepter</b></small
                    >
                    <small class="small-status" v-if="row.status == '6'"
                      ><v-icon dense style="color: rgb(182, 58, 8)">mdi-cancel</v-icon
                      ><b class="ml-2">annuler</b></small
                    >
                    <small class="small-status" v-if="row.status == '7'"
                      ><v-icon dense style="color: rgb(109, 106, 109)"
                        >mdi mdi-lock-clock</v-icon
                      ><b class="ml-2">acd primer</b></small
                    >
                    <small class="small-status" v-if="row.status == '8'"
                      ><v-icon dense style="color: rgb(109, 106, 109)"
                        >mdi mdi-check-all</v-icon
                      ><b class="ml-2">compteur n'est pas téléopérable!</b></small
                    >
                    <small class="small-status" v-if="row.status == '9'"
                      ><v-icon dense style="color: rgb(45, 120, 36)"
                        >mdi-check-all</v-icon
                      ><b class="ml-2"
                        >Aucune mesure trouvée sur ce point.</b
                      ></small
                    >
                    <small class="small-status" v-if="row.status == '10'"
                      ><v-icon dense style="color: rgb(45, 120, 36)"
                        >mdi-check-all</v-icon
                      ><b class="ml-2">PDL inexistant ou invalide.</b></small
                    >
                    <small class="small-status" v-if="row.status == '11'"
                      ><v-icon dense style="color: rgb(45, 120, 36)"
                        >mdi-check-all</v-icon
                      ><b class="ml-2">Courbe de charge incomplète.</b></small
                    >

                    <div class="d-flex flex-row" v-if="row?.status != '4'"></div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <noDataTable class="ma-4" v-if="rowData.length == 0 && loadingTable == false" />
          <progressCircular class="ma-8" v-if="loadingTable == true" />
        </template>
      </datatable>
      <div
        class="d-flex flex-row justify-content-center justify-sm-start align-items-center"
      >
        <pagination
          v-if="rowData.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
//import LineChart from "./chartComponent/LineChart.vue";
import Datatable from "../../../components/dataTable/Datatable.vue";
import Pagination from "../../../components/dataTable/Pagination.vue";
import noDataTable from "../../../components/dataTable/no-data-table.vue";
import progressCircular from "../../../components/dataTable/progress-circular.vue";

export default {
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
  },
  name: "CotationClientP",
  props: ["id"],
  data() {
    let sortOrders = {};
    let columns = [
      { width: "14vw", label: "Date d'ajout", name: "date", sortable: true },
      { width: "14vw", label: "Commercial", name: "Commerciale", sortable: true },
      //{ width: "14vw", label: "Raison Sociale", name: "Raison", sortable: true },
      { width: "14vw", label: "Compteur", name: "numCompteur", sortable: true },
      { width: "14vw", label: "Pièce jointe", name: "piecejointe", sortable: true },
      { width: "14vw", label: "Début du devis", name: "dateDebut", sortable: true },
      { width: "14vw", label: "Statut", name: "status", sortable: true },
    ];

    // columns names
    columns.forEach((key) => {
      sortOrders[key.name] = -1;
    });

    return {
      loadingTable: false,

      columns: columns,
      filterStatus: "tout",

      sortKey: "date",

      sortOrders: sortOrders,

      // dataTable
      dataTable: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      // pagination
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      rowData: [],
      loaded: false,
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    filterByStatus() {},
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    // get Cotation data
    async getData(pageNumber = 1) {
      this.dataTable.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getDemandeCotationByProfileParticulierId/",
        method: "POST",
        data: {
          id: this.id,
          token: this.$cookies.get("token"),
          pageNumber: pageNumber,
          dataTable: this.dataTable,
        },
      })
        .then((response) => {
          console.log("response", response);
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.dataTable.draw == data.draw) {
              this.rowData = data.data.data;
              this.configPagination(data.data);
              console.log("rowData", this.rowData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // recherche cotation data
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.dataTable.search = key;
      } else if (type == "nbrRecords") {
        this.dataTable.length = key;
      }
      this.dataTable.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getDemandeCotationByProfileParticulierId/",
        method: "POST",
        data: {
          id: this.id,
          token: this.$cookies.get("token"),
          pageNumber: pageNumber,
          dataTable: this.dataTable,
        },
      })
        .then((response) => {
          console.log("response", response);
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.dataTable.draw == data.draw) {
              this.rowData = data.data.data;
              this.configPagination(data.data);
              console.log("rowData", this.rowData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // sort by
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.dataTable.column = this.getIndex(this.columns, "name", key);
      this.dataTable.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  background-color: #ffffff !important;
}

.containe_comp {
  background-color: #f2f2f2 !important;
  border-radius: 10px;
  padding: 50px 70px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
}

.body-header {
  color: #192946;
}

.icon {
  width: 58px;
  height: 58px;
  top: 0;
  bottom: 0;
  right: 16px;
  margin: auto;
  background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
    rgb(0, 121, 145);
  border-radius: 7px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.12);
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
}

.text-button {
  color: #192946;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
}

.text-button:hover {
  color: #1e3766;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
  text-decoration: underline;
}

.bgc-primary {
  background-color: #396c99;
}

.bgc-primary:hover {
  background-color: #1e4c66;
}

.bgc-success {
  background-color: #2fb8ac;
}

.bgc-warning {
  background-color: #868685;
}

.card-title {
  padding: 0.45rem 0.825rem;
  margin-bottom: 10;
  background-color: #ffffff;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
  font-size: 1.25rem;
  color: #1d3258;
}

.card-header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e6e6f2 !important;
}

#table-validation-cotation th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}

.v-application ul {
  padding-left: 10px;
}

.info-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}

.small-status {
  width: max-content;
}
</style>
