<template>
  <v-row
    cols="12"
    class="d-flex flex-row justify-center ma-4 main-container"
    :style="openNav ? 'margin-right:350px !important' : 'margin-right:0px !important'"
  >
  <!-- image popup -->
  <v-dialog
        v-model="imagePopup"
        style="z-index: 9999 !important;"
        :height="800"
        :width="1000">
        <v-card>
          <v-toolbar dense color="elevation-0">
            <!--download -->
            <v-spacer></v-spacer>
            <v-btn icon color="black" @click="imagePopup = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn icon color="black" @click="downloadImage()">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row no-gutters>
            <v-col cols="12">
              <v-row no-gutters class="justify-content-center">
                <v-img
                  :src="imageToShow"
                  :height="780"
                  :width="980"
                  contain
                ></v-img>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
  </v-dialog>

    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
      <v-card style="margin-right: 32px">
        <v-card-text class="px-4 py-2">
          <div  class="d-flex flex-row align-items-center justify-content-between py-4" style="margin-bottom: 20px;">
            <!-- title -->
            <p class="vt_title mb-0">Visite Installation N° {{ VisiteInstallation.id }}</p>
            <div class="d-flex flex-row align-items-center">
              <!-- status btn -->
              <div class="d-flex flex-column align-items-center btn-group">
                <div class='d-flex flex-row justify-content-center'>
                  <small class="small-status" v-if="VisiteInstallation.etat == 'refusee'"><v-icon style="color: #ff0000;">mdi-close-circle</v-icon><b class="ml-1">Refusée</b></small>
                  <small class="small-status" v-if="VisiteInstallation.etat == 'annuler'"><v-icon style="color: #ff0000;">mdi-cancel</v-icon><b class="ml-1">Annulé</b></small>
                  <small class="small-status d-flex flex-row align-items-center justify-content-center" v-if="VisiteInstallation.etat == 'en_attend'"><v-icon style="color: #2080ee;">mdi-clock-outline</v-icon><span class="ml-1" style="font-size: 16px; color: #000000;">En Attend</span></small>
                  <small class="small-status" v-if="VisiteInstallation.etat == 'en_cours'"><v-icon style="color: orange;">mdi-progress-clock</v-icon><b class="ml-1">En Cours</b></small>
                  <small class="small-status" v-if="VisiteInstallation.etat == 'terminer'"><v-icon style="color: #00E676;">mdi-check</v-icon><b class="ml-1">Terminer</b></small>
                  <small class="small-status" v-if="VisiteInstallation.etat == 'pre_valider'"><v-icon style="color: #9C27B0;">mdi-eye-check</v-icon><b class="ml-1">Pré Validé</b></small>
                  <small class="small-status" v-if="VisiteInstallation.etat == 'valider'"><v-icon style="color: #00E676;">mdi-check-all</v-icon><b class="ml-1" style="word-wrap: normal;">Validé</b></small>
                  <div class="d-flex flex-row" v-if="showActionsBtns(VisiteInstallation.etat)">
                    <v-icon
                      class="dropdown-toggle"
                      data-toggle="collapse"
                      :data-target="'#collapseStatut'"
                      aria-expanded="false"
                      :aria-controls="'collapseStatut'"
                      size="24"
                      left
                    >
                      mdi-menu-down
                    </v-icon>
                  </div>
                </div>
                <div
                  class="collapse"
                  :id="'collapseStatut'"
                  style="border: 0px"
                  v-if="showActionsBtns(VisiteInstallation.etat)"
                >
                  <div class="d-flex flex-row justify-center align-center mt-4 mt-lg-2">
                    <!-- Refuser -->
                    <v-btn
                      class="dropdown-project"
                      title="Refuser"
                      icon
                      small
                      plain
                      @click="
                        ActionsBtns(
                          VisiteInstallation.id,
                          'refusee',
                          `Vous êtes sur le point de refuser ${VisiteInstallation.name}!`
                        )
                      "
                      v-if="
                        $store.getters.hasPermissionByStatus(
                          'VI',
                          VisiteInstallation.etat,
                          'refusee'
                        )
                      "
                      color="#FF0000"
                    >
                      <v-icon color="#FF0000"> mdi-close-circle </v-icon>
                    </v-btn>
                    <!-- Annuler -->
                    <v-btn
                      class="dropdown-project"
                      title="Annuler"
                      icon
                      small
                      plain
                      @click="
                        ActionsBtns(
                          VisiteInstallation.id,
                          'annuler',
                          `Vous êtes sur le point d'annuler ${VisiteInstallation.name}!`
                        )
                      "
                      v-if="
                        $store.getters.hasPermissionByStatus(
                          'VI',
                          VisiteInstallation.etat,
                          'annuler'
                        )
                      "
                      color="#E91E63"
                    >
                      <v-icon color="#E91E63"> mdi-cancel </v-icon>
                    </v-btn>
                    <!-- Reinitialiser -->
                    <v-btn
                      class="dropdown-project"
                      title="Reinitialiser"
                      icon
                      small
                      plain
                      @click="
                        ActionsBtns(
                          VisiteInstallation.id,
                          'en_attend',
                          `Vous êtes sur le point de reinitialiser ${VisiteInstallation.name}!`
                        )
                      "
                      v-if="
                        $store.getters.hasPermissionByStatus(
                          'VI',
                          VisiteInstallation.etat,
                          'reinitialiser'
                        )
                      "
                      color="#1976D2"
                    >
                      <v-icon color="#1976D2"> mdi-rotate-left </v-icon>
                    </v-btn>
                    <!-- pre valider -->
                    <v-btn
                      class="dropdown-project"
                      title="Pré Valider"
                      icon
                      small
                      plain
                      @click="
                        ActionsBtns(
                          VisiteInstallation.id,
                          'pre_valider',
                          `Vous êtes sur le point de pré valider ${VisiteInstallation.name}!`
                        )
                      "
                      v-if="
                        $store.getters.hasPermissionByStatus(
                          'VI',
                          VisiteInstallation.etat,
                          'pre_valider'
                        )
                      "
                    >
                      <v-icon color="#9C27B0"> mdi-eye-check </v-icon>
                    </v-btn>
                    <!-- valider -->
                    <v-btn
                      class="dropdown-project"
                      title="Valider"
                      icon
                      small
                      plain
                      @click="
                        ActionsBtns(
                          VisiteInstallation.id,
                          'valider',
                          `Vous êtes sur le point de valider ${VisiteInstallation.name}!`
                        )
                      "
                      v-if="
                        $store.getters.hasPermissionByStatus(
                          'VI',
                          VisiteInstallation.etat,
                          'valider'
                        )
                      "
                    >
                      <v-icon color="#00E676"> mdi-check-circle </v-icon>
                    </v-btn>
                    <!-- Creer Viste Installation -->
                    <!-- <v-btn
                      title="Creer Viste Installation"
                      @click="CreerUneVisteInstallation(VisiteInstallation.id)"
                      class="dropdown-project"
                      v-if="
                        $store.getters.hasPermissionByStatus(
                          'VI',
                          VisiteInstallation.etat,
                          'create_VI'
                        )
                      "
                      icon
                      small
                      plain
                    >
                      <v-icon color="#0000FF"> mdi-hammer-screwdriver </v-icon>
                    </v-btn> -->
                  </div>
                </div>
              </div>
              <!-- Valider les questions btn -->
              <div v-if="VisiteInstallation.etat == 'valider' || VisiteInstallation.etat == 'pre_valider' || VisiteInstallation.etat == 'terminer' " class="validate_questions ml-4">
                <button class="btn btn-outline-success mt-2" @click="goTovalidateDevis(VisiteInstallation.id)">Valider les questions</button>
              </div>
            </div>
          </div>
          <!-- End status btn -->
          <v-row class ="justify-center mt-5">
            <v-col>
              <div class="ma-2 " style="width: 100%;border-radius: 6px; height: 220px !important;  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;">
              <div class="section-title " >
                <p class="text ms-3">
                  <i class="fa-solid fa-hotel fa-lg"></i>      
                      <span class="ms-3">Informations de l'entreprise</span>
                      </p>
                <div class="devider"></div>
              </div>
              <div class="info-content-container  ms-3">
                <div class="info-content">
                  <p class="info-content-title mr-0">Raison Social:</p>
                  <p class="info-content-info">
                    {{ VisiteInstallation.raison }}
                  </p>
                </div>
                <div class="info-content">
                  <p class="info-content-title mr-2">Raison :</p>
                  <p class="info-content-info">{{ VisiteInstallation.raison }}</p>
                </div>
                <div class="info-content">
                  <p class="info-content-title mr-2">Address:</p>
                  <p class="info-content-info">{{ (VisiteInstallation.address).trim() }}</p>
                </div>
                
              </div>

            </div>

            </v-col>
            <v-col>
              <!-- Date du Rendez-vous -->
            <div class="ma-2 " style="width: 100%;border-radius: 6px;height: 220px !important; box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;">
            
              <div class="section-title">
                <p class="text ms-3">
                  <i class="fa-solid fa-calendar-days fa-lg"></i>
                  <span class="ms-3"> Date du Rendez-vous</span>
                  </p>
                <div class="devider"></div>
              </div>
              <div class="info-content-container ms-3">
                <div class="info-content">
                  <p class="info-content-title mr-0">Premier Jour:</p>
                  <p class="info-content-info">
                    {{ $dayjs(VisiteInstallation.dateDebut).format("DD/MM/YYYY") }}
                  </p>
                </div>
                <div class="info-content">
                  <p class="info-content-title mr-2">Heure Debut:</p>
                  <p class="info-content-info">{{ VisiteInstallation.HeureDebut }}</p>
                </div>
                <div class="info-content">
                  <p class="info-content-title mr-2">Dernier Jour:</p>
                  <p class="info-content-info">{{ VisiteInstallation.dateFin }}</p>
                </div>
                <div class="info-content">
                  <p class="info-content-title mr-2">Heure Fin:</p>
                  <p class="info-content-info">{{ VisiteInstallation.HeureFin }}</p>
                  </div>
              </div>
              <!-- <div class=" ">
                  <p class="info-content-title mr-2">Premier Jour:</p>
                  <p class="info-content-info">{{ VisiteInstallation.dateDebut }}</p>
                  <p class="info-content-title mr-2">Heure Debut:</p>
                  <p class="info-content-info">{{ VisiteInstallation.HeureDebut }}</p>
                  <p class="info-content-title mr-2">Dernier Jour:</p>
                  <p class="info-content-info">{{ VisiteInstallation.dateFin }}</p>

                  <p class="info-content-title mr-2">Heure Fin:</p>
                  <p class="info-content-info">{{ VisiteInstallation.HeureFin }}</p>
              </div> -->
            </div>
            </v-col>
          
          </v-row>
          <v-row class="justify-center">
            <v-col class="justify-center">
            <div class="ma-2 " style="min-height:220px;border-radius: 6px;width: 100%;border-radius: 6px; border: solid 1px #; box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;">
              <div class="section-title">
                <p class="text ms-3">
                  <i class="fa-solid fa-lightbulb fa-lg"></i>
                    <span class="ms-3">Informations Complémentaires</span></p>
                <div class="devider"></div>
              </div>
              <div class="info-content-container ms-3">
                <div class="info-content">
                  <p class="info-content-title mr-2">Visite installation n°:</p>
                  <p class="info-content-info">{{ VisiteInstallation.id }}</p>
                </div>
                <div class="info-content">
                  <p class="info-content-title mr-2">Visite installation:</p>
                  <p class="info-content-info">{{ VisiteInstallation.name }}</p>
                </div>
                <div class="info-content">
                  <p class="info-content-title mr-2">Technicien:</p>
                  <p class="info-content-info">{{ VisiteInstallation.technician?.nom + " " + VisiteInstallation.technician?.prenom }}</p>
                </div>             
              </div>
            </div>
          </v-col>

          <v-col>
            <div class="ma-2 " style="min-height:220px ;border-radius: 6px; width: 100%;border-radius: 6px; border: solid 1px #; box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;">
              <div class="section-title">
                <p class="text ms-3"> 
                  <i class="fa-regular fa-comment-dots fa-lg"></i>      
                  <span class="ms-3"> Description: </span>
                    </p>
                <div class="devider"></div>
              </div>
              <div class="info-content ms-3">
                <p v-if="VisiteInstallation.description" class="description px-2 pb-4">
                  {{ VisiteInstallation.description }}
                </p>
                <h6 v-else class="d-flex flex-column align-items-center text-center text-muted w-100 mt-4">
                  <span class="mdi mdi-comment-off-outline" style="font-size: 28px;"></span>
                  <span>Pas de description</span>
                </h6>
              </div>
            </div>

          </v-col>
            
          </v-row>
            <!-- *************************************** -->
            <!-- ************* Questionnaire *********** -->
            <!-- *************************************** -->
            <v-row class="justify-content-center mt-5 ms-0">    
              <div class="ma-2 p-0" style="width: 100% ;box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;" >
                <div class="section-title py-3 ">
                <div class="d-flex flex-row ms-3">
                  <i class="fa-solid fa-clipboard-question fa-lg " ></i>
                  <p class="text mb-1 ms-3"> Questionnaire</p>
                </div>
              </div>
             
              <v-expansion-panels
                id="questionGlobal"
                multiple
                elevation="0"
                v-model="panel"
                class="d-flex flex-column text-left ma-0 pa-2"
              >
                <v-expansion-panel style="border:solid 2px #bcbebe" class="">
                  <v-expansion-panel-header>
                    Questionnaire Visite Technique {{ VisiteInstallation.name }}
                  </v-expansion-panel-header>
                  <div id="question">
                    <v-expansion-panel-content>
                      <div
                        class="ma-2 py-4 d-flex flex-row"
                        v-for="(question, index) in VisiteInstallation.questions"
                        :key="index"
                      >
                        <div
                          class="pa-1"
                          :style="{
                            background: $styles.background,
                            width: 'fit-content',
                            height: 'fit-content',
                            borderRadius: '30px',
                          }"
                        >
                          <v-icon class="white--text">mdi-chat-question</v-icon>
                        </div>
                        <div class="d-flex flex-column ml-1 mt-1 align-start w-100">
                          <span
                            style="font-size: 16px"
                            class="Poppins-Bold black--text"
                            >{{ question.question }}</span
                          >
                          <div
                            class="mt-4"
                            style="
                              background-color: #fafafa;
                              border: 1px solid #e2e2e2;
                              padding: 16px;
                              border-radius: 4px;
                              width: 100%;
                            "
                          >
                            <div
                              style="height: 100%"
                              v-if="question.questionType == 'choice'"
                            >
                              {{ question.reponseText }}
                            </div>
                            <div
                              style="height: 100%"
                              v-if="question.questionType == 'multi_choice'"
                            >
                              <div
                                class="d-flex flex-row"
                                v-for="(response, index) in question.reponseText"
                                :key="index"
                              >
                                {{ response }}
                              </div>
                            </div>
                            <div
                              style="height: 100%"
                              v-if="question.questionType == 'multi_choice_metre'"
                            >
                              <div
                                class="d-flex flex-row"
                                v-for="(respObj, index) in question?.reponseText
                                  ?.answersList"
                                :key="index"
                              >
                                {{ respObj?.name }} - {{ respObj?.value }}
                              </div>
                              <div
                                class="d-flex flex-row"
                                v-if="question?.reponseText?.autre"
                              >
                                {{ question?.reponseText?.autre }}
                              </div>
                            </div>
                            <div
                              class="d-flex flex-row"
                              v-if="question.type == 'checkbox'"
                            >
                              {{ question.reponse }}
                            </div>
                            <div
                              class="d-flex flex-row flex-wrap"
                              v-if="question.questionType == 'image'"
                            >
                              <v-img
                                class="image-card"
                                v-for="(file, index) in question.files"
                                :key="index"
                                :src="baseURL + file?.path"
                                @click="showImagesPopup(file?.path)"
                              ></v-img>
                            </div>
                            <div v-if="question.questionType == 'file'">
                              <listFile :isRemovable="false" :file="question.files" />
                            </div>
                            <div
                              v-if="['number', 'text'].includes(question.questionType)"
                            >
                              <p>{{ question.reponseText }}</p>
                            </div>
                            <div class="ml-1" v-if="question.childs?.length > 0">
                              <div
                                v-for="(questionChild, index) in question.childs"
                                :key="index"
                              >
                                <span>{{ questionChild.question }}</span>
                                <div class="mt-2">
                                  <div
                                    style="height: 100%"
                                    v-if="questionChild.questionType == 'choice'"
                                  >
                                    {{ questionChild.reponseText }}
                                  </div>
                                  <div
                                    style="height: 100%"
                                    v-if="questionChild.questionType == 'multi_choice'"
                                  >
                                    <div
                                      class="d-flex flex-row"
                                      v-for="(
                                        response, index
                                      ) in questionChild.reponseText"
                                      :key="index"
                                    >
                                      {{ response }}
                                    </div>
                                  </div>
                                  <div
                                    style="height: 100%"
                                    v-if="question.questionType == 'multi_choice_metre'"
                                  >
                                    <div
                                      class="d-flex flex-row"
                                      v-for="(respObj, index) in question?.reponseText
                                        ?.answersList"
                                      :key="index"
                                    >
                                      {{ respObj?.name }} - {{ respObj?.value }}
                                    </div>
                                    <div
                                      class="d-flex flex-row"
                                      v-if="question?.reponseText?.autre"
                                    >
                                      {{ question?.reponseText?.autre }}
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex flex-row"
                                    v-if="questionChild.type == 'checkbox'"
                                  >
                                    {{ questionChild.reponse }}
                                  </div>
                                  <div
                                    class="d-flex flex-row flex-wrap"
                                    v-if="questionChild.questionType == 'image'"
                                  >
                                    <v-img
                                      class="image-card"
                                      v-for="(file, index) in questionChild.files"
                                      :key="index"
                                      :src="baseURL + file?.path"
                                      @click="showImagesPopup(file?.path)"
                                    ></v-img>
                                  </div>
                                  <div
                                    class="d-flex flex-column"
                                    v-if="questionChild.questionType == 'file'"
                                  >
                                    <listFile
                                      :isRemovable="false"
                                      :file="questionChild.files"
                                    />
                                  </div>
                                  <div
                                    v-if="
                                      ['number', 'text'].includes(
                                        questionChild.questionType
                                      )
                                    "
                                  >
                                    <p>
                                      {{ questionChild.reponseText }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
                <div v-if="VisiteInstallation.cheminement?.length > 0">
                  <v-expansion-panel style="border:solid 2px #bcbebe" class="my-2 shadowExpansionPanel">
                    <v-expansion-panel-header>
                      Questionnaire cheminement {{ VisiteInstallation.name }}
                    </v-expansion-panel-header>
                    <div id="cheminement">
                      <v-expansion-panel-content>
                        <div
                          v-for="(cheminement, index) in VisiteInstallation.cheminement"
                        >
                          <div v-if="cheminement.cheminementComp">
                            <div
                              class="ma-2 py-4 d-flex flex-row align-content-center"
                              v-for="(question, index) in cheminement.cheminementComp
                                .questions"
                              :key="index"
                            >
                              <div
                                class="pa-1"
                                :style="{
                                  background: $styles.background,
                                  width: 'fit-content',
                                  height: 'fit-content',
                                  borderRadius: '30px',
                                }"
                              >
                                <v-icon class="white--text">mdi-chat-question</v-icon>
                              </div>
                              <div class="d-flex flex-column ml-1 mt-1 align-start w-100">
                                <span
                                  style="font-size: 16px"
                                  class="Poppins-Bold black--text"
                                  >{{ question.question }}</span
                                >
                                <div
                                  class="mt-4"
                                  style="
                                    background-color: #fafafa;
                                    border: 1px solid #e2e2e2;
                                    padding: 16px;
                                    border-radius: 4px;
                                    width: 100%;
                                  "
                                >
                                  <div
                                    style="height: 100%"
                                    v-if="question.questionType == 'choice'"
                                  >
                                    {{ question.reponseText }}
                                  </div>
                                  <div
                                    style="height: 100%"
                                    v-if="question.questionType == 'multi_choice'"
                                  >
                                    <div
                                      class="d-flex flex-row"
                                      v-for="(response, index) in question.reponseText"
                                      :key="index"
                                    >
                                      {{ response }}
                                    </div>
                                  </div>
                                  <div
                                    style="height: 100%"
                                    v-if="question.questionType == 'multi_choice_metre'"
                                  >
                                    <div
                                      class="d-flex flex-row"
                                      v-for="(respObj, index) in question?.reponseText
                                        ?.answersList"
                                      :key="index"
                                    >
                                      {{ respObj?.name }} - {{ respObj?.value }}
                                    </div>
                                    <div
                                      class="d-flex flex-row"
                                      v-if="question?.reponseText?.autre"
                                    >
                                      {{ question?.reponseText?.autre }}
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex flex-row"
                                    v-if="question.type == 'checkbox'"
                                  >
                                    {{ question.reponse }}
                                  </div>
                                  <div
                                    class="d-flex flex-row flex-wrap"
                                    v-if="question.questionType == 'image'"
                                  >
                                    <v-img
                                      class="image-card"
                                      v-for="(file, index) in question.files"
                                      :key="index"
                                      :src="baseURL + file?.path"
                                      @click="showImagesPopup(file?.path)"
                                    ></v-img>
                                  </div>
                                  <div v-if="question.questionType == 'file'">
                                    <listFile
                                      :isRemovable="false"
                                      :file="question.files"
                                    />
                                  </div>
                                  <div
                                    v-if="
                                      ['number', 'text'].includes(question.questionType)
                                    "
                                  >
                                    <p>{{ question.reponseText }}</p>
                                  </div>
                                  <div class="ml-1" v-if="question.childs.length > 0">
                                    <div
                                      v-for="(questionChild, index) in question.childs"
                                      :key="index"
                                    >
                                      <span>{{ questionChild.question }}</span>
                                      <div class="mt-2">
                                        <div
                                          style="height: 100%"
                                          v-if="questionChild.questionType == 'choice'"
                                        >
                                          {{ questionChild.reponseText }}
                                        </div>
                                        <div
                                          style="height: 100%"
                                          v-if="
                                            questionChild.questionType == 'multi_choice'
                                          "
                                        >
                                          <div
                                            class="d-flex flex-row"
                                            v-for="(
                                              response, index
                                            ) in questionChild.reponseText"
                                            :key="index"
                                          >
                                            {{ response }}
                                          </div>
                                        </div>
                                        <div
                                          style="height: 100%"
                                          v-if="
                                            question.questionType == 'multi_choice_metre'
                                          "
                                        >
                                          <div
                                            class="d-flex flex-row"
                                            v-for="(respObj, index) in question
                                              ?.reponseText?.answersList"
                                            :key="index"
                                          >
                                            {{ respObj?.name }} - {{ respObj?.value }}
                                          </div>
                                          <div
                                            class="d-flex flex-row"
                                            v-if="question?.reponseText?.autre"
                                          >
                                            {{ question?.reponseText?.autre }}
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex flex-row"
                                          v-if="questionChild.type == 'checkbox'"
                                        >
                                          {{ questionChild.reponse }}
                                        </div>
                                        <div
                                          class="d-flex flex-row flex-wrap"
                                          v-if="questionChild.questionType == 'image'"
                                        >
                                          <v-img
                                            class="image-card"
                                            v-for="(file, index) in questionChild.files"
                                            :key="index"
                                            :src="baseURL + file?.path"
                                            @click="showImagesPopup(file?.path)"
                                          ></v-img>
                                        </div>
                                        <div
                                          class="d-flex flex-column"
                                          v-if="questionChild.questionType == 'file'"
                                        >
                                          <listFile
                                            :isRemovable="false"
                                            :file="questionChild.files"
                                          />
                                        </div>
                                        <div
                                          v-if="
                                            ['number', 'text'].includes(
                                              questionChild.questionType
                                            )
                                          "
                                        >
                                          <p>
                                            {{ questionChild.reponseText }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <v-row
                            v-if="
                              cheminement !=
                              VisiteInstallation.cheminement[
                                VisiteInstallation.cheminement.length - 1
                              ]
                            "
                            style="border: 1px black solid"
                            class="ma-4"
                          ></v-row>
                        </div>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>
                </div>
                <div v-if="VisiteInstallation.cheminement?.cheminementTic">
                  <v-expansion-panel style="border:solid 2px #bcbebe" class="my-2 ">
                    <v-expansion-panel-header>
                      Questionnaire cheminement {{ VisiteInstallation.name }}
                    </v-expansion-panel-header>
                    <div id="cheminementTic">
                      <v-expansion-panel-content>
                        <div
                          v-for="(cheminement, index) in VisiteInstallation.cheminement"
                        >
                          <div v-if="cheminement.cheminementTic.questions.length > 0">
                            <div
                              class="ma-2 py-4 d-flex flex-row align-content-center"
                              v-for="(question, index) in cheminement.cheminementTic
                                .questions"
                              :key="index"
                            >
                              <div
                                class="pa-1"
                                :style="{
                                  background: $styles.background,
                                  width: 'fit-content',
                                  height: 'fit-content',
                                  borderRadius: '30px',
                                }"
                              >
                                <v-icon class="white--text">mdi-chat-question</v-icon>
                              </div>
                              <div class="d-flex flex-column ml-1 mt-1 align-start w-100">
                                <span
                                  style="font-size: 16px"
                                  class="Poppins-Bold black--text"
                                  >{{ question.question }}</span
                                >
                                <div
                                  class="mt-4"
                                  style="
                                    background-color: #fafafa;
                                    border: 1px solid #e2e2e2;
                                    padding: 16px;
                                    border-radius: 4px;
                                    width: 100%;
                                  "
                                >
                                  <div
                                    style="height: 100%"
                                    v-if="question.questionType == 'choice'"
                                  >
                                    {{ question.reponseText }}
                                  </div>
                                  <div
                                    style="height: 100%"
                                    v-if="question.questionType == 'multi_choice'"
                                  >
                                    <div
                                      class="d-flex flex-row"
                                      v-for="(response, index) in question.reponseText"
                                      :key="index"
                                    >
                                      {{ response }}
                                    </div>
                                  </div>
                                  <div
                                    style="height: 100%"
                                    v-if="question.questionType == 'multi_choice_metre'"
                                  >
                                    <div
                                      class="d-flex flex-row"
                                      v-for="(respObj, index) in question?.reponseText
                                        ?.answersList"
                                      :key="index"
                                    >
                                      {{ respObj?.name }} - {{ respObj?.value }}
                                    </div>
                                    <div
                                      class="d-flex flex-row"
                                      v-if="question?.reponseText?.autre"
                                    >
                                      {{ question?.reponseText?.autre }}
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex flex-row"
                                    v-if="question.type == 'checkbox'"
                                  >
                                    {{ question.reponse }}
                                  </div>
                                  <div
                                    class="d-flex flex-row flex-wrap"
                                    v-if="question.questionType == 'image'"
                                  >
                                    <v-img
                                      class="image-card"
                                      v-for="(file, index) in question.files"
                                      :key="index"
                                      :src="baseURL + file?.path"
                                      @click="showImagesPopup(file?.path)"
                                    ></v-img>
                                  </div>
                                  <div v-if="question.questionType == 'file'">
                                    <listFile
                                      :isRemovable="false"
                                      :file="question.files"
                                    />
                                  </div>
                                  <div
                                    v-if="
                                      ['number', 'text'].includes(question.questionType)
                                    "
                                  >
                                    <p>{{ question.reponseText }}</p>
                                  </div>
                                  <div class="ml-1" v-if="question.childs.length > 0">
                                    <div
                                      v-for="(questionChild, index) in question.childs"
                                      :key="index"
                                    >
                                      <span>{{ questionChild.question }}</span>
                                      <div class="mt-2">
                                        <div
                                          style="height: 100%"
                                          v-if="questionChild.questionType == 'choice'"
                                        >
                                          {{ questionChild.reponseText }}
                                        </div>
                                        <div
                                          style="height: 100%"
                                          v-if="
                                            questionChild.questionType == 'multi_choice'
                                          "
                                        >
                                          <div
                                            class="d-flex flex-row"
                                            v-for="(
                                              response, index
                                            ) in questionChild.reponseText"
                                            :key="index"
                                          >
                                            {{ response }}
                                          </div>
                                        </div>
                                        <div
                                          style="height: 100%"
                                          v-if="
                                            question.questionType == 'multi_choice_metre'
                                          "
                                        >
                                          <div
                                            class="d-flex flex-row"
                                            v-for="(respObj, index) in question
                                              ?.reponseText?.answersList"
                                            :key="index"
                                          >
                                            {{ respObj?.name }} - {{ respObj?.value }}
                                          </div>
                                          <div
                                            class="d-flex flex-row"
                                            v-if="question?.reponseText?.autre"
                                          >
                                            {{ question?.reponseText?.autre }}
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex flex-row"
                                          v-if="questionChild.type == 'checkbox'"
                                        >
                                          {{ questionChild.reponse }}
                                        </div>
                                        <div
                                          class="d-flex flex-row flex-wrap"
                                          v-if="questionChild.questionType == 'image'"
                                        >
                                          <v-img
                                            class="image-card"
                                            v-for="(file, index) in questionChild.files"
                                            :key="index"
                                            :src="baseURL + file?.path"
                                            @click="showImagesPopup(file?.path)"
                                          ></v-img>
                                        </div>
                                        <div
                                          class="d-flex flex-column"
                                          v-if="questionChild.questionType == 'file'"
                                        >
                                          <listFile
                                            :isRemovable="false"
                                            :file="questionChild.files"
                                          />
                                        </div>
                                        <div
                                          v-if="
                                            ['number', 'text'].includes(
                                              questionChild.questionType
                                            )
                                          "
                                        >
                                          <p>
                                            {{ questionChild.reponseText }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <v-row
                            v-if="
                              cheminement !=
                              VisiteInstallation.cheminement[
                                VisiteInstallation.cheminement.length - 1
                              ]
                            "
                            style="border: 1px black solid"
                            class="ma-4"
                          ></v-row>
                        </div>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>
                </div>
              </v-expansion-panels>
              <div class="d-flex flex-row justify-content-end me-2 mb-2" v-if="panel != null">
                <v-btn
                  dense
                  :color="$colors[0]"
                  class="mt-4 white--text "
                  @click="createPDF()"
                  ><v-icon>mdi-tray-arrow-down</v-icon></v-btn
                >
              </div>
              </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- side bar for comments -->
    <div class="drawer-container">
      <div
        class="sidebar"
        :style="openNav ? 'width:350px !important' : 'width:0px !important'"
      >
        <div class="sidebar-content p-4">
          <label class="input-comment-label">Commentaire:</label>
          <v-textarea
            class="input-comment"
            label="Entrer votre commentaire"
            outlined
            dense
            rows="3"
            row-height="25"
            v-model="comment"
          ></v-textarea>
          <div class="d-flex flex-row justify-end">
            <v-btn
              dense
              :color="$colors[0]"
              class="white--text"
              style="font-size: 12px; text-transform: capitalize"
              @click="AddComment()"
            >
              Ajouter
            </v-btn>
          </div>
          <div class="comments-wrapper">
            <p class="comments-title">Les Commentaires:</p>
            <div
              class="comment-content-wrapper"
              v-for="(c, index) in VisiteInstallation?.comments"
              :style="c?.deleted ? 'opacity:0.6' : 'opacity:1'"
            >
              <div class="comment-content-container">
                <div class="comment-content-username">
                  <span>{{ c?.author?.prenom + " " + c?.author?.nom }}</span>
                </div>
                <div class="comment-content-text">
                  <span
                    :style="c?.deleted && 'text-decoration: line-through;'"
                    v-html="formatText(c?.text)"
                  ></span>
                </div>
                <div class="comment-content-date">
                  <span>{{ formatDate(c?.createAt) }}</span>
                </div>
              </div>
              <v-icon
                class="comment-delete-btn"
                size="25"
                v-if="
                  !c?.deleted &&
                  (userId == c?.author.id ||
                    ['admin', 'chef équipe', 'ADV'].includes(roleUser))
                "
                color="red"
                @click="deleteComment(c.id)"
              >
                mdi-trash-can-outline
              </v-icon>
            </div>
          </div>
        </div>
      </div>

      <button
        :style="openNav ? 'right:292px !important' : ''"
        class="openbtn"
        @click="openNav = !openNav"
      >
        ☰ Commentaires
      </button>
    </div>
    <!-- side bar for comments -->
  </v-row>
</template>
<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { PDFDocument, PDFBlobProvider } from "pdf-lib";
import listFile from "@/components/listFile.vue";
import CardProfil from "@/components/VisiteTechnique/CardProfil.vue";
import axios from "@/plugins/axios";
import dayjs from "@/utils/dayjsConfig";

export default {
  name: "ProfilVisiteInstallation",
  components: { listFile, CardProfil },
  data() {
    return {
      baseURL: "",
      panel: [],
      listTechnicien: [],
      searchTechnicien: false,
      VisiteInstallation: {},
      comment: "",
      openNav: true,
      imagePopup: false,
      imageToShow: null,
    };
  },
  mounted() {
    this.baseURL = process.env.VUE_APP_URL_API_CLIENT_BASE_URL;
  },
  computed: {
    userId() {
      return this.$store.getters.getUserId;
    },
    roleUser() {
      return this.$store.getters.getUserRole;
    },
    showActionsBtns() {
      return (status) => {
        return (
          this.$store.getters.hasPermissionByStatus("VI", status, "valider") ||
          this.$store.getters.hasPermissionByStatus("VI", status, "pre_valider") ||
          this.$store.getters.hasPermissionByStatus("VI", status, "annuler") ||
          this.$store.getters.hasPermissionByStatus("VI", status, "refusee") ||
          this.$store.getters.hasPermissionByStatus("VI", status, "reinitialiser")
        );
      };
    },
  },
  async created() {
    await this.getData();
  },
  methods: {
    formatText(text) {
      if (text) {
        return text.replace(/\n/g, "<br/>");
      }
      return "";
    },
    formatDate(date) {
      // in langage french
      dayjs.locale("fr");
      return dayjs(date).format("MMMM D, YYYY");
    },
    // show image in popup
    showImagesPopup(files, index) {
      this.imagePopup = true;
      this.imageToShow = this.baseURL + files;
    },
    // download image
    downloadImage() {
      // open the image in new tab
      window.open(this.imageToShow);

    },
    goTovalidateDevis(id){
      // navigate to validate devis
      this.$router.push({path: `/devis/formulaire-validation/${id}`});
     
    },
    createPDF() {
      let divQuestion = document.getElementById("questionGlobal");

      html2canvas(divQuestion, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL();

        const doc = new jsPDF({
          orientation: "p",
          unit: "pt",
          format: [canvas.width, canvas.height],
        });

        doc.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

        doc.save("rapport-question.pdf");
      });
    },
    async getAllTechnicien() {
      await fetch(process.env.VUE_APP_URL_API_CLIENT + "getAllTechnicien/", {
        method: "POST",
        body: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false && data.result.length > 0) {
            for (let i = 0; i < data.result.length; i++) {
              this.listTechnicien.push(data.result[i].userName);
            }
          }
        });
      this.searchTechnicien = true;
    },
    // change status visite installation avec les buttons
    async ActionsBtns(id, status, actionMessage) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: actionMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingRequestVT = true;
          this.dialogLoading = true;
          var formData = new FormData();
          formData.append("id_vi", id);
          formData.append("status", status);
          formData.append("token", this.$store.getters.getUserToken);
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "changeVisiteInstallationStatus/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.status == 200) {
                this.VisiteInstallation.etat = status;
              }
            })
            .catch((err) => {
              console.log("erreur", err);
            });
        }
      });
    },
    async getData() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + `getVisiteInstallationById/`,
        method: "POST",
        data: {
          token: this.$store.state.token,
          id_vi: this.$route.params.id,
        },
      })
        .then((response) => {
          console.log(response.data);
          response.data.raison = "Entreprise Test";
          let tempDebut = response.data.dateDebut;
          let tempFin = response.data.dateFin;
          response.data.dateDebut = this.$dayjs(tempDebut).format("YYYY-MM-DD");
          response.data.dateFin = this.$dayjs(tempFin).format("YYYY-MM-DD");
          response.data.HeureDebut = this.$dayjs.utc(tempDebut).format("HH:mm");
          response.data.HeureFin = this.$dayjs.utc(tempFin).format("HH:mm");
          if (response?.data?.comments) {
            response.data.comments.sort((a, b) => {
              const dateA = new Date(a.createAt);
              const dateB = new Date(b.createAt);
              return dateB - dateA;
            });
          }
          this.VisiteInstallation = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // ajouter commentaire
    async AddComment() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "addCommentToVisiteInstallation/",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
          id_vi: this.VisiteInstallation.id,
          comment: this.comment,
        }),
      })
        .then((res) => {
          this.getData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeWhiteSpace(str) {
      return str.replace(/\s/g, "");
    },
    async deleteComment(id) {
      this.$swal({
        title: "êtes-vous sûr de vouloir supprimer?",
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui, supprimer!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "deleteComment/",
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            data: JSON.stringify({
              token: this.$store.getters.getUserToken,
              comment_id: id,
            }),
          })
            .then((res) => {
              this.getData();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
/****************************** */
/******** main content ******** */
/****************************** */

.info-content-container {
  display: flex;
  flex-direction: column;
}
.info-content {
  display: flex;
  flex-direction: row;
}
.info-content-title {
  font-size: 14px;
  font-weight: 400;
  color: #9296a1;
  margin-bottom: 10px;
}
.info-content-info {
  font-size: 14px;
  font-weight: 600;
  color: #302f2f;
  margin-bottom: 5px;
  margin-bottom: 3px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  color: #4e525e;
  margin-bottom: 10px;
}
/*-----------*/
.section-title {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
  background-color: rgb(6, 123, 146);
  padding-top: 17px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.section-title .text {
  margin-bottom: 10px;
}
.devider {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}

.image-card {
  width: 180px;
  max-width: 180px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin: 8px;
}

/********** comment drawer ************* */
::v-deep .v-label {
  margin: 0px !important;
}

.my-drawer {
  top: 64px !important;
  height: 100% !important;
  max-height: 100% !important;
}

.drawer-container {
  position: relative;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.sidebar {
  height: 100% !important;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 64px;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0px 4px 16px -4px #979797;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  position: fixed;
  top: 140px;
  right: -60px;
  font-size: 15px;
  cursor: pointer;
  background-color: #007991;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 4px 4px 0px 0px;
  border: none;
  z-index: 9999;
  transform: rotateZ(-90deg);
  transition: 0.5s;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}

.input-comment-label {
  font-size: 14px;
  font-weight: 400;
}
.input-comment {
  font-size: 14px;
  font-weight: 400;
}
.comments-wrapper {
  margin-top: 32px;
  margin-bottom: 32px;
}
.comments-title {
  font-size: 16px;
  font-weight: 400;
  color: #02638a;
  border-bottom: 1px solid #d8e7ec;
  padding-bottom: 8px;
  margin-bottom: 20px;
}
.comment-content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-radius: 4px;
  padding: 8px 0px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e2e2e2;
}
.comment-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.comment-content-username {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 4px;
  color: #14324f;
}
.comment-content-username-logo {
  width: 28px;
  height: 28px;
  background: #028f70;
  border-radius: 50%;
  margin-right: 4px;
}
.comment-content-text {
  font-size: 12px;
  font-weight: 400;
  color: #14324f;
  margin-bottom: 4px;
}

.comment-content-date {
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  color: #81929e;
  text-align: left;
}
.vt_title{
  font-size: 20px;
  font-weight: 600;
  color: #156b5d;
}

.comment-delete-btn {
  width: fit-content;
  height: fit-content;
  margin-left: 8px;
}
.shadowExpansionPanel {
  box-shadow: 0px 0px 0px 2px #007991;
}
::v-deep
  .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  font-size: initial !important;
}
</style>
