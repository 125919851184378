<template>
  <div class="pa-4">
    <v-row v-if="loading">
      <loadingComponent
        @closeDialog="closeLoading($event)"
        :loadingProps="loading"
        :messageDialogeProps="messageDialoge"
        :dialogProps="dialog"
        :errorProps="errorModal"
      />
    </v-row>
    <v-stepper v-model="stepperModel" vertical>
      <v-form ref="formStep6" v-model="formStep6Model">
        <v-stepper-step
          :complete="stepperModel > 1 || stepperCompleted.includes(1)"
          step="1"
          :color="$colors[0]"
          style="cursor: pointer; width: fit-content"
          @click="goTo(1, $refs.formStep1)"
        >
          SIRET & SIREN
          <!--<small>sous titre si necessaire</small>-->
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-form ref="formStep1" v-model="formStep1Model">
            <v-card class="mb-4 mt-0 elevation-0">
              <v-col class="pa-0" cols="12" sm="12" md="12" lg="12" xl="12">
                <div class="d-flex flex-column mt-4">
                  <TabSlider v-model="typeClient" class="mb-4">
                    <template v-slot="{ selectedTab }">
                      <div v-if="selectedTab === 'Pro'">Pro</div>
                      <div v-else>Particulier</div>
                    </template></TabSlider
                  >
                  <v-form
                    v-if="typeClient == 'Pro'"
                    style="width: 50%"
                    ref="formSiret"
                    class="d-flex flex-row"
                  >
                    <v-combobox
                      :search-input.sync="siret"
                      v-model="siret"
                      outlined
                      hide-details="auto"
                      :items="listSiret"
                      :rules="[rules.siretLength]"
                      @update:search-input="getPropositionSociete()"
                      label="Siret "
                    ></v-combobox>
                    <!-- icon button -->
                    <v-btn
                      class="ml-4"
                      height="56"
                      :style="`background:${$styles.background}`"
                      @click="getListeCompteurBySiret()"
                    >
                      <v-icon class="white--text" size="29"
                        >mdi-map-marker-outline</v-icon
                      >
                    </v-btn>
                  </v-form>
                  <v-form
                    v-if="typeClient == 'Particulier'"
                    style="width: 50%"
                    ref="formEmail"
                    class="d-flex flex-row"
                  >
                    <v-combobox
                      :search-input.sync="emailParticulier"
                      v-model="emailParticulier"
                      outlined
                      hide-details="auto"
                      :rules="[rules.emailRules]"
                      label="Email "
                    ></v-combobox>
                    <!-- icon button -->
                    <v-btn
                      class="ml-4"
                      height="56"
                      :style="`background:${$styles.background}`"
                      @click="getListeCompteurByEmail()"
                    >
                      <v-icon class="white--text" size="29"
                        >mdi-map-marker-outline</v-icon
                      >
                    </v-btn>
                  </v-form>
                  <v-form
                    style="width: 50%"
                    ref="formCompteur"
                    class="d-flex flex-row mt-8"
                    v-if="
                      (siretFound == true && siret !== null) ||
                      (emailParticulierFound == true &&
                        emailParticulier !== null)
                    "
                  >
                    <v-combobox
                      class="mb-4"
                      v-model="listCompteurSelected"
                      outlined
                      hide-details="auto"
                      :items="listCompteur"
                      item-text="PDL"
                      item-value="PDL"
                      return-object
                      :rules="[required('un pdl valide')]"
                      @change="stepperCompleted = []"
                      label="Compteurs"
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggle">
                          <v-list-item-action>
                            <v-icon
                              :color="listCompteur.length > 0 ? $colors[0] : ''"
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                listCompteurSelected.length ===
                                listCompteur.length
                                  ? "Déselectionnez tous les compteurs"
                                  : "Sélectionnez tous les compteurs"
                              }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                          :style="`background:${$styles.background}`"
                          class="white--text"
                          v-if="index === 0"
                        >
                          <span>{{ item.PDL }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ listCompteurSelected.length - 1 }}
                          {{
                            listCompteurSelected.length - 1 > 1
                              ? "autres PDL"
                              : "autre PDL"
                          }})
                        </span>
                      </template>
                      <template v-slot:item="{ attrs, item, parent, selected }">
                        <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.PDL }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.typeDeProduction
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                    <v-text-field
                      hide-details
                      :rules="[required('un Titre valide')]"
                      v-model="nomInstalation"
                      outlined
                      dense
                      label="Titre d'instalation"
                    />
                  </v-form>
                </div>
              </v-col>
            </v-card>
            <v-btn
              v-if="typeClient == 'Pro'"
              :disabled="
                listCompteurSelected.length == 0 ||
                siret?.length == 0 ||
                siret == null
              "
              :style="`background:${
                listCompteurSelected.length == 0 ||
                siret?.length == 0 ||
                siret == null
                  ? ''
                  : $styles.background
              }`"
              class="white--text"
              @click="next(1)"
            >
              CONTINUER
            </v-btn>
            <v-btn
              v-if="typeClient == 'Particulier'"
              :disabled="
                listCompteurSelected.length == 0 ||
                emailParticulier?.length == 0 ||
                emailParticulier == null
              "
              :style="`background:${
                listCompteurSelected.length == 0 ||
                emailParticulier?.length == 0 ||
                emailParticulier == null
                  ? ''
                  : $styles.background
              }`"
              class="white--text"
              @click="next(1)"
            >
              CONTINUER
            </v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-step
          :color="$colors[0]"
          :complete="stepperModel > 2"
          step="2"
          :style="`${
            stepperCompleted.includes(2) &&
            (stepperModel >= 2 || stepperModel + 1 == 2)
              ? 'cursor:pointer'
              : ''
          };width:fit-content`"
          @click="goTo(2, $refs.formStep2)"
        >
          INFORMATIONS PANNEAUX
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-form ref="formStep2" v-model="formStep2Model">
            <v-card class="mb-6 elevation-0">
              <v-col
                class="pa-0 elevation-0 card_compteur_wrapper"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                v-if="listCompteurSelected.length > 0"
              >
                <div
                  class="card_compteur pa-0 my-0 elevation-0"
                  style="overflow: auto"
                >
                  <table class="compteur-table elevation-0">
                    <thead>
                      <tr>
                        <th class="text-center">Compteur</th>
                        <th class="text-center">Position</th>
                        <th class="text-center">Puissance (KWc)</th>
                        <th class="text-center">Perte</th>
                        <th class="text-center">Prix Capex €/KWc</th>
                        <th class="text-center">Prix Opex €/KWc</th>
                        <th class="text-center">Taille Installation</th>
                        <th class="text-center">Superficie PV</th>
                        <th class="text-center">Inclinaison</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="compteur in listCompteurSelected"
                        v-if="
                          compteur.typeDeProduction == 'autoCollective' ||
                          compteur.typeDeProduction ==
                            'autoCollective&Individuelle' ||
                          compteur.typeDeProduction == 'production'
                        "
                      >
                        <td>
                          {{ compteur.PDL }}
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('une prix de Vente Acc valide')]"
                            v-model="compteur.prixVenteAcc"
                            outlined
                            dense
                            label="Prix de vente ACC"
                          />
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('une longitude valide')]"
                            v-model="compteur.Longitude"
                            outlined
                            dense
                            label="Longitude"
                          />
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('une latitude valide')]"
                            v-model="compteur.Latitude"
                            outlined
                            dense
                            label="Latitude"
                          />
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[
                              required('une puissance pour le toiture valide'),
                            ]"
                            v-model="compteur.puissanceKWcToiture"
                            outlined
                            dense
                            placeholder="125"
                            label="Puissance toiture"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[
                              required('une puissance pour le sol valide'),
                            ]"
                            v-model="compteur.puissanceKWcSol"
                            outlined
                            dense
                            placeholder="125"
                            label="Puissance sol"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[
                              required('une puissance pour le ombrière valide'),
                            ]"
                            v-model="compteur.puissanceKWcOmbriere"
                            outlined
                            dense
                            placeholder="125"
                            label="Puissance ombrière"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[
                              required('un pourcentage de perte valide'),
                            ]"
                            v-model="compteur.losstoiture"
                            outlined
                            dense
                            label="Perte toiture"
                            ><template v-slot:append>
                              <span
                                class="Poppins-Bold mt-1"
                                :style="{ color: $colors[0] }"
                                >%</span
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[
                              required('un pourcentage de perte valide'),
                            ]"
                            v-model="compteur.losssol"
                            outlined
                            dense
                            label="Perte sol"
                            ><template v-slot:append>
                              <span
                                class="Poppins-Bold mt-1"
                                :style="{ color: $colors[0] }"
                                >%</span
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[
                              required('un pourcentage de perte valide'),
                            ]"
                            v-model="compteur.lossombriere"
                            outlined
                            dense
                            label="Perte ombrière"
                            ><template v-slot:append>
                              <span
                                class="Poppins-Bold mt-1"
                                :style="{ color: $colors[0] }"
                                >%</span
                              >
                            </template>
                          </v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Prix €/KWc toiture valide')]"
                            v-model="compteur.prixKWcToitureCapex"
                            outlined
                            dense
                            placeholder="125"
                            label="Prix €/KWc toiture"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Prix €/KWc Sol valide')]"
                            v-model="compteur.prixKWcSolCapex"
                            outlined
                            dense
                            placeholder="125"
                            label="Prix €/KWc Sol"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Prix €/KWc Ombrière valide')]"
                            v-model="compteur.prixKWcOmbriereCapex"
                            outlined
                            dense
                            placeholder="125"
                            label="Prix €/KWc Ombrière"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]">mdi-euro</v-icon>
                            </template>
                          </v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Prix €/KWc toiture valide')]"
                            v-model="compteur.prixKWcToitureOpex"
                            outlined
                            dense
                            placeholder="125"
                            label="Prix €/KWc toiture"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Prix €/KWc Sol valide')]"
                            v-model="compteur.prixKWcSolOpex"
                            outlined
                            dense
                            placeholder="125"
                            label="Prix €/KWc Sol"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Prix €/KWc Ombrière valide')]"
                            v-model="compteur.prixKWcOmbriereOpex"
                            outlined
                            dense
                            placeholder="125"
                            label="Prix €/KWc Ombrière"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]">mdi-euro</v-icon>
                            </template>
                          </v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un N° panneaux toiture valide')]"
                            v-model="compteur.panneauxToiture"
                            outlined
                            dense
                            placeholder="125"
                            label="N° panneaux  toiture"
                          >
                            <template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un N° panneaux Sol valide')]"
                            v-model="compteur.panneauxSol"
                            outlined
                            dense
                            placeholder="125"
                            label="N° panneaux  Sol"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[
                              required('un N° panneaux Ombrière valide'),
                            ]"
                            v-model="compteur.panneauxOmbriere"
                            outlined
                            dense
                            placeholder="125"
                            label="N° panneaux Ombrière"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]">mdi-euro</v-icon>
                            </template>
                          </v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Superficie toiture valide')]"
                            v-model="compteur.superficieToiture"
                            outlined
                            dense
                            placeholder="125"
                            label="Superficie toiture"
                          >
                            <template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Superficie Sol valide')]"
                            v-model="compteur.superficieSol"
                            outlined
                            dense
                            placeholder="125"
                            label="Superficie  Sol"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-solar-power</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un Superficie Ombrière valide')]"
                            v-model="compteur.superficieOmbriere"
                            outlined
                            dense
                            placeholder="125"
                            label="Superficie Ombrière"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]">mdi-euro</v-icon>
                            </template>
                          </v-text-field>
                        </td>
                        <td>
                          <v-select
                            hide-details
                            :rules="[required('une valeur valide')]"
                            v-model="compteur.angleOption"
                            item-text="label"
                            item-value="value"
                            label="Optimiser l'inclinaison"
                            outlined
                            dense
                            :items="angleOptionList"
                          >
                          </v-select>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required(`un angle d'inclinaison valide`)]"
                            v-model="compteur.incliner"
                            outlined
                            dense
                            placeholder="60"
                            label="Inclinaison"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-angle-acute</v-icon
                              >
                            </template>
                          </v-text-field>
                          <v-divider />
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required(`un angle d'orientation valide`)]"
                            v-model="compteur.orientation"
                            outlined
                            dense
                            placeholder="18"
                            label="Orientation"
                            ><template v-slot:append>
                              <v-icon :color="$colors[0]"
                                >mdi-ruler-square-compass</v-icon
                              >
                            </template>
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-card>
            <v-btn
              :disabled="!formStep2Model"
              :style="`background:${!formStep2Model ? '' : $styles.background}`"
              class="white--text mr-2"
              @click="next(2)"
            >
              CONTINUER
            </v-btn>
            <v-btn @click="Back(2)" text> RETOUR </v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-step
          :complete="stepperModel > 3"
          :color="$colors[0]"
          step="3"
          :style="`${
            stepperCompleted.includes(3) &&
            (stepperModel >= 3 || stepperModel + 1 == 3)
              ? 'cursor:pointer'
              : ''
          };width:fit-content`"
          @click="goTo(3, $refs.formStep3)"
        >
          INFORMATIONS PRIX
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-form ref="formStep3" v-model="formStep3Model">
            <v-card class="mb-6 elevation-0">
              <v-col
                class="pa-0 elevation-0 card_compteur_wrapper"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                v-if="listCompteurSelected.length > 0"
                style="overflow-x: 'auto'"
              >
                <div
                  class="card_compteur elevation-0 pa-0 my-0"
                  style="overflow: auto"
                >
                  <table class="compteur-table">
                    <thead>
                      <tr>
                        <th style="width: 10rem" class="text-center">
                          Compteur
                        </th>
                        <th style="width: 10rem" class="text-center">
                          Option Tarifaire
                        </th>
                        <th style="width: 10rem" class="text-center">HPH</th>
                        <th style="width: 10rem" class="text-center">HCH</th>
                        <th style="width: 10rem" class="text-center">HPE</th>
                        <th style="width: 10rem" class="text-center">HCE</th>
                        <th style="width: 10rem" class="text-center">HP</th>
                        <th style="width: 10rem" class="text-center">HC</th>
                        <th style="width: 10rem" class="text-center">POINTE</th>
                        <th style="width: 10rem" class="text-center">BASE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="compteur in listCompteurSelected"
                        v-if="
                          compteur.typeDeProduction == 'autoCollective' ||
                          compteur.typeDeProduction ==
                            'autoCollective&Individuelle' ||
                          compteur.typeDeProduction == 'consommation'
                        "
                      >
                        <td style="text-align: center">{{ compteur.PDL }}</td>
                        <td style="text-align: center">
                          {{ compteur.optionTarifaire }}
                        </td>
                        <template>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'HPH',
                                compteur.parametreCompteur
                              )
                            "
                          >
                            <v-text-field
                              hide-details
                              type="number"
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatHph"
                              label="HPH"
                              outlined
                              dense
                            />
                          </td>

                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                        </template>
                        <td
                          style="text-align: center"
                          v-if="
                            isConsoValueVisible(
                              compteur.optionTarifaire,
                              'HCH',
                              compteur.parametreCompteur
                            )
                          "
                        >
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un prix valide')]"
                            v-model="compteur.prixAchatHch"
                            label="HCH"
                            outlined
                            dense
                          />
                        </td>
                        <td style="text-align: center" v-else>
                          <span class="mdi mdi-block-helper"></span>
                        </td>
                        <td
                          style="text-align: center"
                          v-if="
                            isConsoValueVisible(
                              compteur.optionTarifaire,
                              'HPE',
                              compteur.parametreCompteur
                            )
                          "
                        >
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un prix valide')]"
                            v-model="compteur.prixAchatHpe"
                            label="HPE"
                            outlined
                            dense
                          />
                        </td>
                        <td style="text-align: center" v-else>
                          <span class="mdi mdi-block-helper"></span>
                        </td>
                        <td
                          style="text-align: center"
                          v-if="
                            isConsoValueVisible(
                              compteur.optionTarifaire,
                              'HCE',
                              compteur.parametreCompteur
                            )
                          "
                        >
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un prix valide')]"
                            v-model="compteur.prixAchatHce"
                            label="HCE"
                            outlined
                            dense
                          />
                        </td>
                        <td style="text-align: center" v-else>
                          <span class="mdi mdi-block-helper"></span>
                        </td>
                        <td
                          style="text-align: center"
                          v-if="
                            isConsoValueVisible(
                              compteur.optionTarifaire,
                              'HP',
                              compteur.parametreCompteur
                            )
                          "
                        >
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un prix valide')]"
                            v-model="compteur.prixAchatHp"
                            label="HP"
                            outlined
                            dense
                          />
                        </td>
                        <td style="text-align: center" v-else>
                          <span class="mdi mdi-block-helper"></span>
                        </td>
                        <td
                          style="text-align: center"
                          v-if="
                            isConsoValueVisible(
                              compteur.optionTarifaire,
                              'HC',
                              compteur.parametreCompteur
                            )
                          "
                        >
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un prix valide')]"
                            v-model="compteur.prixAchatHc"
                            label="HC"
                            outlined
                            dense
                          />
                        </td>
                        <td style="text-align: center" v-else>
                          <span class="mdi mdi-block-helper"></span>
                        </td>
                        <td
                          style="text-align: center"
                          v-if="
                            isConsoValueVisible(
                              compteur.optionTarifaire,
                              'POINTE',
                              compteur.parametreCompteur
                            )
                          "
                        >
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un prix valide')]"
                            v-model="compteur.prixAchatPointe"
                            label="POINTE"
                            outlined
                            dense
                          />
                        </td>
                        <td style="text-align: center" v-else>
                          <span class="mdi mdi-block-helper"></span>
                        </td>
                        <td
                          style="text-align: center"
                          v-if="
                            isConsoValueVisible(
                              compteur.optionTarifaire,
                              'BASE',
                              compteur.parametreCompteur
                            )
                          "
                        >
                          <v-text-field
                            hide-details
                            type="number"
                            :rules="[required('un prix valide')]"
                            v-model="compteur.prixAchatBase"
                            label="BASE"
                            outlined
                            dense
                          />
                        </td>
                        <td style="text-align: center" v-else>
                          <span class="mdi mdi-block-helper"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-card>
            <v-btn
              :disabled="!formStep3Model"
              :style="`background:${!formStep3Model ? '' : $styles.background}`"
              class="white--text mr-2"
              @click="next(3)"
            >
              CONTINUER
            </v-btn>
            <v-btn @click="Back(3)" text> RETOUR </v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-step
          step="4"
          :complete="stepperModel > 4"
          :style="`${
            stepperCompleted.includes(4) &&
            (stepperModel >= 4 || stepperModel + 1 == 4)
              ? 'cursor:pointer'
              : ''
          };width:fit-content`"
          @click="goTo(4, $refs.formStep4)"
          :color="$colors[0]"
        >
          INFORMATIONS CAPA
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-form ref="formStep4" v-model="formStep4Model">
            <v-card class="mb-6 elevation-0">
              <v-col
                class="pa-0 elevation-0 card_compteur_wrapper"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                v-if="listCompteurSelected.length > 0"
                style="overflow-x: 'auto'"
              >
                <div
                  class="card_compteur elevation-0 pa-0 my-0"
                  style="overflow: auto"
                >
                  <table class="compteur-table">
                    <thead>
                      <tr>
                        <th style="width: 10rem" class="text-center">
                          Compteur
                        </th>
                        <!-- type Capa -->
                        <th style="width: 10rem" class="text-center">CAPA</th>

                        <!-- CAPA -->
                        <template>
                          <th style="width: 10rem" class="text-center">
                            CAPA HPH
                          </th>
                          <th style="width: 10rem" class="text-center">
                            CAPA HCH
                          </th>
                          <th style="width: 10rem" class="text-center">
                            CAPA HPE
                          </th>
                          <th style="width: 10rem" class="text-center">
                            CAPA HCE
                          </th>
                          <th style="width: 10rem" class="text-center">
                            CAPA HP
                          </th>
                          <th style="width: 10rem" class="text-center">
                            CAPA HC
                          </th>
                          <th style="width: 10rem" class="text-center">
                            CAPA POINTE
                          </th>
                          <th style="width: 10rem" class="text-center">
                            CAPA BASE
                          </th>
                        </template>
                        <!--<th style="width:10rem" class="text-center">TURPE</th>
                        <th style="width:10rem" class="text-center">CEE</th>
                        <th style="width:10rem" class="text-center">ABO</th>-->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="compteur in listCompteurSelected"
                        v-if="
                          compteur.typeDeProduction == 'autoCollective' ||
                          compteur.typeDeProduction ==
                            'autoCollective&Individuelle' ||
                          compteur.typeDeProduction == 'consommation'
                        "
                      >
                        <td style="text-align: center">{{ compteur.PDL }}</td>
                        <!-- type Capa -->
                        <td style="text-align: center">
                          <v-select
                            hide-details
                            :rules="[required('une valeur valide')]"
                            v-model="compteur.typeCapa"
                            item-text="label"
                            item-value="value"
                            outlined
                            dense
                            :items="CapaValueList"
                          >
                          </v-select>
                          <!--<v-radio-group
                            v-model="compteur.typeCapa"
                            hide-details
                            row
                            class="ma-0 pa-0"
                          >
                            <v-radio label="Coût" value="CAPA"></v-radio>
                            <v-radio label="Coef" value="COEF"></v-radio>
                            <v-radio label="Inclus" value="CAPAINCLUS"></v-radio>
                          </v-radio-group>-->
                        </td>

                        <!-- CAPA TEST -->
                        <template>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'HPH',
                                compteur.parametreCompteur
                              ) && compteur.typeCapa != 'CAPAINCLUS'
                            "
                          >
                            <v-text-field
                              hide-details
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatCapaHph"
                              label="HPH"
                              outlined
                              dense
                            />
                          </td>
                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'HCH',
                                compteur.parametreCompteur
                              ) && compteur.typeCapa != 'CAPAINCLUS'
                            "
                          >
                            <v-text-field
                              hide-details
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatCapaHch"
                              label="HCH"
                              outlined
                              dense
                            />
                          </td>
                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'HPE',
                                compteur.parametreCompteur
                              ) && compteur.typeCapa != 'CAPAINCLUS'
                            "
                          >
                            <v-text-field
                              hide-details
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatCapaHpe"
                              label="HPE"
                              outlined
                              dense
                            />
                          </td>
                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'HCE',
                                compteur.parametreCompteur
                              ) && compteur.typeCapa != 'CAPAINCLUS'
                            "
                          >
                            <v-text-field
                              hide-details
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatCapaHce"
                              label="HCE"
                              outlined
                              dense
                            />
                          </td>
                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'HP',
                                compteur.parametreCompteur
                              ) && compteur.typeCapa != 'CAPAINCLUS'
                            "
                          >
                            <v-text-field
                              hide-details
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatCapaHp"
                              label="HP"
                              outlined
                              dense
                            />
                          </td>
                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'HC',
                                compteur.parametreCompteur
                              ) && compteur.typeCapa != 'CAPAINCLUS'
                            "
                          >
                            <v-text-field
                              hide-details
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatCapaHc"
                              label="HC"
                              outlined
                              dense
                            />
                          </td>
                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'POINTE',
                                compteur.parametreCompteur
                              ) && compteur.typeCapa != 'CAPAINCLUS'
                            "
                          >
                            <v-text-field
                              hide-details
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatCapaPointe"
                              label="POINTE"
                              outlined
                              dense
                            />
                          </td>
                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                          <td
                            style="text-align: center"
                            v-if="
                              isConsoValueVisible(
                                compteur.optionTarifaire,
                                'BASE',
                                compteur.parametreCompteur
                              ) && compteur.typeCapa != 'CAPAINCLUS'
                            "
                          >
                            <v-text-field
                              hide-details
                              :rules="[required('un prix valide')]"
                              v-model="compteur.prixAchatCapaBase"
                              label="BASE"
                              outlined
                              dense
                            />
                          </td>
                          <td style="text-align: center" v-else>
                            <span class="mdi mdi-block-helper"></span>
                          </td>
                        </template>

                        <!-- type turpe -->
                        <!--<td style="text-align:center">
                        <!--<v-select hide-details v-model="compteur.typeTurpe" item-text="label" item-value="value" outlined dense :items="TURPEValueList">
                        </v-select>-->
                        <!--<v-radio-group
                            class="mt-0"
                            row
                            hide-details
                            v-model="compteur.typeTurpe"
                          >
                            <v-radio label="Inclus" value="oui"></v-radio>
                            <v-radio label="non Inclus" value="non"></v-radio>
                          </v-radio-group>
                        </td>-->
                        <!--<td style="text-align:center">
                          <v-text-field outlined
                            dense hide-details v-model="compteur.cee" label="CEE" />
                        </td>
                        <td style="text-align:center">
                          <v-text-field outlined
                            dense hide-details v-model="compteur.abo" label="ABO" />
                        </td>-->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-card>
            <v-btn
              :disabled="!formStep4Model"
              :style="`background:${!formStep4Model ? '' : $styles.background}`"
              class="white--text mr-2"
              @click="next(4)"
            >
              CONTINUER
            </v-btn>
            <v-btn @click="Back(4)" text> RETOUR </v-btn>
          </v-form>
        </v-stepper-content>
        <v-stepper-step
          step="5"
          :complete="stepperModel > 5"
          :style="`${
            stepperCompleted.includes(5) &&
            (stepperModel >= 5 || stepperModel + 1 == 5)
              ? 'cursor:pointer'
              : ''
          };width:fit-content`"
          @click="goTo(5, $refs.formStep5)"
          :color="$colors[0]"
        >
          TURPE, CEE & ABO
        </v-stepper-step>
        <v-stepper-content step="5">
          <v-form ref="formStep5" v-model="formStep5Model">
            <v-card class="mb-6 elevation-0">
              <v-col
                class="pa-0 elevation-0 card_compteur_wrapper"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                v-if="listCompteurSelected.length > 0"
                style="overflow-x: 'auto'"
              >
                <div
                  class="card_compteur elevation-0 pa-0 my-0"
                  style="overflow: auto"
                >
                  <table class="compteur-table">
                    <thead>
                      <tr>
                        <th style="width: 10rem" class="text-center">
                          Compteur
                        </th>
                        <th style="width: 10rem" class="text-center">TURPE</th>
                        <th style="width: 10rem" class="text-center">CEE</th>
                        <th style="width: 10rem" class="text-center">ABO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="compteur in listCompteurSelected"
                        v-if="
                          compteur.typeDeProduction == 'autoCollective' ||
                          compteur.typeDeProduction ==
                            'autoCollective&Individuelle' ||
                          compteur.typeDeProduction == 'consommation'
                        "
                      >
                        <td style="text-align: center">{{ compteur.PDL }}</td>
                        <!-- type Capa -->

                        <!-- type turpe -->
                        <td style="text-align: center">
                          <div class="d-flex flex-column align-items-center">
                            <v-select
                              style="width: 50%"
                              :rules="[required('une valeur valide')]"
                              hide-details
                              v-model="compteur.typeTurpe"
                              item-text="label"
                              item-value="value"
                              outlined
                              dense
                              :items="TURPEValueList"
                            >
                            </v-select>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="d-flex flex-column align-items-center">
                            <v-text-field
                              outlined
                              style="width: 50%"
                              dense
                              hide-details
                              :rules="[required('un prix valide')]"
                              type="number"
                              v-model="compteur.cee"
                              label="CEE"
                            />
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="d-flex flex-column align-items-center">
                            <v-text-field
                              outlined
                              style="width: 50%"
                              dense
                              hide-details
                              :rules="[required('un prix valide')]"
                              type="number"
                              v-model="compteur.abo"
                              label="ABO"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-card>
            <div class="d-flex flex-row">
              <v-btn
                :disabled="!formStep5Model"
                :style="`background:${
                  !formStep5Model ? '' : $styles.background
                }`"
                class="white--text mr-2"
                @click="next(5)"
              >
                CONTINUER
              </v-btn>
              <v-btn @click="Back(5)" text> RETOUR </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
        <v-stepper-step
          step="6"
          :style="`${
            stepperCompleted.includes(6) &&
            (stepperModel >= 6 || stepperModel + 1 == 6)
              ? 'cursor:pointer'
              : ''
          };width:fit-content`"
          @click="goTo(6, $refs.formStep6)"
          :color="$colors[0]"
        >
          VISUALISATION & PDF
        </v-stepper-step>
        <v-stepper-content step="6">
          <div class="d-flex flex-row">
            <v-btn
              :style="`background:${
                !formStep6Model || btnEnvoyerDisabled ? '' : $styles.background
              }`"
              class="white--text mx-2"
              @click="sendPrixData"
            >
              Envoyer
            </v-btn>
            <div
              class="mx-2"
              v-if="
                recievedResult &&
                foundResultat == 'True' &&
                listCompteurSelected.length > 0 &&
                siret !== null
              "
            >
              <PdfPresentation :pdfData="resultBudgetSolar" />
            </div>
            <v-btn class="mx-2" @click="Back(6)" text> RETOUR </v-btn>
          </div>
        </v-stepper-content>
      </v-form>
    </v-stepper>
    <div class="d-flex flex-row justify-center" v-if="recievedResult">
      <v-card
        v-if="foundResultat == 'True'"
        class="my-4 pb-16"
        width="100%"
        color="white"
      >
        <div class="d-flex flex-row justify-center" style="margin-bottom: 30px">
          <v-card
            :style="`border-radius:0px;border:2px ${$colors[0]} solid`"
            class="ma-4"
            width="50%"
            min-height="100%"
          >
            <v-toolbar :style="{ background: $colors[0] }" dark>
              <span class="pl-0">Informations Globales</span>
            </v-toolbar>
            <div class="d-flex flex-row ma-8">
              <div
                class="d-flex flex-row justify-center align-items-center"
                :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
              >
                <v-icon class="white--text" size="40">mdi-poll</v-icon>
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Consommation Globale</span>
                <span class="Poppins-Bold" style="font-size: 20px"
                  >{{
                    formatNumber(resultBudgetSolar["sommeConsommation"])
                  }}
                  KWh</span
                >
              </div>
            </div>
            <div class="d-flex flex-row ma-8">
              <div
                class="d-flex flex-row justify-center align-items-center"
                :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
              >
                <v-icon class="white--text" size="40"
                  >mdi-chart-bell-curve-cumulative</v-icon
                >
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Production Globale</span>
                <span class="Poppins-Bold" style="font-size: 20px">
                  {{ formatNumber(resultBudgetSolar["sommeProduction"]) }}
                  KWh</span
                >
              </div>
            </div>
            <div class="d-flex flex-row ma-8">
              <div
                class="d-flex flex-row justify-center align-items-center"
                :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
              >
                <v-icon class="white--text" size="40">mdi-finance</v-icon>
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Surplus Global</span>
                <span class="Poppins-Bold" style="font-size: 20px">
                  {{ formatNumber(resultBudgetSolar["Reste_A_Vendre"]) }}
                  kWh</span
                >
              </div>
            </div>
            <div class="d-flex flex-row ma-8">
              <div
                class="d-flex flex-row justify-center align-items-center"
                :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
              >
                <v-icon class="white--text" size="40"
                  >mdi-timer-sand-complete</v-icon
                >
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Heures de Surplus</span>
                <span class="Poppins-Bold" style="font-size: 20px">{{
                  formatNumber(resultBudgetSolar["Nbr_heures_Vendre"])
                }}</span>
              </div>
            </div>
          </v-card>
          <v-card
            :style="`border-radius:0px;border:2px ${$colors[0]} solid`"
            class="ma-4"
            width="50%"
            min-height="100%"
          >
            <v-toolbar :style="{ background: $colors[0] }" dark>
              <span class="pl-0">Autoconsommation </span>
            </v-toolbar>
            <div class="d-flex flex-row ma-8">
              <div
                class="d-flex flex-row justify-center align-items-center"
                :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
              >
                <v-icon class="white--text" size="40"
                  >mdi-city-variant-outline</v-icon
                >
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Autoconsommation Collective</span>
                <span class="Poppins-Bold" style="font-size: 20px">
                  {{ formatNumber(resultBudgetSolar["sommeAutoCollectif"]) }}
                  KWh</span
                >
              </div>
            </div>
            <div class="d-flex flex-row ma-8">
              <div
                class="d-flex flex-row justify-center align-items-center"
                :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
              >
                <v-icon class="white--text" size="40">mdi-domain</v-icon>
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Autoconsommation Individuelle</span>
                <span class="Poppins-Bold" style="font-size: 20px">
                  {{
                    formatNumber(
                      resultBudgetSolar["sommeAutoConsommerIndividuelle"]
                    )
                  }}
                  KWh</span
                >
              </div>
            </div>
            <div class="d-flex flex-row ma-8">
              <div
                class="d-flex flex-row justify-center align-items-center"
                :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
              >
                <v-icon class="white--text" size="40"
                  >mdi-lightning-bolt</v-icon
                >
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Autoconsommation Totale</span>
                <span class="Poppins-Bold" style="font-size: 20px"
                  >{{
                    formatNumber(
                      resultBudgetSolar[
                        "sommeAutoCollectifEtIndividuelleGlobale"
                      ]
                    )
                  }}
                  KWh</span
                >
              </div>
            </div>
            <div class="d-flex flex-row ma-8">
              <div
                class="d-flex flex-row justify-center align-items-center"
                :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
              >
                <v-icon class="white--text" size="40">mdi-currency-eur</v-icon>
              </div>
              <div class="d-flex flex-column ml-4">
                <span>Budget Énergie Initial</span>
                <span class="Poppins-Bold" style="font-size: 20px">
                  {{ formatNumber(resultBudgetSolar["sommeBudgetInitial"]) }}
                  €/an</span
                >
              </div>
            </div>
          </v-card>
        </div>
        <v-card class="ma-4" :style="`border:2px solid ${$colors[0]}`">
          <v-toolbar :style="{ background: $colors[0] }" dark>
            <span class="pl-0">Budget</span>
          </v-toolbar>
          <div
            style="height: inherit"
            class="d-flex flex-row justify-space-between ma-8"
          >
            <div
              class="d-flex flex-row justify-center align-items-center"
              :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
            >
              <v-icon class="white--text" size="40">mdi-currency-eur</v-icon>
            </div>
            <div class="d-flex flex-column">
              <span>Budget Énergie Autoconsommation Collective</span>
              <span class="Poppins-Bold" style="font-size: 20px"
                >{{
                  formatNumber(
                    resultBudgetSolar["sommeBudgetSolarAutoCollectif"]
                  )
                }}
                €/an</span
              >
            </div>
            <div
              class="d-flex flex-row justify-center align-items-center"
              :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
            >
              <v-icon class="white--text" size="40">mdi-currency-eur</v-icon>
            </div>
            <div class="d-flex flex-column">
              <!--<span>sommeTAXE</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeTAXE"]) }} €/an</span>
            <span>sommeFOURNITURE</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeFOURNITURE"]) }} €/an</span>
            <span>sommeCTA</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeCTA"]) }} €/an</span>
            <span>sommeCSPE</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeCSPE"]) }} €/an</span>
            <span>sommeTVA</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeTVA"]) }} €/an</span>
            <span>sommeTurpeValue</span> 
            <span class="Poppins-Bold" style="font-size:20px"> {{ formatNumber(resultBudgetSolar["sommeTurpeValue"]) }} €/an</span>-->
              <span>Budget Énergie Fournisseur & Turpe</span>
              <span class="Poppins-Bold" style="font-size: 20px">
                {{ formatNumber(resultBudgetSolar["Budget_energie_total"]) }}
                €/an</span
              >
            </div>
            <div
              class="d-flex flex-row justify-center align-items-center"
              :style="`min-height:64px;min-width:64px;border-radius:5px;background:${$styles.background};max-width:50px;max-height:50px`"
            >
              <v-icon class="white--text" size="40">mdi-currency-eur</v-icon>
            </div>
            <div class="d-flex flex-column">
              <span>Budget Énergie Final</span>
              <span class="Poppins-Bold" style="font-size: 20px"
                >{{
                  formatNumber(
                    resultBudgetSolar["Budget_energie_total"] +
                      resultBudgetSolar["sommeBudgetSolarAutoCollectif"]
                  )
                }}
                €/an</span
              >
            </div>
          </div>
        </v-card>
        <div class="d-flex flex-row ma-4" v-if="recievedResult">
          <v-btn
            @click="showTable = !showTable"
            :color="$colors[0]"
            class="white--text"
            >Voir Details</v-btn
          >
        </div>
      </v-card>

      <div v-else-if="foundResultat == 'False'" class="result-message-card">
        <p class="result-message-title">
          le calcule est en traitement lorsque le calcule va finir tu va
          recevoir un email
        </p>
      </div>

      <div
        v-else-if="foundResultat == 'enTraitement'"
        class="result-message-card"
      >
        <p class="result-message-title">
          il y a au moins un calcule lancer avec c'est informations il faut
          attendre les resultat par email
        </p>
      </div>
    </div>
    <div
      class="pb-2"
      v-if="showTable && recievedResult && foundResultat == 'True'"
    >
      <v-card class="pa-2">
        <div class="d-flex flex-column mb-4">
          <small class="text-muted">* ACC = Autoconsommation Collective</small>
          <small class="text-muted">* AC = Autoconsommation</small>
          <small class="text-muted">* FRN = Fournisseur</small>
        </div>
        <v-data-table
          :headers="headers"
          hide-default-header
          :items="Object.values(resultBudgetSolar['listeCompteur'])"
          :items-per-page="10"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th
                  class="text-center"
                  v-for="h in headers"
                  style="
                    border-left: 1px solid white;
                    border-right: 1px solid white;
                    width: 9rem;
                  "
                >
                  <span>{{ h.text }}</span>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, rowIndex) in items" :key="item.Numero_Compteur">
                <td class="text-center" style="min-width: 130px">
                  <div>
                    <span>{{ item.Numero_Compteur }}</span>
                  </div>
                  <div>
                    <small class="text-muted">{{ item.typeProduction }}</small>
                  </div>
                </td>
                <td class="text-center" style="min-width: 130px">
                  <div>
                    <small class="text-muted">Conso: </small
                    >{{ formatNumber(item.Consommation) }} Kwh
                  </div>
                  <div>
                    <small class="text-muted">Prod: </small
                    >{{ formatNumber(item.Production) }} Kwh
                  </div>
                </td>
                <td class="text-center" style="min-width: 130px">
                  <div class="collapsible">
                    <div class="collapsible-header">
                      <div class="collapsible-title">
                        <div>
                          <small class="text-muted">AC Global: </small
                          >{{ formatNumber(item.Auto_Consommation_Total) }} Kwh
                        </div>
                      </div>
                      <div
                        @click="
                          toggleSection('repartitionDeEnergie' + rowIndex)
                        "
                        class="toggle-icon"
                      >
                        <span
                          v-if="isActive('repartitionDeEnergie' + rowIndex)"
                          class="mdi mdi-chevron-up"
                        ></span>
                        <span v-else class="mdi mdi-chevron-down"></span>
                      </div>
                    </div>
                    <div
                      v-show="isActive('repartitionDeEnergie' + rowIndex)"
                      class="content"
                    >
                      <div class="content-text">
                        <span class="content-text-label">AC Indiv: </span>
                        <span class="content-text-value"
                          >{{
                            formatNumber(item.sommeAutoConsommerIndividuelle)
                          }}
                          Kwh</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">AC Collec: </span>
                        <span class="content-text-value"
                          >{{
                            formatNumber(item.Auto_Consommation_Collectif)
                          }}
                          Kwh</span
                        >
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center" style="min-width: 130px">
                  <div>
                    <small class="text-muted">Conso Frn: </small
                    >{{ formatNumber(item.Reste_A_Acheter) }} Kwh
                  </div>
                </td>
                <td class="text-center" style="min-width: 130px">
                  <div class="collapsible">
                    <div class="collapsible-header">
                      <div class="collapsible-title">
                        <div>
                          <small class="text-muted">Budget Init: </small
                          >{{ formatNumber(item.budgetInitial) }} €/an
                        </div>
                      </div>
                      <div
                        @click="toggleSection('openBudgetSansPv' + rowIndex)"
                        class="toggle-icon"
                      >
                        <span
                          v-if="isActive('openBudgetSansPv' + rowIndex)"
                          class="mdi mdi-chevron-up"
                        ></span>
                        <span v-else class="mdi mdi-chevron-down"></span>
                      </div>
                    </div>
                    <div
                      v-show="isActive('openBudgetSansPv' + rowIndex)"
                      class="content"
                    >
                      <div class="content-text">
                        <span class="content-text-label">TAXE: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.TAXEInitial) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">FOURNITURE: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.FOURNITUREInitial) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">CTA: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.CTAInitial) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">CSPE: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.CSPEInitial) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">Acheminement: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.TurpeValueInitial) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">TVA: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.TVAInitial) }} €/an</span
                        >
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center" style="min-width: 130px">
                  <div class="collapsible">
                    <div class="collapsible-header">
                      <div class="collapsible-title">
                        <div>
                          <small class="text-muted">Budget FRN: </small
                          >{{ formatNumber(item.Budget_Energie) }} €/an
                        </div>
                      </div>
                      <div
                        @click="toggleSection('openBudgetAvecPv' + rowIndex)"
                        class="toggle-icon"
                      >
                        <span
                          v-if="isActive('openBudgetAvecPv' + rowIndex)"
                          class="mdi mdi-chevron-up"
                        ></span>
                        <span v-else class="mdi mdi-chevron-down"></span>
                      </div>
                    </div>
                    <div
                      v-show="isActive('openBudgetAvecPv' + rowIndex)"
                      class="content"
                    >
                      <div class="content-text">
                        <span class="content-text-label">TAXE: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.TAXE) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">FOURNITURE: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.FOURNITURE) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">CTA: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.CTA) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">CSPE: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.CSPE) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">Acheminement: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.TurpeValue) }} €/an</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">TVA: </span>
                        <span class="content-text-value"
                          >{{ formatNumber(item.TVA) }} €/an</span
                        >
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center" style="min-width: 130px">
                  <div>
                    <small class="text-muted">Budget ACC Achetée: </small
                    >{{ formatNumber(item.budgetSolarAutoCollectif) }} €/an
                  </div>
                  <div>
                    <small class="text-muted">Budget ACC Vendue: </small
                    >{{
                      formatNumber(
                        resultBudgetSolar["resultatDivisionSolar"][
                          item.Numero_Compteur
                        ]["budgetAccDonner"]
                      )
                    }}
                    €/an
                  </div>
                  <div>
                    <small class="text-muted">Prix: </small
                    >{{
                      formatNumber(
                        resultBudgetSolar["resultatDivisionSolar"][
                          item.Numero_Compteur
                        ]["prixVenteAcc"]
                      )
                    }}
                    €/Kwh
                  </div>
                </td>
                <td class="text-center" style="min-width: 130px">
                  <div class="collapsible">
                    <div class="collapsible-header">
                      <div class="collapsible-title">
                        <div>
                          <small class="text-muted">Budget SurPlus: </small
                          >{{
                            resultBudgetSolar["resultatDivisionSolar"][
                              item.Numero_Compteur
                            ]["budgetSurPlus"]
                          }}
                          €/an
                        </div>
                      </div>
                      <div
                        @click="toggleSection('openBudgetSurPlus' + rowIndex)"
                        class="toggle-icon"
                      >
                        <span
                          v-if="isActive('openBudgetSurPlus' + rowIndex)"
                          class="mdi mdi-chevron-up"
                        ></span>
                        <span v-else class="mdi mdi-chevron-down"></span>
                      </div>
                    </div>
                    <div
                      v-show="isActive('openBudgetSurPlus' + rowIndex)"
                      class="content"
                    >
                      <div class="content-text">
                        <span class="content-text-label">Prix: </span>
                        <span class="content-text-value"
                          >{{
                            resultBudgetSolar["prixVenteSurPlus"]
                          }}
                          €/KW</span
                        >
                      </div>
                      <div class="content-text">
                        <span class="content-text-label">Energie: </span>
                        <span class="content-text-value"
                          >{{
                            resultBudgetSolar["resultatDivisionSolar"][
                              item.Numero_Compteur
                            ]["sommeSurplus"]
                          }}
                          KWh</span
                        >
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center" style="min-width: 130px">
                  <div>
                    <small class="text-muted">Economie: </small
                    >{{
                      resultBudgetSolar["resultatDivisionSolar"][
                        item.Numero_Compteur
                      ]["economie"]
                    }}
                    €/an
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-card class="detail-card">
        <h4 class="simulation-eco-title">Simulation Économique</h4>
        <p class="simulation-eco-description">
          Cette simulation compare les coûts énergétiques avec et sans panneaux
          photovoltaïques sur plusieurs années, montrant les économies
          potentielles et les revenus générés par la vente de surplus d'énergie
          après la période d'amortissement.
        </p>
        <p class="simulation-eco-rsi">
          RSI sur {{ resultBudgetSolar["nbrAnneeRSI"] }} ans:
          <span
            >{{ formatNumber(resultBudgetSolar["sommeDeltaEconomie"]) }} €</span
          >
        </p>
        <div class="simulation-eco-chart-wrapper">
          <apexchart
            type="bar"
            height="400px"
            :options="RSIChartOptions"
            :series="RSIChartSeries"
          >
          </apexchart>
        </div>
        <div class="simulation-eco-chart-wrapper">
          <apexchart
            type="line"
            height="400px"
            :options="factureEnergieChartOptions"
            :series="factureEnergieChartSeries"
          >
          </apexchart>
        </div>
        <div class="table-simulation-eco-wrapper">
          <table class="table-simulation-eco">
            <thead>
              <tr>
                <th></th>
                <th>Prix du kWh annuelle (€) TTC</th>
                <th>Facture Energie (€) TTC sans PV</th>
                <th>Facture Energie (€) TTC avec PV</th>
                <th>Autoconsommation collective (€) TTC</th>
                <th>Vente du surplus de production (€) TTC</th>
                <th>Cout de la maintenance (€) TTC</th>
                <th>Delta €</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in resultBudgetSolar['simulationEcoList']"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    resultBudgetSolar["simulationEcoList"][index][
                      "prixDuKWhAnnuelleTTC"
                    ]
                  }}
                </td>
                <td>
                  {{
                    resultBudgetSolar["simulationEcoList"][index][
                      "factureEnergieTTCSansPV"
                    ]
                  }}
                </td>
                <td>
                  {{
                    resultBudgetSolar["simulationEcoList"][index][
                      "factureEnergieTTCAvecPV"
                    ]
                  }}
                </td>
                <td>
                  {{
                    resultBudgetSolar["simulationEcoList"][index][
                      "autoconsommationCollectiveTTC"
                    ]
                  }}
                </td>
                <td>
                  {{
                    resultBudgetSolar["simulationEcoList"][index][
                      "venteDuSurplusDeProductionTTC"
                    ]
                  }}
                </td>
                <td>
                  {{
                    resultBudgetSolar["simulationEcoList"][index][
                      "coutDeLaMaintenanceTTC"
                    ]
                  }}
                </td>
                <td>
                  {{ resultBudgetSolar["simulationEcoList"][index]["delta"] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { background } from "vue-plotly";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import loadingComponent from "@/components/Loading";
import TabSlider from "@/components/TabSlider.vue";

import html2canvas from "html2canvas";
import PdfPresentation from "../pdfTemplates/PdfPresentation.vue";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "CotationSolarCollectif",
  components: {
    loadingComponent,
    PdfPresentation,
    apexchart: VueApexCharts,
    TabSlider
  },
  data() {
    let typeClient = "Pro";

    return {
      typeClient: typeClient,
      foundResultat: false,
      btnEnvoyerDisabled: false,
      formStep1Model: true,
      formStep2Model: true,
      formStep3Model: true,
      formStep4Model: true,
      formStep5Model: true,
      stepperCompleted: [],
      formsRulesModel: null,
      emailParticulier: null,
      stepperModel: 1,
      CapaValueList: [
        { label: "Coût", value: "CAPA" },
        { label: "Coef", value: "COEF" },
        { label: "Inclus", value: "CAPAINCLUS" },
      ],
      TURPEValueList: [
        { label: "Inclus", value: "oui" },
        { label: "non Inclus", value: "non" },
      ],
      angleOptionList: [
        { label: "Oui", value: "oui" },
        { label: "Non", value: "non" },
      ],
      showTable: false,
      loading: false,
      messageDialoge: "",
      dialog: false,
      errorModal: "",
      nomInstalation: "",
      siret: null,
      listCompteur: [],
      listCompteurSelected: [],
      listSiret: [],
      siretFound: false,
      emailParticulierFound: false,
      recievedResult: false,
      resultBudgetSolar: {},
      RSIChartSeries: [
        {
          name: "Economie cumulée(€)",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      RSIChartOptions: {
        chart: {
          type: "bar",
          height: 400,
        },
        title: {
          text: "Recettes cumulées de l’installation",
          align: "center",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#14324F",
          },
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        colors: ["#548235"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          title: {
            text: "Années",
          },
          type: "numeric",
          tickAmount: "dataPoints", // to all x axis numeric numbers
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
          },
        },
        yaxis: {
          title: {
            text: "Economie cumulée(€)",
          },
          labels: {
            formatter: function (value) {
              return (
                Number(parseFloat(value).toFixed(2)).toLocaleString("fr-FR") +
                " €"
              );
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
      factureEnergieChartSeries: [
        {
          name: "Facture Energie (€) TTC avec PC",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Facture Energie (€) TTC sans PC",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      factureEnergieChartOptions: {
        chart: {
          id: "factureEnergieChart",
          group: "social",
          type: "line",
          height: 400,
        },
        title: {
          text: "SIMULATION FINANCIÈRE",
          align: "center",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#14324F",
          },
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: ["#5a9bd2", "#eb7d2d"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        stroke: {
          show: true,
          width: 3,
        },
        xaxis: {
          type: "numeric",
          tickAmount: "dataPoints", // to all x axis numeric numbers
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
          },
        },
        yaxis: {
          title: {
            text: "MONTANT (€)",
          },
          labels: {
            formatter: function (value) {
              return (
                Number(parseFloat(value).toFixed(2)).toLocaleString("fr-FR") +
                " €"
              );
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
      headers: [
        {
          text: "Compteur",
          align: "center",
          sortable: false,
          thStyle: "border-right:1px solid white",
        },
        {
          text: "Répartition de PV",
          align: "center",
          sortable: false,
        },
        {
          text: "Répartition de l'Énergie",
          align: "center",
          sortable: false,
        },
        {
          text: "Énergie Restante à Consommer après Installation PV",
          align: "center",
          sortable: false,
        },
        {
          text: "Budget avant Installation PV",
          align: "center",
          sortable: false,
        },
        {
          text: "Budget après Installation PV",
          align: "center",
          sortable: false,
        },
        {
          text: "Budget de Consommation Solaire Collectif",
          align: "center",
          sortable: false,
        },
        {
          text: "Surplus d'Énergie",
          align: "center",
          sortable: false,
        },
        {
          text: "Économie Réalisée",
          align: "center",
          sortable: false,
        },
      ],

      rules: {
        isPositiveNumb: (v) =>
          /^[0-9]\d*$/.test(v) ||
          "La valeur doit etre un entier strictement supérieur à 0",
        required(fieldName) {
          return (value) => !!value || `Veuillez saisir ${fieldName}`;
        },

        siretLength: (v) =>
          (!!v && v?.length == 14) || "Le siret doit comporter 14 chiffres",

        isMonthCorrect: (v) =>
          (v >= 1 && v <= 12) || "Le mois doit etre compris entre 1 et 12 ",

        isNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",

        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",

        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",

        phoneLength: (v) =>
          v.length == 10 ||
          "Le numéro de téléphone est une suite de 10 chiffres",

        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",

        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

        Length: (v) => v.length > 0 || "Veuillez saisir un type d'offre",
        isBetweenRangeNumber: (v) =>
          (v >= 2 && v <= 25) ||
          "La valeur saisie doit etre comprise entre 2 et 25",

        emailRules: (v) => (!!v && /.+@.+\.+.+/.test(v)) || "E-mail invalide",
      },
      required(fieldName) {
        return (value) =>
          !!value ||
          value >= 0 ||
          (!!value && value != "") ||
          `Veuillez saisir ${fieldName}`;
      },
      openSections: {},
    };
  },
  mounted() {},
  computed: {
    icon() {
      if (this.listCompteurSelected.length == this.listCompteur.length)
        return "mdi-close-box";
      else if (
        this.listCompteurSelected.length > 0 &&
        this.listCompteurSelected.length < this.listCompteur.length
      )
        return "mdi-minus-box";
      else if (this.listCompteurSelected.length == 0)
        return "mdi-checkbox-blank-outline";
    },
    showCAPA() {
      var showCp = false;
      this.listCompteurSelected.some((item) => {
        showCp =
          item.typeCapa != "CAPAINCLUS" &&
          (item.typeDeProduction == "autoCollective" ||
            item.typeDeProduction == "autoCollective&Individuelle" ||
            item.typeDeProduction == "consommation");
      });
      return showCp;
    },
  },
  watch: {
    siret: function (newValue) {
      if (this.siretFound) {
        this.siretFound = false;
      }
    },
    listCompteurSelected: {
      handler(val) {
        if (this.btnEnvoyerDisabled) {
          this.btnEnvoyerDisabled = false;
        }
        if (this.recievedResult) {
          this.recievedResult = false;
        }
      },
      deep: true,
    },
    resultBudgetSolar: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (
          this.resultBudgetSolar &&
          this.resultBudgetSolar["simulationEcoList"]
        ) {
          this.RSIChartSeries[0].data = this.resultBudgetSolar[
            "simulationEcoList"
          ].map((item) => item.delta);
          this.factureEnergieChartSeries[0].data = this.resultBudgetSolar[
            "simulationEcoList"
          ].map((item) => item.factureEnergieTTCAvecPV);
          this.factureEnergieChartSeries[1].data = this.resultBudgetSolar[
            "simulationEcoList"
          ].map((item) => item.factureEnergieTTCSansPV);
        }
      },
    },
  },
  methods: {
    toggleSection(title) {
      if (this.openSections[title]) {
        this.$delete(this.openSections, title);
      } else {
        this.$set(this.openSections, title, true);
      }
    },
    isActive(title) {
      return !!this.openSections[title];
    },
    Back(stepFrom) {
      let arrayListCompteurTypeProductionCompteur =
        this.listCompteurSelected.filter((element) =>
          [
            "production",
            "autoCollective",
            "autoCollective&Individuelle",
          ].includes(element.typeDeProduction)
        );
      let arrayListCompteurTypeProductionCompteurProd =
        this.listCompteurSelected.filter((element) =>
          [
            "consommation",
            "autoCollective",
            "autoCollective&Individuelle",
          ].includes(element.typeDeProduction)
        );
      console.log(arrayListCompteurTypeProductionCompteurProd);
      if (
        stepFrom == 6 &&
        arrayListCompteurTypeProductionCompteurProd.length == 0
      ) {
        this.stepperModel = 2;
      } else if (
        stepFrom == 3 &&
        arrayListCompteurTypeProductionCompteur.length == 0
      ) {
        this.stepperModel = 1;
      } else {
        this.stepperModel -= 1;
      }
    },
    goTo(stepTo, formRules) {
      let arrayListCompteurTypeProductionCompteur =
        this.listCompteurSelected.filter((element) =>
          [
            "production",
            "autoCollective",
            "autoCollective&Individuelle",
          ].includes(element.typeDeProduction)
        );
      let arrayListCompteurTypeProductionCompteurProd =
        this.listCompteurSelected.filter((element) =>
          [
            "consommation",
            "autoCollective",
            "autoCollective&Individuelle",
          ].includes(element.typeDeProduction)
        );

      console.log("ici");
      if (stepTo == 2 && arrayListCompteurTypeProductionCompteur.length > 0) {
        if (this.stepperCompleted.includes(stepTo)) {
          if (this.stepperModel >= stepTo || this.stepperModel + 1 == stepTo) {
            this.formsRulesModel = formRules;
            this.stepperModel = stepTo;
          }
        }
      }
      if (
        (stepTo == 3 || stepTo == 4) &&
        arrayListCompteurTypeProductionCompteurProd.length > 0
      ) {
        if (this.stepperCompleted.includes(stepTo)) {
          if (this.stepperModel >= stepTo || this.stepperModel + 1 == stepTo) {
            this.formsRulesModel = formRules;
            this.stepperModel = stepTo;
          }
        }
      } else {
        if (this.stepperCompleted.includes(stepTo)) {
          if (this.stepperModel >= stepTo || this.stepperModel + 1 == stepTo) {
            this.formsRulesModel = formRules;
            this.stepperModel = stepTo;
          }
        }
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (!!this.listCompteurSelected && !!this.listCompteur) {
          if (this.listCompteurSelected.length == this.listCompteur.length) {
            this.listCompteurSelected = [];
          } else {
            this.listCompteurSelected = [...this.listCompteur];
          }
        }
      });
    },
    next(step) {
      switch (step) {
        case 1:
          if (
            (this.$refs.formStep1.validate() &&
              (!!this.siret || !!this.emailParticulier)) ||
            this.listCompteurSelected.length > 0
          ) {
            this.formsRulesModel = this.$refs.formStep2;
            let arrayListCompteurTypeProductionCompteur =
              this.listCompteurSelected.filter((element) =>
                [
                  "production",
                  "autoCollective",
                  "autoCollective&Individuelle",
                ].includes(element.typeDeProduction)
              );
            console.log(arrayListCompteurTypeProductionCompteur);
            if (arrayListCompteurTypeProductionCompteur.length > 0) {
              this.stepperModel = 2;
              if (this.stepperCompleted.length == 0) {
                this.stepperCompleted.push(1);
                this.stepperCompleted.push(2);
              } else if (!this.stepperCompleted.includes(2)) {
                this.stepperCompleted.push(2);
              }
            } else {
              this.stepperModel = 3;
              if (this.stepperCompleted.length == 0) {
                this.stepperCompleted.push(1);
                this.stepperCompleted.push(3);
              } else if (!this.stepperCompleted.includes(3)) {
                this.stepperCompleted.push(3);
              }
            }
          }
          break;
        case 2:
          if (this.$refs.formStep2.validate()) {
            let arrayListCompteurTypeProductionCompteur =
              this.listCompteurSelected.filter((element) =>
                [
                  "consommation",
                  "autoCollective",
                  "autoCollective&Individuelle",
                ].includes(element.typeDeProduction)
              );
            console.log(arrayListCompteurTypeProductionCompteur.length, "ICI");
            if (arrayListCompteurTypeProductionCompteur.length > 0) {
              this.stepperModel = 3;
              console.log(this.formsRulesModel);
              this.formsRulesModel = this.$refs.formStep3;
              if (!this.stepperCompleted.includes(3)) {
                this.stepperCompleted.push(3);
              }
            } else {
              this.stepperModel = 6;
              if (!this.stepperCompleted.includes(6)) {
                this.stepperCompleted.push(6);
              }
            }
          }
          break;
        case 3:
          if (this.$refs.formStep3.validate()) {
            console.log(this.formsRulesModel);
            this.stepperModel = 4;
            this.formsRulesModel = this.$refs.formStep4;
            if (!this.stepperCompleted.includes(4)) {
              this.stepperCompleted.push(4);
            }
          }
          break;
        case 4:
          if (this.$refs.formStep4.validate()) {
            this.formsRulesModel = this.$refs.formStep5;
            this.stepperModel = 5;
            if (!this.stepperCompleted.includes(5)) {
              this.stepperCompleted.push(5);
            }
          }
          break;
        case 5:
          if (this.$refs.formStep4.validate()) {
            this.formsRulesModel = this.$refs.formStep5;
            this.stepperModel = 6;
            if (!this.stepperCompleted.includes(6)) {
              this.stepperCompleted.push(6);
            }
          }
          break;
        default:
          break;
      }
    },
    formatNumber(input) {
      input = "" + input;
      // Remove non-numeric characters (comma or quotes) and parse as float
      const numericValue = parseFloat(input.replace(",", ""));

      // Check if the parsed value is a valid number
      if (!isNaN(numericValue)) {
        // Format the number using the French locale
        return numericValue.toLocaleString("fr-FR");
      } else {
        // Handle invalid input, for example, return the input as is
        return input;
      }
    },
    async selectAllCompteur() {
      if (this.listCompteurSelected.length == this.listCompteur.length) {
        this.listCompteurSelected = [];
      } else {
        this.listCompteurSelected = [...this.listCompteur];
      }
    },
    async getPropositionSociete() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getPropositionSiretSolar/",
        method: "POST",
        data: {
          token: this.$cookies.get("token"),
          siret: this.siret,
        },
      })
        .then(async (response) => {
          if (response.data.result != false) {
            this.listSiret = response.data.result;
          }
        })
        .catch((err) => {});
    },
    async getListeCompteurBySiret() {
      this.loading = true;
      this.messageDialoge = "";
      this.dialog = true;
      this.errorModal = false;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getListeCompteurBySiret/",
        method: "POST",
        data: {
          token: this.$cookies.get("token"),
          siret: this.siret,
        },
      })
        .then(async (response) => {
          if (response.data.result != false) {
            this.listCompteur = response.data.result;
            if (this.listCompteur.length == 0) {
              this.siretFound = false;
              this.messageDialoge = "no PDL";
              this.dialog = true;
              this.errorModal = true;
            } else {
              this.siretFound = true;
              this.messageDialoge = "no PDL";
              this.dialog = false;
              this.errorModal = false;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.siretFound = false;
          this.loading = false;
          this.messageDialoge = "no PDL";
          this.dialog = true;
        });
    },
    async getListeCompteurByEmail() {
      this.loading = true;
      this.messageDialoge = "";
      this.dialog = true;
      this.errorModal = false;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getListeCompteurByEmail/",
        method: "POST",
        data: {
          token: this.$cookies.get("token"),
          email: this.emailParticulier,
        },
      })
        .then(async (response) => {
          if (response.data.result != false) {
            this.listCompteur = response.data.result;
            if (this.listCompteur.length == 0) {
              this.emailParticulierFound = false;
              this.messageDialoge = "no PDL";
              this.dialog = true;
              this.errorModal = true;
            } else {
              this.emailParticulierFound = true;
              this.messageDialoge = "no PDL";
              this.dialog = false;
              this.errorModal = false;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.emailParticulierFound = false;
          this.loading = false;
          this.messageDialoge = "no PDL";
          this.dialog = true;
        });
    },
    isConsoValueVisible(optionTarifaire, consoType, paramCpt) {
      const compteursOptionTarifaires = {
        // *********** C3 ***********************
        HTACU5: {
          segment: "C3",
          cadrant: ["HPH", "HPE", "HCH", "HCE", "POINTE"],
        },
        HTALU5: {
          segment: "C3",
          cadrant: ["HPH", "HPE", "HCH", "HCE", "POINTE"],
        },
        HTACUPM5: {
          segment: "C3",
          cadrant: ["HPH", "HPE", "HCH", "HCE", "POINTE"],
        },
        HTALUPM5: {
          segment: "C3",
          cadrant: ["HPH", "HPE", "HCH", "HCE", "POINTE"],
        },

        // *********** C4 ***********************
        BTSUPCU4: {
          segment: "C4",
          cadrant: ["HPH", "HPE", "HCH", "HCE"],
        },
        BTSUPLU4: {
          segment: "C4",
          parametreCompteur: "C4",
          cadrant: ["HPH", "HPE", "HCH", "HCE"],
        },

        // *********** C5 : 4 cadrant ***********

        BTINFCU4: {
          segment: "C5",
          // parametreCompteur C4
          cadrant: ["HPH", "HPE", "HCH", "HCE"],
          // parametreCompteur C5HP
          cadrantC5HP: ["HP", "HC"],
          // parametreCompteur C5BASE
          cadrantC5BASE: ["BASE"],
        },
        BTINFMU4: {
          segment: "C5",
          // parametreCompteur C4
          cadrant: ["HPH", "HPE", "HCH", "HCE"],
          // parametreCompteur C5HP
          cadrantC5HP: ["HP", "HC"],
          // parametreCompteur C5BASE
          cadrantC5BASE: ["BASE"],
        },

        // *********** C5 HP ********************
        BTINFMUDT: {
          segment: "C5",
          cadrant: ["HP", "HC"],
        },

        // *********** C5 BASE ******************
        BTINFLU: {
          segment: "C5",
          cadrant: ["BASE"],
        },
        BTINFCUST: {
          segment: "C5",
          cadrant: ["BASE"],
        },
      };

      if (!optionTarifaire) {
        return false;
      }

      const selectedCompteur = compteursOptionTarifaires[optionTarifaire];

      if (!selectedCompteur) {
        return false;
      }

      let visible = false;
      if (["BTINFCU4", "BTINFMU4"].includes(optionTarifaire)) {
        if (paramCpt == "C5HP") {
          visible = selectedCompteur.cadrantC5HP.includes(consoType);
        } else if (paramCpt == "C5BASE") {
          visible = selectedCompteur.cadrantC5BASE.includes(consoType);
        } else {
          visible = selectedCompteur.cadrant.includes(consoType);
        }
      } else {
        visible = selectedCompteur.cadrant.includes(consoType);
      }

      return visible;
    },

    async sendPrixData(e) {
      // && this.btnEnvoyerDisabled == false
      if (this.$refs.formStep5.validate()) {
        this.btnEnvoyerDisabled = true;
        this.recievedResult = false;
        // if(this.$refs.formCompteur.validate()){
        //   e.preventDefault()
        //   console.log(this.listCompteurSelected)
        // }
        this.loading = true;
        this.dialog = true;
        this.errorModal = false;
        e.preventDefault();

        // if()
        // add axios request
        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT +
            "solarCollectifCalculeWithThread/",
          method: "POST",
          data: {
            token: this.$cookies.get("token"),
            nomInstalation: this.nomInstalation,
            cmpts: this.listCompteurSelected,
          },
        })
          .then(async (response) => {
            if (response.data.length != 0) {
              this.foundResultat = response.data.foundResultat;
              this.recievedResult = true;
              this.resultBudgetSolar = response.data;
              this.loading = false;
              this.dialog = true;
              this.errorModal = true;
            } else {
              this.loading = false;
              this.dialog = false;
              this.errorModal = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.dialog = false;
            this.errorModal = false;
          });
      }
    },
  },
};
</script>

<!-- style page -->
<style lang="scss" scoped>
::v-deep .v-input--selection-controls__input .v-icon {
  font-size: large;
}
::v-deep .v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 0px !important;
}
.styled-table {
  width: 100%;
  border-collapse: collapse;
}
.collapsible-wrapper {
  font-family: Arial, sans-serif;
}

.collapsible {
  margin: 20px 0;
}

.content {
  text-align: left;
  cursor: pointer;
  padding: 8px;
  overflow: hidden;
  background-color: #f4faff;
  border: 1px solid #dee2e6;
}

.content-text {
  display: inline-block;
  width: max-content;
  .content-text-label {
    font-size: 11px;
    font-weight: 500;
    color: #84929f;
  }
  .content-text-value {
    font-size: 11px;
    font-weight: 400;
    color: #000;
  }
}

.toggle-icon {
  font-weight: 700;
  font-size: 24px;
  background: rgb(135, 158, 181);
  color: #fff;
  border-radius: 4px;
  height: 24px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggle-icon:hover {
  cursor: pointer;
  background-color: rgb(135, 158, 181, 0.8);
}
.styled-table th,
.styled-table td {
  width: 100px;
  min-width: 100px;
  border: 1px solid #ddd;
  padding: 8px;
}

.styled-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4ca8af;
  color: white;
}

.card_compteur_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(var(20, 218, 245), 0.1);
  padding: 16px;
  border-radius: 8px;
  .card_compteur {
    position: relative;
    display: block;
    overflow-x: auto;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 150ms;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 8px 24px rgba(89, 112, 145, 0.12);

    .compteur-table {
      width: 100%;
      display: table;
      border-collapse: collapse;
      box-sizing: border-box;
      text-indent: initial;
      border-spacing: 1px;
      border-radius: 6px;
      overflow: hidden;

      * {
        position: relative;
      }

      thead tr {
        background-color: rgb(4, 177, 161);
        font-size: 16px;
        color: #fff;
      }

      tbody tr {
        background-color: rgb(237, 242, 242);
        border-bottom: 1px solid rgb(221, 228, 228);
        &:last-child {
          border: 0;
        }
      }

      td,
      th {
        padding: 16px 8px;
        text-align: left;
        border: 1px solid rgb(221, 228, 228);
        min-width: 100px;
        &:first-child {
          width: 50px;
        }
      }
      th {
        font-size: 0.875rem;
        font-weight: 700;
      }
      td {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}

.v-data-table--loading .v-data-table__td {
  opacity: 1;
}

:deep(.v-data-table) {
  .v-table__wrapper > table tbody > tr > td {
    border-bottom: 1px solid #dee2e6 !important;
    border-left: 1px solid #dee2e6 !important;
    border-right: 0px solid !important;
  }
  .v-data-table__wrapper > table > thead {
    background-color: #04b1a1 !important;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    color: #fff !important;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    border-left: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
  }
}
</style>

<!-- simulation eco -->
<style lang="scss" scoped>
.detail-card {
  margin-top: 20px !important;
  padding: 24px 20px !important;
}
.simulation-eco-title {
  color: #14324f;
  font-size: 24px;
  margin-bottom: 10px;
}
.simulation-eco-description {
  color: #393d41;
  font-size: 14px;
  margin-bottom: 32px;
}
.simulation-eco-rsi {
  font-size: 16px;
  color: #04b1a1;
  font-weight: 700;
  margin-bottom: 20px;

  span {
    font-size: 16px;
    color: #084480;
  }
}
.simulation-eco-chart-wrapper {
  margin-bottom: 20px;
  border: 1px solid #e1dede;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px #eae8e8;
}
.table-simulation-eco-wrapper {
  width: 100%;
  overflow-x: auto;
}
.table-simulation-eco {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 8px 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
  th {
    font-size: 12px;
    font-weight: 500;
    background: #04b1a1;
    text-align: center;
    color: #f4f4f4;
  }
  tbody {
    tr {
      background-color: #f4f4f4;

      td {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #000;
        &:first-child {
          background-color: #dee5f1;
        }
        &:last-child {
          background-color: #dee5f1;
        }
      }
    }
  }
}
</style>

<!-- result message -->
<style lang="scss" scoped>
.result-message-card {
  width: 100%;
  background: white;
  margin-top: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0px 4px 8px 0px rgb(196 196 196 / 65%);
  .result-message-title {
    margin-bottom: 0px;
    color: #3aaab7;
  }
}
</style>
