<template>
    <div
      v-show="selectedInstallation == installationId"
      :data-id="installationId"
      class="outer-container"
      @mousedown.stop.prevent
    >
      <div
        class="hide-grid"
        v-show="!isHidden"
  
        @click="
          () => {
            this.isActive = !this.isActive;
          }
        "
        :style="{ height: height * 0.07 + 'px' }"
      >
        <img src="/hidden.png" style="height: 80%;width: 80%;" />
      </div>
  
      <div :style="{ transform: `rotate(${angle}deg)` }" class="grid-container">
        <div
          v-for="cell in grid"
          :key="`${cell.x}-${cell.y}`"
          :class="getCellClasses(cell)"
          :style="getCellStyle(cell)"
          @mousedown.prevent="handleClick(cell)"
        >
          <img
            v-if="cell.type === 'selected'"
            class="cell-image"
            :src="require('@/assets/solar_module_small.jpg')"
          />
        </div>
        <div
          v-if="isActive"
          class="rotation-arrow"
          :style="getArrowStyle"
          @mousedown.prevent="startRotation"
          v-show="!isHidden"
        >
          <img :style="{ height: height * 0.065 + 'px' }" src="/iconRotateZ.png" />
        </div>
        <img
          v-if="isActive"
          :style="{ height: height * 0.065 + 'px' }"
          src="/iconTranslateXY.png"
          class="drag-handle"
          @mousedown.prevent="startDrag"
          v-show="!isHidden"
        />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      installationId: {
        type: Number,
        required: false,
      },
      selectedInstallation: {
        type: Number,
      },
      startX: {
        type: Number,
        default: 0,
      },
      showed: {
        type: Boolean,
        default: true,
      },
      startY: {
        type: Number,
        default: 0,
      },
      orientation: {
        type: Number,
        default: 0,
      },
      width: {
        type: Number,
        required: true,
      },
      height: {
        type: Number,
        required: true,
      },
      onDrag: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        grid: [],
        isMouseDown: false,
        angle: 0,
        isDragging: false,
        initialBounds: null,
        dragStartX: 0,
        dragStartY: 0,
        expanded: true,
        isActive: true,
        isHidden: false,
      };
    },
    computed: {
      selectedCellCount() {
        return this.grid.filter(cell => cell.type === 'selected').length;
      }
    },
    watch: {
      width: "updateGrid",
      height: "updateGrid",
      showed: {
        handler: function(val) {
          this.expanded = val;
        },
        immediate: true
      },
      orientation: {
        handler(newValue, oldValue) {
          this.angle = this.orientation;
        },
        deep: true,
        immediate: true,
      },
      selectedCellCount(newCount) {
        this.$emit("selectedPannelsChange", { nbrPanels: newCount });
      },
      isHidden(newVal) {
        if (newVal) {
          this.isActive = false;
        } else {
          this.isActive = true;
        }
      }
    },
    mounted() {
      this.initializeGrid();
      console.log("this.panel", this.expanded);
    },
    methods: {
      initializeGrid() {
        this.setCell(this.startX, this.startY, "selected");
        this.addPlaceholders(this.startX, this.startY);
      },
      updateGrid() {
        this.$forceUpdate(); // Force re-render to update the grid dimensions
      },
      setCell(x, y, type) {
        const existingCell = this.grid.find(
          (cell) => cell.x === x && cell.y === y
        );
        if (existingCell) {
          existingCell.type = type;
        } else {
          this.grid.push({ x, y, type });
        }
      },
      getCell(x, y) {
        return this.grid.find((cell) => cell.x === x && cell.y === y);
      },
      handleClick(cell) {
        if (this.isActive) {
          if (cell.type === "placeholder") {
            this.setCell(cell.x, cell.y, "selected");
            this.addPlaceholders(cell.x, cell.y);
          } else if (cell.type === "selected" && this.selectedCellCount >1) {
            this.setCell(cell.x, cell.y, "placeholder");
            this.removePlaceholders(cell.x, cell.y);
          }
        }
      },
      addPlaceholders(x, y) {
        const directions = [
          { x: 0, y: -1 },
          { x: 1, y: 0 },
          { x: 0, y: 1 },
          { x: -1, y: 0 },
        ];
  
        directions.forEach((dir) => {
          const newX = x + dir.x;
          const newY = y + dir.y;
          if (!this.getCell(newX, newY)) {
            this.setCell(newX, newY, "placeholder");
          }
        });
      },
      removePlaceholders(x, y) {
        const directions = [
          { x: 0, y: -1 },
          { x: 1, y: 0 },
          { x: 0, y: 1 },
          { x: -1, y: 0 },
        ];
  
        directions.forEach((dir) => {
          const newX = x + dir.x;
          const newY = y + dir.y;
          const adjacentCell = this.getCell(newX, newY);
          if (adjacentCell && adjacentCell.type === "placeholder") {
            const adjacentDirections = directions.filter((d) => d !== dir);
            const shouldBePlaceholder = adjacentDirections.some((ad) => {
              const checkX = newX + ad.x;
              const checkY = newY + ad.y;
              const checkCell = this.getCell(checkX, checkY);
              return checkCell && checkCell.type === "selected";
            });
            if (!shouldBePlaceholder) {
              this.grid.splice(this.grid.indexOf(adjacentCell), 1);
            }
          }
        });
      },
      getCellStyle(cell) {
        const cellWidth = this.width / 10; // Adjust the number of cells as needed
        const cellHeight = this.height / 10; // Adjust the number of cells as needed
        return {
          top: `${cell.y * cellHeight}px`,
          left: `${cell.x * cellWidth}px`,
          width: `${cellWidth}px`,
          height: `${cellHeight}px`,
        };
      },
      getCellClasses(cell) {
        let classes = ["cell", cell.type];
        if (!this.isActive && cell.type === "placeholder") {
          classes.push("no-border");
        }
        if (cell.type === "selected") {
          classes.push("selected");
        }
        return classes;
      },
  
      getArrowStyle() {
        return {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%) rotate(${this.angle}deg) translateY(-50%)`,
          cursor: "pointer",
          zIndex: 1000,
        };
      },
      startRotation(event) {
        const rect = this.$el.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
  
        const startVector = {
          x: event.clientX - centerX,
          y: event.clientY - centerY,
        };
        let initialAngle = Math.atan2(startVector.y, startVector.x);
  
        const rotate = (event) => {
          const currentVector = {
            x: event.clientX - centerX,
            y: event.clientY - centerY,
          };
          const currentAngle = Math.atan2(currentVector.y, currentVector.x);
          const deltaAngle = currentAngle - initialAngle;
  
          this.angle = (this.angle + deltaAngle * (180 / Math.PI) + 360) % 360;
          this.$emit("orientationChange", { orientation: this.angle });
          initialAngle = currentAngle; // Update the initialAngle for the next move
        };
  
        const stopRotation = (event) => {
          window.removeEventListener("mousemove", rotate);
          window.removeEventListener("mouseup", stopRotation);
        };
  
        window.addEventListener("mousemove", rotate);
        window.addEventListener("mouseup", stopRotation);
      },
      startDrag(event) {
        this.isDragging = true;
        this.dragStartX = event.clientX;
        this.dragStartY = event.clientY;
        window.addEventListener("mousemove", this.drag);
        window.addEventListener("mouseup", this.stopDrag);
      },
      drag(event) {
        if (this.isDragging) {
          const dx = event.clientX - this.dragStartX;
          const dy = event.clientY - this.dragStartY;
          this.onDrag(dx, dy);
          this.dragStartX = event.clientX;
          this.dragStartY = event.clientY;
        }
      },
      stopDrag() {
        this.isDragging = false;
        window.removeEventListener("mousemove", this.drag);
        window.removeEventListener("mouseup", this.stopDrag);
      },
    },
  };
  </script>
  
  <style scoped>
  @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
  
  .outer-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    transform-origin: center;
  }
  .grid-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .cell {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    cursor: pointer;
  }
  .cell-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire cell */
  }
  .selected {
    border: 1px solid yellow; /* Adding yellow border for selected cells */
  }
  .placeholder {
    border: 1px solid white;
  }
  .rotation-arrow {
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: default;
  }
  .drag-handle {
    position: absolute;
    left: 50%; /* Adjusted position */
    top: 50%; /* Adjusted position */
    transform: translate(110%, -50%);
    cursor: move;
    z-index: 1000;
  }
  .hide-grid {
    position: absolute;
    left: 250%; /* Adjusted position */
    top: 50%; /* Adjusted position */
    transform: translate(260%, -50%);
    cursor: pointer;
    z-index: 1000;
    background-color: white;
    border-radius: 999px;
    display: flex;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
  }
  .hide-grid img {
    height: 20px;
    width: 20px;
  }
  .no-border {
    border: none !important;
  }
  </style>
  