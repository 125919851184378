<template>
  <div class="mx-auto">
    <v-row v-if="loading || errorModal">
      <loadingComponent
        @closeDialog="closeLoading($event)"
        :loadingProps="loading"
        :messageDialogeProps="messageDialoge"
        :dialogProps="dialog"
        :errorProps="errorModal"
      />
    </v-row>
    <div style="width: 100%" class="d-flex justify-center mb-2 mt-5">
      <v-card width="100%" class="ma-5 pa-4 pt-0">
        <v-form ref="formGis">
            <div v-if="resulat" class ="mt-4 float-right">
              <EnefficPresentation
                :compteur="PVGISdata.PDL"
                :pdfData="{
                  pageDeGardeTitle: PVGISdata?.typeClient == 'Particulier' ? `Offre ${dataProfileParticulier?.nom} ${dataProfileParticulier?.prenom}` : `OFFRE ${dataSociete?.raison}`,
                  RespName: `${dataCompte?.nom} ${dataCompte?.prenom}`,
                  RespNom: dataCompte?.nom,
                  RespPrenom: dataCompte?.prenom,
                  RespFonction: `Directeur administratif et financier`,
                  RespEmail: dataCompte?.email,
                  RespTel: dataCompte?.telephone,
                  ClientName: PVGISdata?.typeClient == 'Particulier' ? `${dataProfileParticulier?.nom} ${dataProfileParticulier?.prenom}` : `${dataSociete?.nom} ${dataSociete?.prenom}`,
                  ClientNom: PVGISdata?.typeClient == 'Particulier' ? dataProfileParticulier?.nom : dataSociete?.nom,
                  ClientPrenom: PVGISdata?.typeClient == 'Particulier' ? dataProfileParticulier?.prenom : dataSociete?.prenom,
                  ClientSociete: PVGISdata?.typeClient == 'Particulier' ? dataProfileParticulier?.adresseEmail : dataSociete?.raison,
                  ClientTel: PVGISdata?.typeClient == 'Particulier' ? dataProfileParticulier?.numTele : dataSociete?.siret,
                  PuissanceProjetKwc: puissance_kw_c,
                  ProductionAttendueKWh: productionTotal,
                  NombreDePannaux: laResultat.autoConsommation.nombrePanneaux,
                  address: PVGISdata?.adresse,
                  addressImage: null,
                  CoutTotalInstallation: laResultat.autoConsommation.coutInstallation,
                  RevenusExploitationMoyenAnnuelAttendu: laResultat.autoConsommation.revenueExploitationAnnuelle,
                  ChargeExploitation: chargesExploitation,
                  TempsDeRetour: laResultat.autoConsommation.nbrAnneePourCommencerAGagne,
                  BudgetPhotovoltaique: budgetPhotovoltaique,
                  PrimeInvestissement: primeInvestissement,
                  GainAnnuelMoyen: laResultat.autoConsommation.gainAnnuelMoyen,
                  totalEconomie: laResultat.autoConsommation.totalEconomie,
                  totalVenteSurplus: laResultat.autoConsommation.totalSurplus,
                  totalGains: laResultat.autoConsommation.totalGain,
                  tableAmortissement: laResultat.autoConsommation.tableauAmourtisement,
                  consoProdChartSeries: seriesConsoProd,
                  gainsChartSeries: seriesGains,
                  amortissementChartSeries: seriesAmortissement,
                  gainsChartSeriesTotal: seriesGainsTotal,
                  amortissementChartSeriesTotal: seriesAmortissementTotal,
                }"
              />
          </div>
        </v-form>
      </v-card>
    </div>
    <v-row id="pagePDF">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-row class="my-5 mx-2">
          <v-col
            v-if="resulatCdc == true || resulatMesures == true"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <cardMesures
              :allDataCourbe="allDataCourbe"
              titre="Consommation"
              valueMesure="consommationMesures"
              :PVGISdata="PVGISdata"
              :condition="resulatCdc"
            />
          </v-col>
          <v-col
            v-if="allDataCourbe.consommationApresVente"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <cardMesures
              :allDataCourbe="allDataCourbe"
              titre="Apres production"
              :valueMesure="'consommationApresVente'"
              :PVGISdata="PVGISdata"
            />
          </v-col>
          <v-col v-if="resulat == true" cols="12" sm="12" md="6" lg="6" xl="6">
            <cardMesures
              :allDataCourbe="allDataCourbe"
              titre="Production"
              valueMesure="consommationSolaire"
              :PVGISdata="PVGISdata"
            />
          </v-col>
          <div class="row my-3 mx-1" v-if="validateCourbes">
            <v-row>
              <v-col
                v-if="courbe_charge_status == 1"
                cols="12"
                md="12"
                sm="12"
                lg="12"
                xl="12"
              >
                <v-row>
                  <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                    <h3>Revente total</h3>
                  </v-col>
                  <v-col cols="12" md="4" sm="4" lg="4" xl="4">
                    <cardCustom
                      icon="mdi mdi-solar-power-variant"
                      title="Budget production"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="totalBudgetProduction"
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="4" lg="4" xl="4">
                    <cardCustom
                      icon="mdi mdi-finance"
                      title="Budget consommation"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="totalBudgetConsommation"
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="4" lg="4" xl="4">
                    <cardCustom
                      icon="mdi mdi-chart-pie"
                      title="Différence production consommation"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="Math.abs(totalBudgetConsommation - totalBudgetProduction)"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="resulatDiff" cols="12" md="12" sm="12" lg="12" xl="12">
                <v-row>
                  <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                    <h3>Auto consommation</h3>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-chart-bell-curve-cumulative"
                      title="Production a Vendre"
                      :unite="'KWh'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.totalProdctionDiffVente"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-shopping"
                      title="Budget vente"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.totalBudgetDiffVente"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-currency-eur"
                      title="Budget achat"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.totalBudgetDiffAchat"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-solar-power-variant"
                      title="Économies sur les Factures Énergétique"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.econnomie"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="resulatDiff" cols="12" md="12" sm="12" lg="12" xl="12">
                <v-row>
                  <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                    <h3>Etude</h3>
                  </v-col>
                  
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom2Values
                      icon="mdi mdi-finance"
                      title="Revenus d'exploitation annuel"

                      :subTitle1="'Auto'"
                      :unite1="'€/an'"
                      :color1="$colors[0]"
                      :total1="laResultat.autoConsommation.revenueExploitationAnnuelle"
                      
                      :subTitle2="'Total'"
                      :unite2="'€/an'"
                      :color2="$colors[0]"
                      :total2="laResultat.reventeTotale.revenueExploitationAnnuelle"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-chart-pie"
                      title="Prix d'installation par Kw crete"
                      :unite="'€'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.prixInstallation"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-chart-pie"
                      title="Coût Total de l'installation"
                      :unite="'€'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.coutInstallation"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom2Values
                      icon="mdi mdi-chart-pie"
                      title="Temps de retour"

                      :subTitle1="'Auto'"
                      :unite1="''"
                      :color1="$colors[0]"
                      :total1="laResultat.autoConsommation.nbrAnneePourCommencerAGagne"
                      
                      :subTitle2="'Total'"
                      :unite2="''"
                      :color2="$colors[0]"
                      :total2="laResultat.reventeTotale.nbrAnneePourCommencerAGagne"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <div class="ma-2" v-if="validateCourbes && courbe_charge_status == 1">
          <v-row>
            <v-col cols="6">
              <v-card
                id="cardProConsoHeure"
                style="box-shadow: none; margin-bottom: 2rem"
              >
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de production et de consommation par heure
                  </h6>
                </v-card-title>
                <VueApexCharts
                  id="chartProdConsoHeure"
                  v-if="resulat == true"
                  type="line"
                  ref="chartRefHoraire"
                  :options="chartOptionsKW"
                  :series="seriesGlob"
                  :height="350"
                />
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card id="cardProConsoMois" style="box-shadow: none">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de production et de consommation par mois
                  </h6>
                </v-card-title>
                <VueApexCharts
                  id="chartProdConsoMois"
                  v-if="resulat == true"
                  type="bar"
                  ref="chartRefBar"
                  :options="chartOptionsBarKW"
                  :series="seriesGlobMonth"
                  :height="350"
                />
              </v-card>
            </v-col>

            <v-col cols="6" style="padding-top: 10px">
              <v-card style="box-shadow: none" class="m-2">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de différence entre production et consommation par heure
                  </h6>
                </v-card-title>
                <VueApexCharts
                  v-if="resulatDiff == true"
                  type="line"
                  ref="chartRefHoraire1"
                  :options="chartOptionsKW"
                  :series="seriesGlobDiff"
                  :height="350"
                />
              </v-card>
            </v-col>
            <v-col cols="6" style="padding-top: 10px">
              <v-card style="box-shadow: none" class="m-2">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de différence entre production et consommation par mois
                  </h6>
                </v-card-title>
                <VueApexCharts
                  v-if="resulatDiff == true"
                  type="bar"
                  ref="chartRefBar1"
                  :options="chartOptionsBarKW"
                  :series="seriesGlobDiffMonth"
                  :height="350"
                />
              </v-card>
            </v-col>

            <v-col cols="12" style="padding-top: 10px">
              <v-card id="chartBudgetHeure" style="box-shadow: none">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de budget par heure
                  </h6>
                </v-card-title>
                <VueApexCharts
                  v-if="resulat == true"
                  type="line"
                  ref="chartRefHoraireBudget"
                  :options="chartOptions"
                  :series="seriesBudget"
                  :height="350"
                />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card id="cardDifferentiel" style="box-shadow: none" class="m-2">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe ventes/achats par heure
                  </h6>
                </v-card-title>
                <VueApexCharts
                  v-if="resulatDiff == true"
                  type="line"
                  ref="chartRefHoraireBudget"
                  :options="chartOptionsMultiColor"
                  :series="seriesGlobDiffBudget"
                  :height="350"
                />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import loadingComponent from "@/components/Loading";
import VueApexCharts from "vue-apexcharts";
import cardCustom from "./components/cardCustom.vue";
import cardCustom2Values from "./components/cardCustom2Values.vue";
import cardMesures from "./components/cardMesures.vue";
import expansionColumn from "./components/expansionColumn.vue";
import EnefficPresentation from "./pdfTemplates/EnefficPresentation.vue";
export default {
  name: "results_PVGIS",
  components: {
    VueApexCharts,
    cardCustom,
    cardCustom2Values,
    cardMesures,
    expansionColumn,
    EnefficPresentation,
    loadingComponent,
  },
  name: "results_PVGIS",
  data() {
    return {
      // valeur d'economie
      num_compteur:"",
      courbe_charge_status: 0,
      chargesExploitation: 0,
      puissance_kw_c: 0,
      productionTotal: 0,
      budgetPhotovoltaique: 0,
      primeInvestissement: 0,
      seriesConsoProd: null,
      seriesGains: null,
      seriesAmortissement: null,
      seriesGainsTotal:null,
      seriesAmortissementTotal:null,
      
      laResultat: {
          reventeTotale:{
            econnomie: 0,
            venteSurplus: 0,
            nombrePanneaux: 0,
            revenueExploitationAnnuelle: 0,
            gainAnnuelMoyen: 0,
            tableauAmourtisement: [],
            totalEconomie: 0,
            totalSurplus: 0,
            totalGain: 0,
            nbrAnneePourCommencerAGagne: "",
            coutInstallation: 0,
            prixInstallation: 0,

          },
          autoConsommation:{
            econnomie: 0,
            venteSurplus: 0,
            nombrePanneaux: 0,
            revenueExploitationAnnuelle: 0,
            gainAnnuelMoyen: 0,
            tableauAmourtisement: [],
            totalEconomie: 0,
            totalSurplus: 0,
            totalGain: 0,
            nbrAnneePourCommencerAGagne: "",
            coutInstallation: 0,
            prixInstallation: 0,
            totalBudgetDiffVente: 0,
            totalProdctionDiffVente: 0,
            totalBudgetDiff: 0,
            totalBudgetDiffAchat: 0,
          }
      },

      dataSociete: {},
      dataCompte: {},
      dataProfileParticulier: {},
      infosSocieteList: [
        { icon: "mdi mdi-meter-electric", titre: "PDL", valeur: this.PVGISdata?.PDL },
        {
          icon: "mdi mdi-map-marker-radius",
          titre: "Adresse",
          valeur: this.PVGISdata?.adresse,
        },
      ],
      infosInstallationsList: [
        { icon: "mdi mdi-angle-acute", titre: "Inclinaison", resultat: "incliner" },
        { icon: "mdi mdi-ruler-square-compass", titre: "Angle", resultat: "orientation" },
        { icon: "m²", titre: "Taille", resultat: "tailleInstalation" },
        { icon: "mdi mdi-counter", titre: "Panneaux", resultat: "nbrPanaux" },
        { icon: "%", titre: "Pourcentage de perte", resultat: "loss" },
        { icon: "mdi mdi-solar-power", titre: "Puissance", resultat: "puissanceKWc" },
      ],
      infosConsoProdList: [
        { icon: "mdi mdi-solar-power-variant", titre: "consommation", resultat: 0 },
        { icon: "mdi mdi-finance", titre: "production", resultat: 0 },
        { icon: "mdi mdi-chart-pie", titre: "différence", resultat: 0 },
      ],
      PVGISdata: {
        typeClient: "",
        PDL: "",
        adresse: "",
        parametreCompteur: "",
        segment: "",
        optionTarifaire: "",
        adresse: "",
        Longitude: "",
        Latitude: "",
        nbrInstallation: 1,
        Installations: [
          {
            angleOption: "non",
            tailleInstalation: "",
            nbrPanaux: "",
            incliner: "30",
            inclinOptimal: "0",
            orientation: "90",
            loss: 11,
            puissanceKWc: "",
          },
        ],
        prixVenteHph: "10",
        lesPrix: {
          prixAchatHPH: null,
          prixAchatHCH: null,
          prixAchatHPE: null,
          prixAchatHCE: null,
          prixAchatPointe: null,
          prixAchatHP: null,
          prixAchatHC: null,
          prixAchatBASE: null,
          prixAchatCapaHPH: null,
          prixAchatCapaHCH: null,
          prixAchatCapaHPE: null,
          prixAchatCapaHCE: null,
          prixAchatCapaPointe: null,
          prixAchatCapaHP: null,
          prixAchatCapaHC: null,
          prixAchatCapaBASE: null,
          typeCapa: "CAPA",
          typeTurpe: "non",
          ABO: "0",
          CEE: "0",
          typeFournisseur: "Fournisseur",
          parametreCompteur: null,
        },
      },
      // true = heure , false = jour
      // modal
      dialog: false,
      loading: false,
      messageDialoge: "",
      errorModal: false,

      resulat: false,
      resulatDiff: false,
      resulatCdc: false,
      resulatMesures: false,
      allDataCourbe: {},
      // true = heur , false = jour
      isChecked: true,

      chartOptions: {
        noData: {
          text: "Loading data...", // The custom "No Data" message or loader
          align: "center", // Position of the message ('left', 'center', or 'right')
          verticalAlign: "middle", // Vertical position of the message ('top', 'middle', or 'bottom')
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000",
            fontSize: "14px",
            fontFamily: "Arial",
          },
        },
        chart: {
          id: "line-chart",
          animations: {
            enabled: false, // Disable animations for better performance with large data
          },
        },
        xaxis: {
          type: "datetime",// Use datetime type for x-axis
          labels: {
            datetimeFormatter: {
              year: "MMM",
              month: "MMM",
              day: "dd MMM",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return `${Number(value).toLocaleString("fr-FR")} €`;
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        legend: {
          height: 50,
          horizontalAlign: "center",
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              Number(
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
              ).toLocaleString("fr-FR") +
              " €"
            );
          },
        },
        tooltip: {
          shared: false,
          x: {
            format: "dd MMM HH:mm", // Format the date-time display in the tooltip
          },
          y: {
            formatter: function (val) {
              return "";
            },
            title: {
              formatter: function (val, opts) {
                return (
                  val +
                  " " +
                  Number(
                    opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
                  ).toLocaleString("fr-FR") +
                  "€"
                );
              },
            },
          },
        },
        grid: {
          show: false,
          //borderColor: "#f1f1f1",
        },
      },
      chartOptionsMultiColor: {},
      chartOptionsBar: {
        grid: {
          show: false,
        },
        chart: {
          type: "bar",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime", // Use datetime type for x-axis
          labels: {
            datetimeFormatter: {
              year: "MMM",
              month: "MMM",
              day: "dd MMM",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number(value).toLocaleString("fr-FR");
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return "";
            },
            title: {
              formatter: function (val, opts) {
                return (
                  Number(
                    opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
                  ).toLocaleString("fr-FR") + " €"
                );
              },
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            barWidth: "10", // Set to true for horizontal bars, false for vertical bars
            dataLabels: {
              background: "transparent", // Set to 'transparent' to remove the background texture on the bars
            },
          },
        },
      },
      chartOptionsBarKW: {},
      chartOptionsKW: {},
      chartSeriesHoraire: null,
      chartSeriesMonth: null,
      dataCdcHours: null,
      seriesGlob: null,
      seriesGlobMonth: null,
      seriesBudget: null,
      seriesBudgetMonth: null,

      dataProdectionMonths: null,
      dataConsommationnMonths: null,
      //diff
      chartSeriesDiffHoraire: null,
      chartSeriesDiffMonth: null,
      diffirenceHours: null,
      diffirenceMonths: null,
      seriesGlobDiff: null,
      seriesGlobDiffMonth: null,
      showValidateBtn: false,
      showInfoInput: false,
      showCordonnerInput: false,
      showAddressInput: false,
      validateCourbes: false,
      chartSeriesBudgetHoraire: null,
      chartSeriesBudgetMonth: null,
      totalBudgetConsommation: null,
      totalBudgetProduction: null,
      chartSeriesDiffHoraireBudget: null,
      seriesGlobDiffBudget: null,
      consommationApresVente: null,
    };
  },
  mounted() {
    this.chartOptionsKW = JSON.parse(JSON.stringify(this.chartOptions));
    this.chartOptionsBarKW = JSON.parse(JSON.stringify(this.chartOptionsBar));
    this.chartOptionsMultiColor = JSON.parse(JSON.stringify(this.chartOptions));
    this.chartOptionsMultiColor.colors = ["#FF0000", "#00FF00"];
    this.chartOptionsMultiColor.yaxis.labels.formatter = function (value) {
      return `${Number(value).toLocaleString("fr-FR")} €`;
    };
    this.chartOptionsBarKW.yaxis.labels.formatter = function (value) {
      return `${Number(value).toLocaleString("fr-FR")} KWH`;
    };
    this.chartOptionsKW.yaxis.labels.formatter = this.chartOptionsBarKW.yaxis.labels.formatter;
  },
  computed: {
    roleUser() {
      return this.$store.getters.getUserRole;
    },
    puissanceKvaTotal: function () {
      let total = 0;
      this.PVGISdata.Installations.forEach((element) => {
        total += Number(element.puissanceKWc);
      });
      return total;
    },
    NombrePanneauxTotal: function () {
      let total = 0;
      this.PVGISdata.Installations.forEach((element) => {
        total += Number(element.nbrPanaux);
      });
      return total;
    },
    keyAllData: function () {
      return this.resulatMesures
        ? "consommationMesures"
        : this.resulatCdc
        ? "ConsomationCdc"
        : "";
    },
  },
  created() {
    this.get_solar_result();
     
    
    
    
  },
  methods: {
    async get_solar_result(){
      this.dialog = true;
      this.loading = true;
      this.messageDialoge = "";
      this.errorModal = false;
      await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + `get_solar_result/`,
          method: "POST",
          data: {
            nom: this.$route.query.c,
          },
        })
        .then(async (response) => {
            if (response.data.result == true) {
              this.allDataCourbe = response.data;
              this.courbe_charge_status = response.data.Courbe_De_Charge_status;
              if (response.data.Courbe_De_Charge_status == 1) {
                this.num_compteur = response.data.PDL
                this.productionTotal = this.allDataCourbe.total_value;
                this.resulatCdc = true;
                this.resulatMesures = true;
                this.dataProdectionHours = response.data.solar_data.SolarHours_as_json;
                this.dataConsommationHours = response.data.consommation_data.ConsoHours_as_json;
                this.dataProdectionMonths = response.data.solar_data.SolarMonths_as_json;
                this.dataConsommationnMonths = response.data.consommation_data.ConsoMonths_as_json;
                this.totalBudgetConsommation = response.data.consommation_data.totalBudgetConsommation;
                this.totalBudgetProduction = response.data.solar_data.totalBudgetSolar;

                // pdf charts
                const dataChartConsommation = response.data.consommation_data.ConsoMonths_as_json.map(
                  (item) => item.value
                );
                const dataChartProduction = response.data.solar_data.SolarMonths_as_json.map(
                  (item) => item.value
                );
                this.seriesConsoProd = [
                  {
                    name: "Consommation Energitique",
                    type: "column",
                    data: dataChartConsommation,
                  },
                  {
                    name: "Production solaire",
                    type: "area",
                    data: dataChartProduction,
                  },
                ];
                console.log("BEFORE INIT GRAPH");
                await this.initGraphs();
                await this.initGraphsBudget();
                this.resulat = true;
              } else if (response.data.Courbe_De_Charge_status == 0) {
                this.resulatCdc = false;
                this.resulatMesures = true;
              } else if (response.data.Courbe_De_Charge_status == -1) {
                this.resulatCdc = false;
                this.resulatMesures = false;
              }


              //get les donner des diffirences

              
              this.resulatDiff = true;
              this.diffirenceHours = response.data.diff.hours.data;
              this.diffirenceMonths = response.data.diff.months.data;
              this.laResultat.autoConsommation.nombrePanneaux = response.data.diff.nbrPanaux;
              this.laResultat.autoConsommation.totalBudgetDiffAchat = response.data.diff.hours.sum_buy;
              this.laResultat.autoConsommation.econnomie = response.data.diff.econnomie;
              this.laResultat.autoConsommation.venteSurplus = response.data.diff.venteSurplus;
              this.laResultat.autoConsommation.revenueExploitationAnnuelle = response.data.diff.revenueExploitationAnnuelle;
              this.laResultat.autoConsommation.nbrAnneePourCommencerAGagne =response.data.diff.nbrAnneePourCommencerAGagne;
              this.laResultat.autoConsommation.coutInstallation = response.data.diff.coutInstallation;
              this.laResultat.autoConsommation.prixInstallation = response.data.diff.prixInstallation;
              this.laResultat.autoConsommation.tableauAmourtisement = response.data.diff.tableauAmourtisement;
              this.laResultat.autoConsommation.gainAnnuelMoyen = response.data.diff.gainTotalMoyenne;
              this.laResultat.autoConsommation.totalEconomie = response.data.diff.leTotaleDeEnconomie;
              this.laResultat.autoConsommation.totalSurplus = response.data.diff.leTotaleDeSurplus;
              this.laResultat.autoConsommation.totalGain = response.data.diff.leTotaleDeGain;
              this.laResultat.autoConsommation.totalBudgetDiffVente = response.data.diff.hours.sum_sell;
              this.laResultat.autoConsommation.totalProdctionDiffVente = response.data.diff.hours.sum_sell_Conso;
              this.laResultat.autoConsommation.totalBudgetDiff = response.data.diff.totalBudgetDiff;
              this.laResultat.reventeTotale.revenueExploitationAnnuelle = response.data.revenueExploitationAnnuelleReventeTotal;
              this.laResultat.reventeTotale.nbrAnneePourCommencerAGagne = response.data.nbrAnneePourCommencerAGagneReventeTotal;
              this.puissance_kw_c = response.data.diff.puissance_kw_c;
              this.chargesExploitation = 0;
              this.seriesGains = [
                {
                  name: "ECONOMIES SUR FACTURES",
                  data: response.data.diff.tableauAmourtisement.map((item) => item.enconomie),
                },
                {
                  name: "VENTE DE SURPLUS",
                  data: response.data.diff.tableauAmourtisement.map((item) => item.surplus),
                },
              ];
              this.seriesAmortissement = [
                {
                  name: "Amortissement",
                  data: response.data.diff.tableauAmourtisement.map(
                    (item) => item.amortisementTempsGraph
                  ),
                },
              ];
              this.seriesGainsTotal = [
                {
                  name: "ECONOMIES SUR FACTURES",
                  data: response.data.tableauAmourtisementReventeTotal.map((item) => item.enconomie),
                },
                {
                  name: "VENTE DE SURPLUS",
                  data: response.data.tableauAmourtisementReventeTotal.map((item) => item.surplus),
                },
              ];
              this.seriesAmortissementTotal = [
                {
                  name: "Amortissement",
                  data: response.data.tableauAmourtisementReventeTotal.map(
                    (item) => item.amortisementTempsGraph
                  ),
                },
              ];
              this.allDataCourbe["consommationApresVente"] = response.data.diff.hours.consommationApresVente;
              await this.initGraphsDiff();
              this.resulat = true;
              this.validateCourbes = true;
            }
            this.getAdressePDL(this.num_compteur);
            this.dialog = false;
            this.loading = false;
          })


    },
    to_fixed2(num) {
      return parseFloat(num).toFixed(2);
    },
    async initGraphs() {
      console.log("INIT GRAPH ------------------------------------------------------");
      this.chartSeriesHoraire = [
        {
          name: "Production Solaire",
          data: this.dataProdectionHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
        {
          name: "Consommation Énergétique",
          data: this.dataConsommationHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
      ];

      this.chartSeriesMonth = [
        {
          name: "Production Solaire",
          data: this.dataProdectionMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
        {
          name: "Consommation Énergétique",
          data: this.dataConsommationnMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
      ];
      this.seriesGlob = this.chartSeriesHoraire;
      this.seriesGlobMonth = this.chartSeriesMonth;
      console.log("INIT GRAPH ------------------------------------------------------");
    },
    async initGraphsDiff() {
      this.chartSeriesDiffHoraire = [
        {
          name: "Diff",
          data: this.diffirenceHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
      ];
      this.chartSeriesDiffMonth = [
        {
          name: "Diff Months",
          data: this.diffirenceMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
      ];
      this.chartSeriesDiffHoraireBudget = [
        {
          name: "Achats",
          data: this.diffirenceHours.map((item) => {
            if (item.vente_ou_achat == "achat") {
              return { x: new Date(item.date).getTime(), y: this.to_fixed2(item.Budget) };
            } else {
              return { x: new Date(item.date).getTime(), y: 0 };
            }
          }),
        },
        {
          name: "Ventes",
          data: this.diffirenceHours.map((item, index) => {
            if (item.vente_ou_achat == "vente") {
              //this.chartSeriesDiffHoraireBudget[1]["data"][index-1] = this.chartSeriesDiffHoraireBudget[0]["data"][index-1];
              return { x: new Date(item.date).getTime(), y: this.to_fixed2(item.Budget) };
            } else {
              return { x: new Date(item.date).getTime(), y: 0 };
            }
          }),
        },
      ];
      let skip = false;
      this.diffirenceHours.map((item, index) => {
        if (skip) {
          skip = false;
          return;
        }
        if (index == 0 || index == this.diffirenceHours.lenght) {
          return;
        }
        if (
          this.chartSeriesDiffHoraireBudget[0]["data"][index].y == null &&
          this.chartSeriesDiffHoraireBudget[0]["data"][index - 1].y != null
        ) {
          this.chartSeriesDiffHoraireBudget[0]["data"][
            index
          ] = this.chartSeriesDiffHoraireBudget[1]["data"][index];
          skip = true;
        }
        if (
          this.chartSeriesDiffHoraireBudget[1]["data"][index].y == null &&
          this.chartSeriesDiffHoraireBudget[1]["data"][index - 1].y != null
        ) {
          this.chartSeriesDiffHoraireBudget[1]["data"][
            index
          ] = this.chartSeriesDiffHoraireBudget[0]["data"][index];
          skip = true;
        }
        return;
      });
      //Date(item.date).getTime()
      this.seriesGlobDiffBudget = this.chartSeriesDiffHoraireBudget;
      this.seriesGlobDiff = this.chartSeriesDiffHoraire;
      this.seriesGlobDiffMonth = this.chartSeriesDiffMonth;
    },
    async initGraphsBudget() {
      console.log("INIT GRAPH ------------------------------------------");
      console.log(this.dataProdectionHours);
      console.log(this.dataConsommationHours);
      this.chartSeriesBudgetHoraire = [
        {
          name: "Budget production solaire",
          data: this.dataProdectionHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.budget_total),
          })),
        },
        {
          name: "Budget consommation énergétique",
          data: this.dataConsommationHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.budget),
          })),
        },
      ];
      console.log(this.dataProdectionMonths);
      console.log(this.dataConsommationnMonths);
      this.chartSeriesBudgetMonth = [
        {
          name: "Budget production solaire",
          data: this.dataProdectionMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.budget_total),
          })),
        },
        {
          name: "Budget consommation énergétique",
          data: this.dataConsommationnMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.budget),
          })),
        },
      ];
      this.seriesBudget = this.chartSeriesBudgetHoraire;
      this.seriesBudgetMonth = this.chartSeriesBudgetMonth;
      console.log("INIT GRAPH ------------------------------------------");
    },
    async getAdressePDL(compteur) {
      await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + `getAdressePDL/`,
          method: "POST",
          data: {
            PDL: compteur,
          },
        })
          .then((response) => {
            
            this.PVGISdata.PDL = compteur
            this.dataSociete = response.data.dataSociete;
            this.dataCompte = response.data.dataCompte;
            this.dataProfileParticulier = response.data.dataProfileParticulier;
            this.PVGISdata.typeClient = response.data.typeClient;
            this.PVGISdata.adresse = response.data.adresse;
            this.PVGISdata.parametreCompteur = response.data.parametreCompteur;
            this.PVGISdata.segment = response.data.segment;
            this.PVGISdata.optionTarifaire = response.data.optionTarifaire;
            if (
              !(
                this.PVGISdata.adresse === undefined ||
                this.PVGISdata.adresse === null ||
                this.PVGISdata.adresse === ""
              )
            ) {
              this.showAddressInput = true;
              if (response.data.pvgis_status) {
                this.$set(
                  this.PVGISdata,
                  "Installations",
                  response.data.pvgis.installation
                );
                this.PVGISdata.nbrInstallation = this.PVGISdata.Installations.length;
                this.PVGISdata.Longitude = response.data.pvgis.longitude;
                this.PVGISdata.Latitude = response.data.pvgis.latitude;
                let lesPrix = {
                  prixAchatHPH: response.data.pvgis.prixAchatHph,
                  prixAchatHCH: response.data.pvgis.prixAchatHch,
                  prixAchatHPE: response.data.pvgis.prixAchatHpe,
                  prixAchatHCE: response.data.pvgis.prixAchatHce,
                  prixAchatPointe: response.data.pvgis.prixAchatPointe,
                  prixAchatHP: response.data.pvgis.prixAchatHp,
                  prixAchatHC: response.data.pvgis.prixAchatHc,
                  prixAchatBASE: response.data.pvgis.prixAchatBase,
                  prixAchatCapaHPH: response.data.pvgis.prixAchatCapaHph,
                  prixAchatCapaHCH: response.data.pvgis.prixAchatCapaHch,
                  prixAchatCapaHPE: response.data.pvgis.prixAchatCapaHpe,
                  prixAchatCapaHCE: response.data.pvgis.prixAchatCapaHce,
                  prixAchatCapaPointe: response.data.pvgis.prixAchatCapaPointe,
                  prixAchatCapaHP: response.data.pvgis.prixAchatCapaHp,
                  prixAchatCapaHC: response.data.pvgis.prixAchatCapaHc,
                  prixAchatCapaBASE: response.data.pvgis.prixAchatCapaBase,
                  typeCapa: "CAPA",
                  typeTurpe: response.data.pvgis.turpe,
                  ABO: response.data.pvgis.abo,
                  CEE: response.data.pvgis.cee,
                  typeFournisseur: "Fournisseur",
                  parametreCompteur: response.data.parametreCompteur,
                };
                this.PVGISdata.lesPrix = lesPrix;

                this.showAddressInput = true;
                this.showCordonnerInput = true;
                this.showInfoInput = true;
              }
            } else {
              this.showAddressInput = false;
            }
          })
          .catch((errors) => {
            console.log(errors);
          });
        this.infosSocieteList[0].valeur = this.PVGISdata.PDL;
        this.infosSocieteList[1].valeur = this.PVGISdata.adresse;
    },

  },
};
</script>
<style scoped>
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("../../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

.Poppins-Bold {
  font-family: "Poppins-Bold";
}

::v-deep
  .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: white !important;
}
::v-deep .v-input--selection-controls .v-radio > .v-label {
  margin: 0px !important;
}

::v-deep .v-sheet.theme--dark.elevation-0.v-toolbar {
  height: fit-content !important;
}

::v-deep .v-sheet.theme--dark.elevation-0.v-toolbar > .v-toolbar__content {
  height: fit-content !important;
}
</style>

<style scoped>
::v-deep .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  margin-bottom: 0px;
}
</style>
