<template>
    <div class="modal">
      <div class="modal-content">
        <div class="close" style="font-size: 32px" @click="$emit('close')">
          &times;
        </div>
  
        <div style="padding: 20px">
          <h2>Sélectionner Module</h2>
        </div>
        <div class="panel-options">
          <table>
            <thead>
              <tr>
                <th>Fabriquant</th>
                <th>Cote</th>
                <th>Code</th>
                <th style="width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="panel in panelOptions" :key="panel.type">
                <td>
                  <img
                    :src="require(`@/assets/solar-panel.png`)"
                    alt="panel img"
                    class="panel-image"
                  />{{ panel.type }}
                </td>
                <td>{{ panel.watts }} W</td>
                <td>{{ panel.code }}</td>
                <td><button @click="selectPanel(panel)">Sélectionner</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        panelOptions: [
          {
            type: "Jinko Solar Co., Ltd.",
            watts: 310,
            code: "JKM310M-72B",
            image: "path/to/jinko-image.jpg",
            width: 0.992,
            height: 1.956,
          },
          {
            type: "SunPower",
            watts: 335,
            code: "SPR-P3-335-BLK",
            image: "path/to/sunpower-image.jpg",
            width: 0.95,
            height: 1.5,
          },
          {
            type: "SunPower 2",
            watts: 370,
            code: "SPR-P3-370-BLK-E3-AC",
            image: "path/to/sunpower-image.jpg",
            width: 1,
            height: 1.956,
          },
          {
            type: "Panasonic",
            watts: 325,
            code: "VBHN325SA16",
            image: "path/to/panasonic-image.jpg",
            width: 0.95,
            height: 0.95,
          },
        ],
      };
    },
    methods: {
      selectPanel(panel) {
        const obj = {
          type: panel.type,
          watts: panel.watts,
          code: panel.code,
          width: panel.width,
          height: panel.height,
        };
        this.$emit("add-installation", obj);
        this.$emit("close");
      },
    },
  };
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background: white;
    border-radius: 5px;
    position: relative;
    width: 70%;
    padding-bottom: 20px;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 10px;
    line-height: normal;
    cursor: pointer;
  }
  .panel-options {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    border-left: none;
    border-right: none;
  }
  th {
    background-color: #f2f2f2;
    border-top: 1px solid #ccc;
  }
  td .panel-image {
    margin-right: 10px;
    width: 30px;
    height: auto;
    vertical-align: middle;
  }
  button {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  button:hover {
    background-color: #0056b3;
  }
  td:last-child {
    text-align: right;
  }
  </style>
  