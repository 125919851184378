<template>
  <div class="mb-4">
    <div class="d-flex flex-row">
      <div class="card card-body">
        <div>
          <div class="card-title mb-0 pa-0">
            <span style="font-weight: 400">Informations Client Particulier</span>
          </div>
        </div>
        <!-- DATA TABLE FOR VISITE -->
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Civile</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.civile }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Nom</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.nom }}
            </p>
          </div>
        </div>
        <hr />

        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Prénom</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.prenom }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Adresse Email</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.adresseEmail }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Adresse Postal</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 0.9rem"
                  class="text-muted mb-0"
                >
                  {{ dataProps.adressePostal?.substring(0, 25) }}...
                </p>
              </template>
              <span>{{ dataProps.adressePostal }}</span>
            </v-tooltip>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Code postal</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.codePostal }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">commune</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.commune }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">N° Téléphone</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.numTele }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Voie</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">{{ dataProps.voie }}</p>
          </div>
        </div>
        <hr />
      </div>

    </div>

  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "InfoSociete",
  props: ["dataProps", "listSelectProps", "listModelProps"],
  data() {
    return {
      formEditInfos: null,
      societeId: null,
      sameObject: true,
      contactUnique: false,
      modelSelect: "Chef ",
      loaded: false,
      boolEditRenseignement: false,
      modelObject: { nom: "", prenom: "", fonction: "", email: "" },
      listModelEdit: { ...this.listModelProps },
      listModel: { ...this.listModelProps },
    };
  },
  created() {
    this.modelSelect = this.listSelectProps[0];
    if (Object.keys(this.listModel).length == 0) {
      this.listModel = { ...this.listModelProps };
    }
  },
  methods: {
    isEqualObjects(objet1, objet2) {
      const clesObjet1 = Object.keys(objet1);
      const clesObjet2 = Object.keys(objet2);

      if (clesObjet1.length !== clesObjet2.length) {
        return false;
      }

      for (let i = 0; i < clesObjet1.length; i++) {
        const cle = clesObjet1[i];

        if (typeof objet1[cle] === "object" && typeof objet2[cle] === "object") {
          if (!this.isEqualObjects(objet1[cle], objet2[cle])) {
            return false;
          }
        } else {
          if (objet1[cle] !== objet2[cle]) {
            return false;
          }
        }
      }

      return true;
    },
    async updateInfos(item) {
      if (
        this.isEqualObjects(this.listModelEdit[item], this.listModel[item]) == false &&
        this.$refs.formEditInfos.validate()
      ) {
        if (this.contactUnique) {
          this.listSelectProps.forEach(async (items) => {
            await axios({
              url: process.env.VUE_APP_URL_API_CLIENT + `updateInfos${items}/`,
              method: "POST",
              data: {
                societeId: this.dataProps.id,
                data: this.listModelEdit[item],
                token: this.$cookies.get("token"),
              },
            }).then((res) => {
              this.$set(this.listModel, items, { ...this.listModelEdit[item] });
              this.$set(this.listModelEdit, items, { ...this.listModelEdit[item] });
            });
          });
        } else {
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + `updateInfos${item}/`,
            method: "POST",
            data: {
              societeId: this.dataProps.id,
              data: this.listModelEdit[item],
              token: this.$cookies.get("token"),
            },
          }).then((res) => {
            this.$set(this.listModel, item, { ...this.listModelEdit[item] });
            this.$emit("newListModel", this.listModel);
          });
        }
        this.boolEditRenseignement = false;
        this.contactUnique = false;
        this.sameObject = true;
      }
    },
    editRenseignementClient(keySelect) {
      this.boolEditRenseignement = !this.boolEditRenseignement;
      this.listModelEdit[keySelect] = { ...this.listModel[keySelect] };
      console.log(this.boolEditRenseignement);
      if (this.boolEditRenseignement == false) {
        this.sameObject = true;
      }
    },
  },
  watch: {
    listModelProps: {
      handler(newValue, oldValue) {
        this.listModel = { ...newValue };
      },
      deep: true,
    },
    listModelEdit: {
      handler(newValue) {
        this.sameObject = this.isEqualObjects(newValue, this.listModel);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  background-color: #ffffff !important;
}

.containe_comp {
  background-color: #f2f2f2 !important;
  border-radius: 10px;
  padding: 50px 70px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
}

.body-header {
  color: #192946;
}

.icon {
  width: 58px;
  height: 58px;
  top: 0;
  bottom: 0;
  right: 16px;
  background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
    rgb(0, 121, 145);
  border-radius: 7px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.12);
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
}

.text-button {
  color: #192946;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
}

.text-button:hover {
  color: #1e3766;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
  text-decoration: underline;
}

.bgc-primary {
  background-color: #396c99;
}

.bgc-primary:hover {
  background-color: #1e4c66;
}

.bgc-success {
  background-color: #2fb8ac;
}

.bgc-warning {
  background-color: #868685;
}

.card-title {
  padding: 0.45rem 0.825rem;
  margin-bottom: 10;
  background-color: #ffffff;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
  font-size: 1.25rem;
  color: #1d3258;
}

.card-header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e6e6f2 !important;
}

#table-validation-cotation th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}

.v-application ul {
  padding-left: 10px;
}

.info-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}

.small-status {
  width: max-content;
}
::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 0px;
}
::v-deep [data-v-07234524] .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label[data-v-07234524] {
  color: white;
}
::v-deep .v-input__icon.v-input__icon--append > i {
  color: white;
}
::v-deep .v-input--selection-controls__input .v-icon {
  color: white;
}
::v-deep .VSelect.v-text-field--outlined fieldset {
  border: 0px solid white;
}
::v-deep .formTextField.v-text-field--outlined fieldset {
  border-color: #007991;
}
::v-deep .v-input__icon.v-input__icon--append > i::before {
  color: white !important;
}
</style>
