<template>
  <div style="height: 100%; width: 100%; padding: 15px">
    <div v-show="recherche">
      <div style="width: 50%" class="d-flex flex-row">
        <v-text-field
          outlined
          dense
          label="Adresse"
          :rules="[$rules.required('Adresse')]"
          v-model="recherche"
          :error="searched == false"
            :error-messages="searched == false ? ['Vérifiez l\'adresse saisie'] : []"
        >
          <template v-slot:append>
            <v-icon :color="$colors[0]">mdi-map-marker-radius-outline</v-icon>
          </template>
        </v-text-field>
        <v-btn
          class="ml-4"
          outlined
          @click="findPlace"
          style="border: solid 2px #8aa7b4"
        >
          <v-icon :color="$colors[0]">mdi-map-marker-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- container (toolbar + map) -->
    <div v-show="recherche">
      <div v-show="searched" class="map-container">
        <!-- toolbar -->
        <div class="toolbar hide-scrollbar">
          <div class="project-list">
            <template v-for="installation in installations">
              <InstallationItem
                @update-area="updateArea"
                :map="map"
                :installation="installation"
                :selectedInstallation="selectedInstallation"
                :longitude="longitude_area"
                :latitude="latitude_area"
                @toggleExpand="updateSelectedInstallation"
                @addPanneau="addPanneau(installation)"
                @update-orientation="updateOrientation(installation, $event)"
                @update-lossPercentage="
                  updateLossPercentage(installation, $event)
                "
                @update-peakPower="updatePeakPower(installation, $event)"
                @togglePanneu="togglePannaux"
                @delete-installation="deleteInstallation"
                @polygon-complete="handlePolygonComplete"
              />
            </template>
            <div
              class="add-installation-btn"
              @click="showInstallationModal = true"
            >
              <span class="mdi mdi-plus"></span>
              <span>Add Installation</span>
            </div>
            <!-- <div
              class="prod-installation-btn"
              v-if="showProdButton"
              @click="sendInstallation()"
            >
              <span class="mdi mdi-plus"></span>
              <span>Calculer Production</span>
            </div> -->
            <InstallationModal
              v-if="showInstallationModal"
              @close="showInstallationModal = false"
              @add-installation="addInstallation"
            />
          </div>
        </div>

        <!-- map -->
        <div style="height: 100%">
          <div ref="map" class="map-wrapper">
            <div id="map"></div>
          </div>
          <div>
            <div
              ref="compass"
              class="compass"
              :style="{ transform: 'rotate(' + -compassHeading + 'deg)' }"
            >
              <div class="compass-img">
                <img src="/compass.png" height="50" width="50" />
              </div>
              <p style="margin-left: 7px">{{ compassDegree }}</p>
            </div>
          </div>
          <div>
            <div class="screenshot" @click="takeScreenshot">
              <span class="mdi mdi-camera"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import html2canvas from "html2canvas";
import InstallationItem from "./InstallationItem.vue";
import InstallationModal from "./InstallationModal.vue";
import GridOverlay from "./GridOverlay.vue"; // Import the GridOverlay component
import Vue from "vue";
import axios from "axios";

export default {
  data() {
    return {
      google: null,
      latitude_area: 0,
      longitude_area: 0,
      recherche: this.adresse, // Initialize recherche with adresse
      searched: null,
      showedinst: false,
      polygons: [],
      production: [],
      map: null,
      area: 0,
      compassHeading: 0,
      compassDegree: "0°",
      installations: [],
      polygons: {},
      showProdButton: false,
      selectedInstallation: null,
      installationId: 0,
      showInstallationModal: false,
      dataMarkers: {
        latitude: 40.76,
        longitude: -73.983,
        solarPotential: 0.8,
      },
    };
  },
  async mounted() {
    await this.initMap();
    this.$nextTick(() => {
      this.addCompassControl();
    });
  },
  components: {
    InstallationItem,
    InstallationModal,
    GridOverlay,
  },
  props: {
    adresse: {
      type: String,
      required: true,
    },
   
  },
  watch: {
    adresse(newVal) {
      this.recherche = newVal;
    },
    installations:{
      handler(newVal){
        // check the items in the installations if that has any polygon showProdButton = true
        console.log("newVal ------*****-----", newVal);
       if(newVal.length > 0){
        newVal.forEach((installation) => {
          if (installation.polygons) {
            this.showProdButton = true;
          }
        });
       }

      },
      deep: true,
    },
    production: {
      handler(newVal) {
        console.log("newVal", newVal);
        // Update the installations with the new production data
        this.installations = this.installations.map((installation) => {
          // Create a shallow copy of the installation object
          let newInstallation = { ...installation };

          // Find the corresponding data in newVal
          const correspondingData = newVal.find(
            (item) => item.id === newInstallation.id
          );

          if (correspondingData) {
            // Add the new production attribute and update its value
            newInstallation.production = correspondingData.year_production;
          }

          return newInstallation;
        });
        console.log("installations", this.installations);
      },
      deep: true,
      immediate: true,
    },
   

    selectedInstallation(newVal, oldVal) {
      // Hide polygons of the previously selected installation
      if (oldVal !== null) {
        this.hidePolygons(oldVal);
      }

      // Show polygons of the newly selected installation
      if (newVal !== null) {
        this.showPolygons(newVal);
      }

      // Update grid instances
      const gridInstances = document.querySelectorAll(".outer-container");
      gridInstances.forEach((gridInstance) => {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.$props.selectedInstallation = this.selectedInstallation;
          vueInstance.updateGrid(); // Ensure the grid updates its layout
        }
      });
    },
    deep: true,
  },
  created() {
    this.recherche = this.adresse; // Ensure recherche is initialized with adresse in the created hook
  },

  _methods: {
    async initMap() {
      const loader = new Loader({
        apiKey: "AIzaSyC8jB8jLLPW0nCDqE8XrDAPYHg7YjXN2qI", // Replace with your actual API key
        libraries: ["places", "drawing"], // Include any additional libraries you need
      });
      await loader.load();
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 43.567296, lng: 3.896473 },
        mapTypeId: "satellite",
        zoom: 21,
        minZoom: 1,
        maxZoom: 21,

        heading: 90,
        tilt: 0,
        rotateControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
      });

      this.map.addListener("heading_changed", () => {
        this.updateCompass();
      });
    },
    async sendInstallation() {
      const selectedInstallationf = this.installations.find(installation => installation.id === this.selectedInstallation)
      console.log("selectedInstallation", selectedInstallationf);
      
      await axios({
       
        url:  process.env.VUE_APP_URL_API_CLIENT + `getInstallationAndSendItToSolarApi/`,
        method: "POST",
        data: {
          installation: [selectedInstallationf],
          token: this.$store.getters.getUserToken,
        },
      })
        .then((response) => {
          console.log("response received ")
          this.production = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    
    },
    updateArea(obj) {
      console.log("obj", obj);
      // add or update the polygons for the installation
      this.installations = this.installations.map((installation) => {
        if (installation.id === obj.installationId) {
          // replace the polygons array with the new polygon object
          installation.polygons = [obj];
          // add or update the polygons for the installation
          if (installation.polygons) {
            // find the polygon with the same latitude and longitude
            const polygonIndex = installation.polygons.findIndex(
              (polygon) =>
                polygon.latitude === obj.latitude &&
                polygon.longitude === obj.longitude
            );
            if (polygonIndex !== -1) {
              // if the polygon exists, update it
              installation.polygons[polygonIndex] = obj;
            } else {
              // if the polygon does not exist, add it
              installation.polygons.push(obj);
            }
          } else {
            installation.polygons = [obj];
          }
        }
        return installation;
      });
      console.log("installationsss, polygons", this.installations);
    },
    addCompassControl() {
      if (this.$refs.compass) {
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
          this.$refs.compass
        );
        this.map.addListener("heading_changed", this.updateCompass);
        this.updateCompass();
      } else {
        console.error("Compass ref is not available");
      }
    },
    updateCompass() {
      const heading = this.map.getHeading() || 0;
      this.compassHeading = heading;
      this.compassDegree = `${Math.round(heading)}°`;
    },
    updateSelectedInstallation(installation) {
      this.selectedInstallation = installation;
    },
    updateOrientation(installation, newAngle) {
      installation.orientation = Number(newAngle);
      const gridInstance = document.querySelector(
        `.outer-container[data-id='${installation.id}']`
      );
      if (gridInstance) {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.$props.orientation = Number(newAngle);
          vueInstance.updateGrid(); // Ensure the grid updates its layout
        }
      }
    },
    updateLossPercentage(installation, newLossPercentage) {
      installation.lossPercentage = Number(newLossPercentage);
    },
    updatePeakPower(installation, newPeakPower) {
      installation.peakPower = Number(newPeakPower);
    },
    addInstallation(panel) {
      let obj = {
        id: this.installationId++,
        name: `Installation ${this.installationId}`,
        nbrPanels: 0,
        orientation: 0,
        lossPercentage: 0,
        peakPower: 0,
        production: 0,
        wattsPerPanel: panel.watts,
        ...panel,
      };
      console.log(obj);
      this.selectedInstallation = obj.id;
      this.installations.push(obj);
      this.showInstallationModal = false;
      this.$emit("addInstallation", obj);
    },
    addPanneau(installation) {
      if (!installation) {
        alert("no installation panel selected");
        return;
      }
      if (installation.nbrPanels > 0) {
        alert("installation already added");
        return;
      }
      // increase number of Panels
      installation.nbrPanels++;

      const currentMapLocation = this.map.getCenter();
      const lat = currentMapLocation.lat();
      const lng = currentMapLocation.lng();

      const widthInMeters = installation.width;
      const heightInMeters = installation.height;

      const latSpan = heightInMeters / 111000;
      const lngSpan =
        widthInMeters / (111000 * Math.cos((lat * Math.PI) / 180));

      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(lat - latSpan / 2, lng - lngSpan / 2),
        new google.maps.LatLng(lat + latSpan / 2, lng + lngSpan / 2)
      );

      // Create the custom overlay at the current position
      this.createCustomOverlay(bounds, installation);
    },
    createCustomOverlay(bounds, installation) {
      let div = null;

      const overlay = new google.maps.OverlayView();

      overlay.onAdd = () => {
        div = document.createElement("div");
        div.style.borderStyle = "none";
        div.style.borderWidth = "0px";
        div.style.position = "absolute";

        // Create Vue instance for the grid
        const GridOverlayComponent = Vue.extend(GridOverlay);
        const instance = new GridOverlayComponent({
          propsData: {
            startX: 0,
            startY: 0,
            orientation: installation.orientation,
            width: bounds.toSpan().lng() * 111000 * 10, // Calculate width in meters
            height: bounds.toSpan().lat() * 111000 * 10, // Calculate height in meters
            onDrag: handleDrag,
            installationId: this.selectedInstallation,
            selectedInstallation: this.selectedInstallation,
          },
        }).$mount();

        instance.$on("orientationChange", (data) => {
          installation.orientation = data.orientation;
        });

        instance.$on("selectedPannelsChange", (data) => {
          installation.nbrPanels = data.nbrPanels;
        });

        div.appendChild(instance.$el);

        const panes = overlay.getPanes();
        panes.overlayMouseTarget.appendChild(div);
      };

      overlay.draw = () => {
        const overlayProjection = overlay.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(
          bounds.getSouthWest()
        );
        const ne = overlayProjection.fromLatLngToDivPixel(
          bounds.getNorthEast()
        );

        if (div) {
          div.style.left = sw.x + "px";
          div.style.top = ne.y + "px";
          const width = ne.x - sw.x;
          const height = sw.y - ne.y;

          div.style.width = width * 10 + "px";
          div.style.height = height * 10 + "px";

          // Safely update the instance props to force re-render
          const gridInstance = div.querySelector(".outer-container");
          if (gridInstance) {
            const vueInstance = gridInstance.__vue__;
            if (vueInstance) {
              vueInstance.$props.width = width * 10;
              vueInstance.$props.height = height * 10;
              vueInstance.updateGrid(); // Ensure the grid updates its layout
            }
          }
        }
      };

      const handleDrag = (dx, dy) => {
        const overlayProjection = overlay.getProjection();
        const startBounds = bounds;

        const sw = overlayProjection.fromLatLngToDivPixel(
          startBounds.getSouthWest()
        );
        const ne = overlayProjection.fromLatLngToDivPixel(
          startBounds.getNorthEast()
        );

        const newCenterX = (sw.x + ne.x) / 2 + dx;
        const newCenterY = (sw.y + ne.y) / 2 + dy;

        const newCenterLatLng = overlayProjection.fromDivPixelToLatLng(
          new google.maps.Point(newCenterX, newCenterY)
        );

        const latHeight =
          startBounds.getNorthEast().lat() - startBounds.getSouthWest().lat();
        const lngWidth =
          startBounds.getNorthEast().lng() - startBounds.getSouthWest().lng();
        const newSW = new google.maps.LatLng(
          newCenterLatLng.lat() - latHeight / 2,
          newCenterLatLng.lng() - lngWidth / 2
        );
        const newNE = new google.maps.LatLng(
          newCenterLatLng.lat() + latHeight / 2,
          newCenterLatLng.lng() + lngWidth / 2
        );

        bounds = new google.maps.LatLngBounds(newSW, newNE);
        overlay.draw();
      };

      overlay.onRemove = function () {
        if (div) {
          div.parentNode.removeChild(div);
          div = null;
        }
      };

      overlay.setMap(this.map);
    },
    findPlace() {
      const request = {
        query: this.recherche,
        fields: ["name", "geometry"],
      };
      var service = new window.google.maps.places.PlacesService(this.map);
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.map.setCenter(results[0].geometry.location);
          this.map.setZoom(25);
          this.searched = true;
          this.$emit("placeFound", true);
        } else {
          this.searched = false;

          this.$emit("placeFound", false);
        }
      });
    },

    createCompassOverlay() {
      let compassOverlay = new google.maps.OverlayView();

      compassOverlay.onAdd = function () {
        let layer = this.getPanes().overlayLayer;
        layer.appendChild(this.$refs.compass);
      };

      compassOverlay.draw = function () {
        // Positioning if needed
      };

      compassOverlay.onRemove = function () {
        this.$refs.compass.parentNode.removeChild(this.$refs.compass);
      };

      compassOverlay.setMap(this.map);
    },
    togglePannaux(inst) {
      console.log("installationId, about panelId", inst);
      this.showedinst = inst.showed;
      console.log("showedinst", this.showedinst);
    },
    async takeScreenshot() {
      const gridInstance = document.querySelector(
        `.outer-container[data-id="${this.selectedInstallation}"]`
      );
      if (gridInstance) {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.isHidden = true;
          vueInstance.isActive = false;
          await vueInstance.updateGrid(); // Ensure updateGrid is completed
        }
      }

      const mapContainer = this.$refs.map;
      if (mapContainer) {
        try {
          const canvas = await html2canvas(mapContainer, {
            useCORS: true,
            logging: true,
            scale: 1,
          });
          const image = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = image;
          link.download = "map-screenshot.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error("Error taking screenshot:", err);
        } finally {
          if (gridInstance) {
            const vueInstance = gridInstance.__vue__;
            if (vueInstance) {
              vueInstance.isHidden = false;
              vueInstance.isActive = true;
              await vueInstance.updateGrid(); // Ensure updateGrid is completed
            }
          }
        }
      } else {
        console.error("Map container is not found");
      }
    },
    handlePolygonComplete({ installationId, polygon }) {
      if (!this.polygons[installationId]) {
        this.polygons[installationId] = [];
      }
      this.polygons[installationId].push(polygon);
    },

    deleteInstallation(installationId) {
      console.log("installationId delete", installationId);
      // Remove the installation from the list
      this.installations = this.installations.filter(
        (inst) => inst.id !== installationId
      );

      // Remove associated polygons from the map and from the polygons object
      if (this.polygons[installationId]) {
        this.polygons[installationId].forEach((polygon) => {
          polygon.setMap(null);
        });
        delete this.polygons[installationId];
        this.$emit("deleteInstallation", installationId);
      }

      // Ensure selected installation is updated correctly
      if (this.selectedInstallation === installationId) {
        this.selectedInstallation = null;
      }
    },

    showPolygons(installationId) {
      if (this.polygons[installationId]) {
        this.polygons[installationId].forEach((polygon) => {
          polygon.setMap(this.map);
        });
      }
    },

    hidePolygons(installationId) {
      if (this.polygons[installationId]) {
        this.polygons[installationId].forEach((polygon) => {
          polygon.setMap(null);
        });
      }
    },
  },
  get methods() {
    return this._methods;
  },
  set methods(value) {
    this._methods = value;
  },
};
</script>

<style scoped>
#map {
  height: 100%;
  width: 100%;
}

.map-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.compass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  top: 10px !important;
  right: 15px !important;
}

.compass-img {
  background-color: rgba(255, 255, 255, 0.904);
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screenshot {
  position: absolute;
  top: 100px;
  right: 20px;
  cursor: pointer;
  font-size: 40px;
  color: #fff;
  text-shadow: 0px 4px 8px #262626;
}
</style>

<!-- search style -->
<style scoped>
.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.search-input-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  max-width: 600px;
}
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 8px;
}

.icon-prefix {
  position: absolute;
  left: 10px;
  pointer-events: none;
  color: #ddd;
}

.search-input {
  padding: 10px;
  padding-left: 40px;
  width: 100%;
  outline: none;
  border-radius: 8px;
}
.search-btn {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background: #03bb90;
  padding: 0px 16px;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.map-container {
  margin-top: 12px;
  height: 600px;
  position: relative;
}
</style>
<!-- toolbar -->
<style scoped>
.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 600px;
  overflow-y: auto;
  width: 395px;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 100;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
.project-list {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  gap: 20px;
  padding: 10px;
}
.add-installation-btn {
  font-size: 14px;
  border: 2px solid #bbb;
  color: #fff;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  background-color: #607d8b;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(60, 64, 67, 0.3);
}
.prod-installation-btn {
  font-size: 14px;
  border: 2px solid #bbb;
  color: #fff;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  background-color: #f5a43b;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(60, 64, 67, 0.3);
}
</style>
