<template>
  <section>
    <loadingComponent
      :dialogProps="loading"
      :messageDialogeProps="''"
      :loadingProps="loading"
      :errorProps="false"
    />
    <!-- modal add borne -->
    <v-dialog v-model="modalAddLOT" scrollable width="70%">
      <v-card>
        <v-card-title>Ajouter Un LOT</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="addLOTForm" class="p-2">
            <v-text-field
              v-model="newLOT.title"
              label="LOT Title"
              outlined
              dense
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="newLOT.total"
              label="LOT Total"
              outlined
              dense
              suffix="€"
              disabled
              :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
            />
            <v-select
              v-model="haveSubLOT"
              :items="['Oui', 'Non']"
              label="ya t'il des subLOT"
              outlined
              dense
              :rules="[rules.required]"
            />
            <div
              class="mb-4"
              style="
                padding: 15px 10px;
                border-radius: 4px;
                background-color: #b0ebe6;
              "
              v-if="newLOT.items?.length > 0"
              v-for="(subLOTObject, indexSubLOTObject) in newLOT.items"
            >
            <template v-if="haveSubLOT == 'Oui'">
              <div
                class="d-flex flex-row align-items-start justify-content-between"
              >
                <div>
                  <p class="font-weight-bold">
                    # sub LOT N° {{ indexSubLOTObject + 1 }}
                  </p>
                </div>
                <div>
                  <v-icon
                    @click="(e) => removeSubLOT(e, indexSubLOTObject)"
                    style="color: #ff756b"
                    >mdi-close-circle</v-icon
                  >
                </div>
              </div>
              <v-text-field
                v-model="subLOTObject.subtitle"
                label="sub-LOT Title"
                outlined
                dense
                :rules="haveSubLOT == 'Oui' ? [rules.required] : []"
              />
              <v-text-field
                v-model="subLOTObject.total"
                label="sub-LOT Total"
                outlined
                dense
                suffix="€"
                disabled
                :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
              />
            </template>
              <div class="d-flex flex-row flex-wrap mb-4" style="gap: 20px">
                <div
                  style="
                    max-width: 350px;
                    padding: 15px 10px;
                    border-radius: 4px;
                    background-color: #fff;
                  "
                  v-if="subLOTObject.items?.length > 0"
                  v-for="(materialObject, indexMaterial) in subLOTObject.items"
                >
                  <div
                    class="d-flex flex-row align-items-start justify-content-between"
                  >
                    <div>
                      <p class="font-weight-bold">
                        # Material N° {{ indexMaterial + 1 }}
                      </p>
                    </div>
                    <div>
                      <v-icon
                        @click="
                          (e) =>
                            removeMaterial(e, indexSubLOTObject, indexMaterial)
                        "
                        style="color: #ff756b"
                        >mdi-close-circle</v-icon
                      >
                    </div>
                  </div>
                  <v-text-field
                    v-model="materialObject.description"
                    label="description"
                    outlined
                    dense
                    :rules="[rules.required]"
                  />
                  <v-select
                    v-model="materialObject.unite"
                    :items="['U', 'ml']"
                    label="unite"
                    outlined
                    dense
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    type="number"
                    v-model="materialObject.quantite"
                    @input="updateTotalHT(indexSubLOTObject, indexMaterial)"
                    label="Quantite"
                    outlined
                    dense
                    :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
                  />
                  <v-text-field
                    v-model="materialObject.PUHT"
                    label="PUHT"
                    outlined
                    @input="updateTotalHT(indexSubLOTObject, indexMaterial)"
                    dense
                    suffix="€"
                    :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
                  />
                  <v-text-field
                    v-model="materialObject.totalHT"
                    label="Total HT"
                    outlined
                    dense
                    suffix="€"
                    :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
                    disabled
                  />
                </div>
              </div>

              <v-btn
                @click="(e) => ajouterSubLOTMaterial(e, indexSubLOTObject)"
                elevation="0"
                style="
                  color: #fff;
                  background-color: #198b81;
                  border-color: #198b81;
                "
              >
                Ajouter SubLOT Material
              </v-btn>
            </div>
            <template v-if="haveSubLOT == 'Oui'">
              <v-btn
                @click="ajouterSubLOT"
                elevation="0"
                style="
                  color: #fff;
                  background-color: #14324f;
                  border-color: #14324f;
                "
              >
                Ajouter SubLOT
              </v-btn>
            </template>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text style="color: #198b81" @click="closeModalAddLOT">
            Annuler
          </v-btn>
          <v-btn
            elevation="0"
            style="
              color: #fff;
              background-color: #198b81;
              border-color: #198b81;
            "
            @click="addNewLOT"
          >
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- content -->
    <div class="ma-8">
      <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Devis</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Details Devis
          </li>
        </ol>
      </nav>

      <div class="row">
        <div class="col-lg-4 side-info">
          <div class="card-block">
            <!--Detail societe-->
            <h6 class="m-b-10 pb-2 b-b-default f-w-600">Information Societe</h6>
            <div class="d-flex flex-wrap">
              <!-- Raison Sociale -->
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-identifier</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Raison Sociale</p>
                  <h6 class="text-muted font-weight-light">
                    {{ societe?.raison }}
                  </h6>
                </div>
              </div>

              <!-- Siret -->
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-domain</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Siret</p>
                  <h6 class="text-muted font-weight-light">
                    {{ societe?.siret }}
                  </h6>
                </div>
              </div>
            </div>

            <!-- Email and Phone -->
            <div class="d-flex flex-wrap">
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-email-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Email</p>
                  <h6 class="text-muted font-weight-light">
                    {{ societe?.adresseEmail }}
                  </h6>
                </div>
              </div>
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-phone-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Téléphone</p>
                  <h6 class="text-muted font-weight-light">
                    {{ societe?.numTele }}
                  </h6>
                </div>
              </div>
            </div>

            <!-- Nom et Prenom -->
            <div class="d-flex flex-wrap">
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-card-account-details-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Nom</p>
                  <h6 class="text-muted font-weight-light">
                    {{ societe?.nom }}
                  </h6>
                </div>
              </div>
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-card-account-details-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Prénom</p>
                  <h6 class="text-muted font-weight-light">
                    {{ societe?.prenom }}
                  </h6>
                </div>
              </div>
            </div>

            <!-- adresse postal -->
            <div class="d-flex flex-wrap">
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-map-marker-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Adresse Postale</p>
                  <h6 class="text-muted font-weight-light">
                    {{ societe?.adressePostal }}
                  </h6>
                </div>
              </div>
            </div>

            <h6 class="m-b-0 m-t-40 pb-3 b-b-default f-w-600">
              Informations Compteur
            </h6>
            <!-- Compteur et Voie-->
            <div class="d-flex flex-wrap">
              <!-- Compteur -->
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-lightning-bolt-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Compteur</p>
                  <h6 class="text-muted font-weight-light">
                    {{ compteur?.numCompteur }}
                  </h6>
                </div>
              </div>

              <!-- Voie -->
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-meter-electric-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Voie</p>
                  <h6 class="text-muted font-weight-light">
                    {{ compteur?.adresse }}
                  </h6>
                </div>
              </div>
            </div>
            <!-- Option Tarifaire et Segment   -->
            <div class="d-flex flex-wrap">
              <!-- Option Tarifaire -->
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-credit-card-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Option Tarifaire</p>
                  <h6 class="text-muted font-weight-light">
                    {{ compteur?.optionTarifaire }}
                  </h6>
                </div>
              </div>

              <!-- Segment -->
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-tune-vertical-variant</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Segment</p>
                  <h6 class="text-muted font-weight-light">
                    {{ compteur?.parametreCompteur }}
                  </h6>
                </div>
              </div>
            </div>
            <!-- Code Postal et type   -->
            <div class="d-flex flex-wrap">
              <!-- Code Postal -->
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-help-circle-outline</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Code Postal</p>
                  <h6 class="text-muted font-weight-light">
                    {{ compteur?.codePostal }}
                  </h6>
                </div>
              </div>

              <!-- Type -->
              <div
                class="d-flex flex-row p-2"
                style="flex: 1 1 200px !important"
              >
                <v-icon
                  style="background-color: #f5f5f5; width: 48px; height: 48px"
                  :color="$colors[0]"
                  class="pa-3 mr-4 rounded"
                  >mdi-alpha-i</v-icon
                >
                <div>
                  <p class="font-weight-bold mb-2">Type</p>
                  <h6 class="text-muted font-weight-light">
                    {{ compteur?.type }}
                  </h6>
                </div>
              </div>
            </div>

            <ul class="social-link list-unstyled m-t-40 m-b-10">
              <li>
                <a
                  href="#!"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="facebook"
                  ><i
                    class="feather icon-facebook facebook"
                    aria-hidden="true"
                  ></i
                ></a>
              </li>
              <li>
                <a
                  href="#!"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="twitter"
                  ><i
                    class="feather icon-twitter twitter"
                    aria-hidden="true"
                  ></i
                ></a>
              </li>
              <li>
                <a
                  href="#!"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="instagram"
                  ><i
                    class="feather icon-instagram instagram"
                    aria-hidden="true"
                  ></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <!-- CARD BODY IF THE INFO BUTTON CLICKED TO DISPLAY SOCIETE INFO -->
          <div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div
                    class="card-title d-flex flex-row justify-center align-center"
                    style="width: 100%"
                  >
                    <h5 class="Poppins-Bold mr-1 mb-0">
                      {{ devisDetail?.dateAjout }} -
                    </h5>
                    <div class="text-95 align-start d-sm-flex justify-center">
                      <div class="d-flex flex-column align-center btn-group">
                        <div
                          class="d-flex flex-row justify-center align-center"
                        >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'refusee'"
                            ><v-icon dense style="color: #ff0000"
                              >mdi-close-circle</v-icon
                            ><b class="ml-1 Poppins-Bold">refusée</b></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'annuler'"
                            ><v-icon dense style="color: #ff0000"
                              >mdi-file-cancel-outline</v-icon
                            ><b class="ml-1 Poppins-Bold">Annulé</b></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'en_attend'"
                            ><v-icon dense style="color: #2080ee"
                              >mdi-clock-outline</v-icon
                            ><b
                              class="ml-1 Poppins-Bold"
                              style="word-break: keep-all"
                              >en attente</b
                            ></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'en_cours'"
                            ><v-icon dense style="color: orange"
                              >mdi-progress-clock</v-icon
                            ><b class="ml-1 Poppins-Bold">en cours</b></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'terminer'"
                            ><v-icon dense style="color: #00e676"
                              >mdi-check</v-icon
                            ><b class="ml-1 Poppins-Bold">Terminer</b></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'pre_valider'"
                            ><v-icon dense style="color: #9c27b0"
                              >mdi-eye-check</v-icon
                            ><b class="ml-1 Poppins-Bold">pré validé</b></small
                          >
                          <div
                            class="d-flex flex-row justify-center align-center"
                            v-if="devisDetail.status == 'valider'"
                          >
                            <v-icon style="color: #00e676"
                              >mdi-check-circle-outline</v-icon
                            ><span class="ml-1 Poppins-Bold">Validé</span>
                          </div>
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'signer'"
                            ><v-icon dense style="color: rgb(134, 29, 90)"
                              >mdi-file-sign</v-icon
                            ><b
                              class="ml-1 Poppins-Bold"
                              style="word-wrap: normal"
                              >Signé</b
                            ></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'envoyer_signiature'"
                            ><v-icon dense style="color: rgb(114, 85, 7)"
                              >mdi mdi-send-clock</v-icon
                            ><b
                              class="ml-1 Poppins-Bold"
                              style="word-wrap: normal"
                              >envoyer signiature</b
                            ></small
                          >
                          <small
                            class="small-status Poppins-Bold"
                            v-if="devisDetail.status == 'renitialiser'"
                            ><v-icon dense style="color: rgb(121, 166, 196)"
                              >mdi mdi-lock-reset</v-icon
                            ><b class="ml-1" style="word-wrap: normal"
                              >renitialiser</b
                            ></small
                          >

                          <div
                            class="d-flex flex-row"
                            v-if="showActionsBtns(devisDetail.status)"
                          >
                            <v-icon
                              class="dropdown-toggle"
                              data-toggle="collapse"
                              :data-target="'#collapseStatut'"
                              aria-expanded="false"
                              :aria-controls="'collapseStatut'"
                              size="20"
                              left
                            >
                              mdi-menu-down
                            </v-icon>
                          </div>
                        </div>
                        <div
                          class="collapse"
                          :id="'collapseStatut'"
                          style="border: 0px"
                          v-if="showActionsBtns(devisDetail.status)"
                        >
                          <div class="d-flex flex-row mt-4 mt-lg-2">
                            <!-- Refuser -->
                            <v-btn
                              title="Refuser Devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'refusee',
                                  `Vous êtes sur le point de refuser ce devis!`
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'refusee'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="red"> mdi-close-circle </v-icon>
                            </v-btn>
                            <!-- annulé -->
                            <v-btn
                              title="Annuler Devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'annuler',
                                  `Vous êtes sur le point d'annuler ce devis!`
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'annuler'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="red"> mdi-cancel </v-icon>
                            </v-btn>
                            <!-- renitialiser-->
                            <v-btn
                              title="renitialiser"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'en_attend',
                                  `Vous êtes sur le point de renitialiser ce devis!`
                                )
                              "
                              class="dropdown-project"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'renitialiser'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="grey accent-5">
                                mdi mdi-lock-reset
                              </v-icon>
                            </v-btn>
                            <!-- signé button -->
                            <v-btn
                              title="signer un devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'signer',
                                  `Vous êtes sur le point de signer ce devis!`
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'signer'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="rgb(134, 29, 90)">
                                mdi mdi-draw
                              </v-icon>
                            </v-btn>

                            <!-- envoyer signiature button -->
                            <v-btn
                              title="envoyer signiature"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'envoyer_signiature',
                                  `Vous êtes sur le point de envoyer un signiature de ce devis!`
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'envoyer_signiature'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="grey accent-5">
                                mdi mdi-send-clock
                              </v-icon>
                            </v-btn>
                            <!-- Pré Valider -->
                            <v-btn
                              title="Pré Valider Devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'pre_valider',
                                  `Vous êtes sur le point de pré valider ce devis!`
                                )
                              "
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'pre_validation'
                                )
                              "
                              class="dropdown-devisDetail"
                              icon
                              small
                              plain
                            >
                              <v-icon color="#9C27B0"> mdi-eye-check </v-icon>
                            </v-btn>
                            <!-- Valider -->
                            <v-btn
                              title="Valider Devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'valider',
                                  `Vous êtes sur le point de valider ce devis!`
                                )
                              "
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'valider'
                                )
                              "
                              class="dropdown-devisDetail"
                              icon
                              small
                              plain
                            >
                              <v-icon color="green accent-3">
                                mdi-check-circle
                              </v-icon>
                            </v-btn>
                            <!-- Creer Viste Technique -->
                            <v-btn
                              title="Creer Viste Technique"
                              @click="CreerUneVisteTechnique(devisDetail.id)"
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'create_VT'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="blue accent-5">
                                mdi mdi-toolbox
                              </v-icon>
                            </v-btn>
                            <!-- Creer Viste Installation -->
                            <v-btn
                              title="Creer Viste Installation"
                              @click="
                                CreerUneVisteInstallation(
                                  devisDetail.id
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'create_VI'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="green accent-5">
                                mdi-hammer-screwdriver
                              </v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- DATA TABLE FOR VISITE -->
                <div class="">
                  <div class="px-0">
                    <div class="row">
                      <div class="col-12">
                        <div>
                          <span class="text-sm Poppins-Bold align-middle mx-1"
                            >Devis Number :</span
                          >
                          <span class="text-400 text-grey-m2 align-middle">{{
                            devisCalcul?.autreData?.devisNumber
                          }}</span>
                        </div>
                        <div>
                          <span class="text-sm Poppins-Bold align-middle mx-1"
                            >Prime Advenir :</span
                          >
                          <span class="text-400 text-grey-m2 align-middle">{{
                            devisCalcul?.autreData?.PrimeAdvenir
                          }}</span>
                        </div>
                        <div>
                          <span class="text-sm Poppins-Bold align-middle mx-1"
                            >Nombre de borne :</span
                          >
                          <span class="text-400 text-grey-m2 align-middle">{{
                            devisCalcul?.autreData?.nombreDeBorn
                          }}</span>
                        </div>
                        <div>
                          <span class="text-sm Poppins-Bold align-middle mx-1"
                            >Nombre Point De Recharge :</span
                          >
                          <span class="text-400 text-grey-m2 align-middle">{{
                            devisCalcul?.autreData?.nombreDePointDeRecharge
                          }}</span>
                        </div>
                        <div>
                          <span class="text-sm Poppins-Bold align-middle mx-1"
                            >Puissance De Borne :</span
                          >
                          <span class="text-400 text-grey-m2 align-middle">{{
                            devisCalcul?.autreData?.puissanceDeBorn
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-0">
                      <div class="col-12 col-lg-12">
                        <v-btn
                          elevation="0"
                          style="background-color: #45b780; color: #fff"
                          @click="openModalAddLOT"
                        >
                          Ajouter un LOT
                        </v-btn>
                      </div>
                      <div class="col-12 col-lg-12">
                        <DevisPresTable
                          :devisCalcul="devisCalcul"
                          :auxData="auxData"
                          @ajoutMaterial="addNewMaterial"
                          @updateTotal="updateLotTotal"
                          @deleteLotObject="deleteLotObject"
                          @deleteLOTMaterial="deleteLOTMaterial"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "@/plugins/axios";
import DevisPresTable from "@/views/devis/components/DevisPresTable.vue";
import loadingComponent from "../../components/Loading.vue";

export default {
  components: { DevisPresTable,loadingComponent },
  name: "profilAPD",
  data() {
    return {
      loading: false,
      loadingError: false,
      dialogLoading: false,
      loadingMessage: false,

      modalAddLOT: false,
      haveSubLOT: 'Non',
      newLOT: {
        title: "",
        total: 0,
        items: [
          {
            subtitle: "",
            total: 0,
            items: [
              {
                description: "",
                unite: "U",
                quantite: 1,
                PUHT: 0,
                aux: true,
                new: false,
                totalHT: 0,
              },
            ],
          },
        ],
      },

      devisDetail: {},
      devisCalcul: [],
      compteur: {},
      societe: {},
      auxData: {},

      // rules
      rules: {
        required: (value) => {
          let valid = false;
          if (typeof value == "number" && Number(value) >= 0) {
            valid = true;
          } else if (typeof value == "string" && value.toString().length > 0) {
            if (
              typeof Number(value) == "number" &&
              Number(value) != NaN &&
              Number(value) < 0
            ) {
              valid = false;
            } else {
              valid = true;
            }
          }
          return valid || "Ce champ est obligatoire.";
        },
        siretLength: (v) =>
          v.length == 14 || "Le siret doit comporter 14 chiffres",
        isString: (value) =>
          !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        isNumber: (v) =>
          /^[0-9]+$/.test(v) || "Ce champ n'accepte que les chiffres",
        isNumberOrFloat: (value) =>
          /^[0-9]+(\.\d+)?$/.test(value) ||
          "Ce champ n'accepte que les chiffres ou les nombres décimaux valides (ex. 50 ou 50.3)",
        isFloat: (v) =>
          /^-?\d*(\.\d+)?$/.test(v) ||
          "Ce champ doit être un nombre décimal valide(50.3)",
        hasItemSelected: (v) =>
          v.length >= 1 || "Vous devez selectionner aux moins une champ",
        greaterThanZero: (value) =>
          parseFloat(value) > 0 || "La valeur doit être supérieure à zéro.",
        accepteZero: (value) =>
          parseFloat(value) >= 0 || "La valeur doit être supérieure à zéro.",
      },
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
    showActionsBtns() {
      return (status) => {
        return (
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "valider"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "renitialiser"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "annuler"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "signer"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "envoyer_signiature"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "refusee"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "pre_validation"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "create_VT"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "create_VI"
          )
        );
      };
    },
  },
  watch: {
    newLOT: {
      immediate: false,
      deep: true,
      handler: function (val) {
        this.onNewMaterialAdded();       
      },
    },
  },
  async mounted() {
    await this.fetchDevisDetail();
  },

  methods: {
    updateTotalHT(indexSubLOTObject, indexMaterial) {
      const material =
        this.newLOT.items[indexSubLOTObject].items[indexMaterial];
      if (!material) return;

      const quantite = parseFloat(material.quantite) || 0;
      const PUHT = parseFloat(material.PUHT) || 0;
      this.$set(this.newLOT.items[indexSubLOTObject].items, indexMaterial, {
        ...material,
        totalHT: quantite * PUHT,
      });
    },
    onNewMaterialAdded() {
      let sumOfSubLOTTotal = 0;
      for (let idxSubLOT = 0; idxSubLOT < this.newLOT.items.length; idxSubLOT++) {
        const subLOT = this.newLOT.items[idxSubLOT];
        // calculate sublot total from materials total
        let sumOfMaterialTotal = 0;
        for (let idxMaterial = 0; idxMaterial < subLOT.items.length; idxMaterial++) {
          const material = subLOT.items[idxMaterial];
          const totalHT = parseFloat(material.totalHT) || 0;
          sumOfMaterialTotal += totalHT;
        }
        // update sub-lot total
        this.$set(subLOT, 'total', sumOfMaterialTotal);
        sumOfSubLOTTotal += sumOfMaterialTotal;
      }
      // update lot total
      this.$set(this.newLOT, 'total', sumOfSubLOTTotal);
    },
    CreerUneVisteTechnique(id_devis) {
      const props = {
        id_devis: id_devis,
      };
      this.$router.push({
        path: "/visite-technique/nouveau-formulaire",
        query: props,
      });
    },
    CreerUneVisteInstallation(id_devis) {
      const props = {
        id_devis: id_devis,
      };
      this.$router.push({
        path: "/visite-installation/ajoutVisiteInstallation",
        query: props,
      });
    },
    async fetchDevisDetail() {
      // get compteur detail with id using axios
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAPDBornById/",
        method: "POST",
        data: {
          id: this.$route.params.id,
          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data?.status == true && response.data?.data) {
            const responseData = response.data;
            this.devisDetail = responseData.data;
            this.societe = responseData.data.societe;
            this.compteur = responseData.data.compteur;
            this.devisCalcul = responseData.data.devisCalcul;
            this.auxData = responseData.data.titlesAux;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    // creer un devis
    async ActionsBtns(id, status, actionMessage) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: actionMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = true;
          this.loading = true;
          this.error = false;
          var formData = new FormData();
          formData.append("id", id);
          formData.append("status", status);
          formData.append("token", this.$store.getters.getUserToken);
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "changeAPDBornStatus/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.status == 200) {
                // update the status of the devis
                this.devisDetail.status = status;
              }
            })
            .catch((err) => {
              console.log(err);
            }).finally(()=>{
              this.dialog = false;
              this.loading = false;
            });
        }
      });
    },

    // add material
    async addNewMaterial({ lot }) {
      const request = {
        token: this.$cookies.get("token"),
        id_Devis: this.$route.params.id,
        typeDevis: 'APD',
        objects: lot,
      }
      console.log(request);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "addNewMaterial/",
        method: "POST",
        data: request,
      }).then((res) => {
          if (res.data.status == true) {
            this.fetchDevisDetail();
          }
      }).catch((error) => {
        console.log(error)
        })
    },
    async updateLotTotal({ lot }) {
      const request = {
        token: this.$cookies.get("token"),
        id_Devis: this.$route.params.id,
        typeDevis: 'APD',
        objects: lot,
      }

      this.loading = true;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "updateTotalLot/",
        method: "POST",
        data: request,
      }).then((res) => {
          if (res.data.status == true) {
            this.fetchDevisDetail();
            this.loading = false;
          }
      }).catch((error) => {
        console.log(error)
        this.loading = false;
      })
    },
    async deleteLOTMaterial({ lot }) {
      const request = {
        token: this.$cookies.get("token"),
        id_Devis: this.$route.params.id,
        typeDevis: "APD",
        objects: lot,
      };
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "deleteLOTMaterial/",
        method: "POST",
        data: request,
      }).then((res) => {
        if (res.data.status == true) {
          this.fetchDevisDetail();
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    // modal add LOT
    async addNewLOT(event) {
      event.preventDefault();
      if (this.$refs.addLOTForm.validate()) {
        console.log(this.newLOT);
        this.loading = true;
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateAPDBorn/",
          method: "POST",
          data: {
            token: this.$cookies.get("token"),
            id_apdBorn: this.$route.params.id,
            lot: this.newLOT,
          },
        }).then((res) => {
            if (res.data.status == true) {
              this.closeModalAddLOT();
              this.fetchDevisDetail();
          }else{
              this.closeModalAddLOT();
            }
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
            this.loading = false;
        })
      }
    },

    async deleteLotObject({title}) {
      this.loading = true;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "deleteLotFromApd/",
        method: "POST",
        data: {
          token: this.$cookies.get("token"),
          id_apdBorn: this.$route.params.id,
          title: title,
        },
      })
        .then((res) => {
          if (res.data.status == true) {
            this.fetchDevisDetail();
          }
        })
        .catch((error) => {
          console.log(error);
      }).finally(() => {
          this.loading = false;
        });
    },
    ajouterSubLOT(event) {
      event.preventDefault();
      this.newLOT.items.push({
        subtitle: "",
        total: "",
        items: [
          {
            description: "",
            unite: "U",
            quantite: 1,
            PUHT: 0,
            aux: true,
            new: false,
            totalHT: 0,
          },
        ],
      });
    },
    ajouterSubLOTMaterial(event, index) {
      event.preventDefault();
      this.newLOT.items[index].items.push({
        description: "",
        unite: "U",
        quantite: 1,
        PUHT: 0,
        aux: true,
        new: false,
        totalHT: 0,
      });
    },
    removeSubLOT(event, index) {
      event.preventDefault();
      this.newLOT.items.splice(index, 1);
    },
    removeMaterial(event, indexSubLOTObject, indexMaterial) {
      event.preventDefault();
      this.newLOT.items[indexSubLOTObject].items.splice(indexMaterial, 1);
    },
    openModalAddLOT(event) {
      event.preventDefault();
      this.modalAddLOT = true;
    },
    closeModalAddLOT(event) {
      event?.preventDefault();
      this.modalAddLOT = false;
      this.newLOT = {
        title: "",
        total: "",
        items: [
          {
            subtitle: "",
            total: "",
            items: [
              {
                description: "",
                unite: "U",
                quantite: 1,
                PUHT: 0,
                totalHT: 0,
              },
            ],
          },
        ],
      };
    },
  },
};
</script>
<style scoped>
/* @import "./style/profil.css"; */
.breadcrumb {
  background-color: #ffffff !important;
}
.containe_comp {
  background-color: #f2f2f2 !important;
  border-radius: 10px;
  padding: 50px 70px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
}
.row {
  margin-top: 3px !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
}
.card-block {
  flex: 1 1 auto;
  padding: 1.25rem;
  background-color: #fff;
}
.body-header {
  color: #192946;
}
.icon {
  width: 58px;
  height: 58px;
  top: 0;
  bottom: 0;
  right: 16px;
  margin: auto;
  background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
    rgb(0, 121, 145);
  border-radius: 7px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.12);
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
}
.text-button {
  color: #192946;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
}
.text-button:hover {
  color: #1e3766;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
  text-decoration: underline;
}
.text-yellow {
  color: #f7b924;
}
.stext-yellow {
  color: #ddb24c;
}
.stext-green {
  color: #97ccc1;
}
.stext-red {
  color: #c29b9d;
}
.born {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  background-color: #dddddd59;
}
.text-green {
  color: #4e9686;
}
.toggle-icon.show_borne + .born {
  max-height: 350px;
}
.bgc-primary {
  background-color: #396c99;
}
.bgc-primary:hover {
  background-color: #1e4c66;
}
.bgc-success {
  background-color: #2fb8ac;
}
.bgc-warning {
  background-color: #868685;
}
.card-title {
  padding: 0.45rem 0.825rem;
  margin-bottom: 10;
  background-color: #ffffff;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
  font-size: 1.25rem;
  color: #1d3258;
}
.card-header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e6e6f2 !important;
}

.v-application ul {
  padding-left: 10px;
}
.info-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}
.f-w-600 {
  font-weight: 600;
}
.m-b-20 {
  margin-bottom: 20px;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
h6 {
  font-size: 14px;
  font-weight: 500;
  color: #218f86;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-t-40 {
  margin-top: 40px;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.sub-row {
  color: #727272;
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}

.text-muted {
  color: #878a8a !important;
  font-weight: 550 !important;
}
.subtitle {
  font-size: 1.05rem;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: 0.02em;
  box-shadow: 1px 0px 1px 0px #e6e6f2;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  overflow-x: hidden !important;
}
.table-header {
  background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
    rgb(0, 121, 145) !important;
  padding: 1.25rem 1.5rem;
  font-size: 1.05rem;
  color: #ffffff;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  border-radius: 10px 10px 0px 0px;
}
.page-title {
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 300;
}
.brc-default-l1 {
  border-color: #dce9f0 !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-grey-m2 {
  color: #888a8d !important;
}

.text-success-m2 {
  color: #86bd68 !important;
}

.font-bolder,
.text-600 {
  font-weight: 600 !important;
}

.text-110 {
  font-size: 110% !important;
  font-weight: 600 !important;
}
.text-blue {
  color: #478fcc !important;
}
.pb-25,
.py-25 {
  padding-bottom: 0.75rem !important;
}

.pt-25,
.py-25 {
  padding-top: 0.75rem !important;
}
.bgc-default-tp1 {
  background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
    rgb(0, 121, 145) !important;
}
.bgc-default-l4,
.bgc-h-default-l4:hover {
  background-color: #f3f8fa !important;
}
.page-header .page-tools {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.btn-light {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #dddfe4;
}
.w-2 {
  width: 1rem;
}

.text-120 {
  font-size: 135% !important;
}
.text-primary-m1 {
  color: #4087d4 !important;
}

.text-danger-m1 {
  color: #dd4949 !important;
}
.text-blue-m2 {
  color: #68a3d5 !important;
}
.text-150 {
  font-size: 150% !important;
  font-weight: 500 !important;
}
.text-60 {
  font-size: 60% !important;
}
.text-grey-m1 {
  color: #7b7d81 !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.hid_borne {
  display: none;
  /*add transition animation*/
  transition-property: #103a5e;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  transition-delay: 0.3s;
}
.show_borne {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}
.show_borne.hidden {
  opacity: 0;
}
.subtitle .tr-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtitle .toggle-icon {
  cursor: pointer;
}
.subtitle .tr-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side-info p {
  font-size: 14px;
}
.divider {
  margin-top: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #acafaf;
}
</style>
