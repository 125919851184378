<template>
  <div class="container d-flex flex-row justify-center align-center">
    <v-row v-if="loading || errorModal">
      <loadingComponent
        @closeDialog="close($event)"
        :loadingProps="loading"
        :messageDialogeProps="messageDialoge"
        :dialogProps="dialog"
        :errorProps="errorModal"
      />
    </v-row>
    <v-card>
      <v-toolbar
        class="white--text"
        :style="{ background: $styles.background }"
      >
        <span class="pl-0">Rentrer une opportunité</span>
      </v-toolbar>
      <div class="d-flex flex-column">
        <v-stepper
          style="border-top-left-radius: 0px; border-top-right-radius: 0px"
          elevation="0"
          v-model="e1"
        >
          <v-stepper-header style="box-shadow: 0 0 0 transparent">
            <v-stepper-step
              :color="$colors[0]"
              :complete="e1 > 1"
              step="1"
              @click="e1 > 1 ? (e1 = 1) : (e1 = e1)"
            >
              Pro Ou Particulier
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :color="$colors[0]"
              :complete="e1 > 2"
              step="2"
              @click="e1 > 2 ? (e1 = 2) : (e1 = e1)"
            >
              {{
                typeClient == "Pro"
                  ? "Recherche Entreprise"
                  : "Recherche Particulier"
              }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :color="$colors[0]"
              :complete="e1 > 3"
              step="3"
              @click="e1 > 3 ? (e1 = 3) : (e1 = e1)"
            >
              {{
                typeClient == "Pro"
                  ? "Informations Entreprise"
                  : "Informations Particulier"
              }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :color="$colors[0]"
              :complete="e1 > 4"
              step="4"
              @click="e1 > 4 ? (e1 = 4) : (e1 = e1)"
            >
              Ajout Point(s)
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step :color="$colors[0]" step="5">
              Ajout Fichier(s)
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items style="height: 10%">
            <v-stepper-content step="1">
              <v-card elevation="0" class="d-flex flex-row">
                <TabSlider v-model="typeClient">
                  <template v-slot="{ selectedTab }">
                    <div v-if="selectedTab === 'Pro'">Pro</div>
                    <div v-else>Particulier</div>
                  </template></TabSlider
                >
              </v-card>
              <v-btn
                @click="nextStep()"
                :color="$colors[0]"
                class="white--text mt-10"
                >Suivant</v-btn
              >
            </v-stepper-content>
            <v-stepper-content class="pa-4" step="2">
              <v-card elevation="0" class="d-flex flex-row">
                <v-row class="ma-2">
                  <v-col class="pa-0" cols="12" style="height: 35%">
                    <v-form v-if="typeClient == 'Pro'" ref="formSiret">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="siret"
                            dense
                            :rules="[
                              $rules.required(`un siret valide`),
                              $rules.isSanitize,
                              $rules.isNumber,
                              $rules.compteurElecLength,
                            ]"
                            label="SIRET"
                            placeholder="SIRET"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-form v-if="typeClient == 'Particulier'" ref="formSiret">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="particulierEmail"
                            dense
                            :rules="[$rules.isEmail]"
                            label="Email"
                            placeholder="Email"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="
                  () => {
                    typeClient == 'Pro'
                      ? getSiretInformations()
                      : getParticulerInformations();
                  }
                "
                :color="$colors[0]"
                class="white--text mx-2"
                >Rechercher</v-btn
              >
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-form class="mt-4" ref="formInfo">
                <v-card elevation="0" class="mb-6 d-flex flex-row">
                  <v-row>
                    <v-col cols="12" sm="4" md="3" v-if="typeClient == 'Pro'">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Raison"
                        :rules="[
                          $rules.required(`une raison sociale valide`),
                          $rules.isSanitize,
                        ]"
                        disabled
                        v-model="societe.raison"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Adresse Postale"
                        :rules="[
                          $rules.required(`une adresse postale valide`),
                          $rules.isSanitize,
                        ]"
                        :disabled="adressePostalDisabled"
                        v-model="societe.adressePostal"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-select
                        label="Civilité"
                        dense
                        outlined
                        :items="selectionGenre"
                        v-model="societe.civile"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Nom"
                        :rules="[
                          $rules.required(`un nom valide`),
                          $rules.isSanitize,
                        ]"
                        v-model="societe.nom"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Prenom"
                        :rules="[
                          $rules.required(`un prénom valide`),
                          $rules.isSanitize,
                        ]"
                        v-model="societe.prenom"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Email"
                        :rules="[
                          $rules.required(`un Email valide`),
                          $rules.isSanitiz,
                          valideEmail,
                        ]"
                        v-model="societe.adresseEmail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Téléphone"
                        :rules="[
                          $rules.required(`un numéro de téléphone valide`),
                          $rules.isSanitize,
                          validePhoneNumber,
                        ]"
                        v-model="societe.numTele"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3" v-if="typeClient == 'Pro'">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Fonction"
                        :rules="[
                          $rules.required(`une Fonction valide`),
                          $rules.isSanitize,
                        ]"
                        v-model="societe.fonction"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        :min="new Date().toISOString().split('T')[0]"
                        type="date"
                        label="Date de devis"
                        :rules="[
                          $rules.required(`une date de devis valide`),
                          $rules.isSanitize,
                        ]"
                        v-model="societe.dateDevis"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-form>
              <v-btn
                class="mr-2 white--text"
                :color="$colors[0]"
                @click="validateInfo()"
              >
                Valider
              </v-btn>
              <v-btn text @click="e1 = 1"> Annuler </v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-card class="mb-12" height="300px;">
                <v-form ref="formValidePdls1">
                  <v-row>
                    <v-col cols="12">
                      <v-divider class="mt-2"></v-divider>
                      <p>Ajout PDL</p>
                      <v-row>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                          <v-text-field
                            outlined
                            v-model="num_comp"
                            :rules="[
                              $rules.requiredItem('un PDL valide'),
                              $rules.isNumber,
                              $rules.compteurElecLength,
                            ]"
                            label="Compteur éléctrique"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
              <v-btn
                class="mr-2 white--text"
                :color="$colors[0]"
                @click="verifierListeCompteurExiste()"
              >
                Valider
              </v-btn>
              <v-btn text @click="e1 = 2"> Annuler </v-btn>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-card elevation="0">
                <v-combobox
                  v-model="fichierChoisi"
                  :items="choixFichiers"
                  outlined
                  dense
                ></v-combobox>
                <v-form ref="formFiles">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <uploadFile
                      :fileProps="files[fichierChoisi]"
                      :name="fichierChoisi"
                      :maxNumberFileProps="maxNumberFile"
                      :fileRequired="fileRequired"
                      @maxNumberFile="4"
                      @FileData="retrieveFile($event, fichierChoisi)"
                    />
                    <!--<listFile
                      :isVisible="true"
                      :file="files[fichierChoisi]"
                      @remove="refreshListFiles($event, fichierChoisi)"
                    />-->
                  </div>
                </v-form>
                <div
                  style="border: orange solid 1px; text-align: center"
                  v-if="error == true"
                >
                  <p style="color: orange">{{ errorAlertText }}</p>
                </div>
              </v-card>
              <v-btn
                class="white--text mr-2"
                :color="$colors[0]"
                @click="submit()"
              >
                Finaliser
              </v-btn>
              <v-btn text @click="e1 = 4"> Annuler </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <!---->
      </div>
    </v-card>
  </div>
</template>
<script>
import loadingComponent from "@/components/Loading";
import listFile from "../components/listFile.vue";
import uploadFile from "../components/uploadFile.vue";
import axios from "@/plugins/axios";
import TabSlider from "@/components/TabSlider.vue";

export default {
  name: "NouvelleOpportunite",
  components: {
    uploadFile: uploadFile,
    listFile: listFile,
    TabSlider,
    loadingComponent: loadingComponent,
  },
  data() {
    return {
      //
      responseStatus: 0,
      dialog: false,
      loading: false,
      messageDialoge: "",
      typeClient: "Pro",
      errorModal: false,
      adressePostalDisabled: false,
      //
      num_comp: null,
      isLoading: false,
      files: {
        ACD: [],
        Contrat: [],
        Facture: [],
        Plans: [],
      },
      listDesBornes: [],
      listPrixParBorne: [],
      listMarque: [
        "Autel",
        "Autel/TOTEM",
        "Hager",
        "Hager/TOTEM",
        "AutelResidentiel",
      ],
      listMontage: ["MURAL", "PIED"],
      listTypePointDeRecharge: ["1 X 7KW", "2 X 7KW", "1 X 22KW", "2 X 22KW"],
      listSOL: ["SOL LEGER + 10M", "SOL LEGER + 0-5M"],
      loadingListPrixParBorne: false,
      headerListDesBornes: [
        { label: "Nbr borne" },
        { label: "Type PDR" },
        { label: "Montage" },
        { label: "ML int." },
        { label: "ML ext." },
        { label: "SOL" },
        { label: "Tranchée" },
        { label: "Action" },
      ],
      maxNumberFile: 4,
      fileRequired: false,
      choixFichiers: ["ACD", "Facture", "Contrat", "Plans"],
      fichierChoisi: "ACD",
      e1: 1,
      selectionGenre: ["Monsieur", "Madame"],
      siret: "",
      particulierEmail: "",
      societe: {
        Siret: this.siret,
        nom: "",
        prenom: "",
        adresseEmail: "",
        numTele: "",
        fonction: "",
        codeNaf: "",
        codeInsee: "",
        raison: "",
        adressePostal: "",
        codePostal: "",
        commune: "",
        voie: "",
        statusSociete: "",
        RCS: "",
        TVA: "",
        civile: "",
        formJu: "",
        dateDevis: "",
      },
      errorAlertText: "",
      error: false,
      rules: {
        required: (value) => {
          let valid = false;
          if (typeof value == "number" && Number(value) >= 0) {
            valid = true;
          } else if (typeof value == "string" && value.toString().length > 0) {
            if (
              typeof Number(value) == "number" &&
              Number(value) != NaN &&
              Number(value) < 0
            ) {
              valid = false;
            } else {
              valid = true;
            }
          }
          return valid || "Ce champ est obligatoire.";
        },
        siretLength: (v) =>
          v.length == 14 || "Le siret doit comporter 14 chiffres",
        isEmail: (value) =>
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
          "email invalide",
        isString: (value) =>
          !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        isNumber: (v) =>
          /^[0-9]+$/.test(v) || "Ce champ n'accepte que les chiffres",
        isNumberOrFloat: (value) =>
          /^[0-9]+(\.\d+)?$/.test(value) ||
          "Ce champ n'accepte que les chiffres ou les nombres décimaux valides (ex. 50 ou 50.3)",
        isFloat: (v) =>
          /^-?\d*(\.\d+)?$/.test(v) ||
          "Ce champ doit être un nombre décimal valide(50.3)",
        hasItemSelected: (v) =>
          v.length >= 1 || "Vous devez selectionner aux moins une champ",
        greaterThanZero: (value) =>
          parseFloat(value) > 0 || "La valeur doit être supérieure à zéro.",
        accepteZero: (value) =>
          parseFloat(value) >= 0 || "La valeur doit être supérieure à zéro.",
      },
    };
  },
  methods: {
    // add borne to listDesBornes
    addBorne() {
      this.listDesBornes.push({
        nbr_borne: 1,
        type_point_recharge: "1 X 7KW",
        montage: "MURAL",
        ml_interieur: 0,
        ml_exterieur: 0,
        sol: "SOL LEGER + 10M",
        metre_tranche: 0,
      });
    },
    // remove borne from listDesBornes
    removeBorne(index) {
      // Confirm if the user really wants to remove the item
      this.$swal({
        title: "Êtes-vous sûr de vouloir supprimer cette borne ?",
        text: "Cette action est irréversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          // Remove the item at the given index from listBorneSelected array
          this.listDesBornes.splice(index, 1);
        }
      });
    },
    close(event) {
      this.dialog = event;
      this.errorModal = event;
      if (this.responseStatus === 401) {
        this.$store.dispatch("logout");
      }
    },
    clickMenu(clikedElement) {
      this.e1 = clikedElement;
    },
    changeCompteurSelected() {},
    async submit() {
      if (
        this.files["ACD"].length === 0 &&
        this.files["Contrat"].length === 0 &&
        this.files["Facture"].length === 0
      ) {
        this.dialog = true;
        this.loading = false;
        this.messageDialoge = "Sélectionnez au moins une fichier!";
        this.errorModal = true;
      } else {
        this.dialog = true;
        this.loading = true;
        this.messageDialoge = "";
        this.errorModal = false;
        var formData = new FormData();
        if (this.typeClient == "Pro") {
          formData.append("societe", JSON.stringify(this.societe));
        } else if (this.typeClient == "Particulier") {
          formData.append("dataParticulier", JSON.stringify(this.societe));
        }
        formData.append("dateDevis", this.societe.dateDevis);
        formData.append("typeClient", this.typeClient);
        formData.append("PDLs", this.num_comp);
        formData.append("token", this.$cookies.get("token"));
        for (var i = 0; i < this.files.Contrat.length; i++) {
          //lesDonner["Contrat"+i] = this.files.Contract[i]
          formData.append("Contrat" + i, this.files.Contrat[i]);
        }
        for (var i = 0; i < this.files.ACD.length; i++) {
          //lesDonner["ACD"+i] = this.files.ACD[i]
          formData.append("ACD" + i, this.files.ACD[i]);
        }
        for (var i = 0; i < this.files.Facture.length; i++) {
          //lesDonner["Facture"+i] = this.files.Facture[i]
          formData.append("Facture" + i, this.files.Facture[i]);
        }
        for (var i = 0; i < this.files.Plans.length; i++) {
          formData.append("Plans" + i, this.files.Plans[i]);
        }
        formData.append("listDesBornes", JSON.stringify(this.listDesBornes));
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "ajouterDemandeDeCotation/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              this.dialog = true;
              this.loading = false;
              this.messageDialoge = "Demande de cotation ajouter";
              this.errorModal = false;
            } else {
              this.dialog = true;
              this.loading = false;
              this.messageDialoge = res.data.toastMessage;
              this.errorModal = true;
            }
          })
          .catch((err) => {
            this.dialog = true;
            this.loading = false;
            this.messageDialoge = "une erreur technique est survenue";
            this.errorModal = true;
          });
      }
    },
    refreshListFiles(file, typeFichier) {
      this.files[typeFichier] = file;
      this.maxNumberFile = 4;
    },
    CheckNumberFile(e) {
      this.maxNumberFile = e;
    },
    retrieveFile(event, typeFichier) {
      this.files[typeFichier] = event;
    },
    async getParticulerInformations() {
      if (this.$refs.formSiret.validate()) {
        this.dialog = true;
        this.loading = true;
        this.messageDialoge = "";
        this.errorModal = false;
        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT +
            "get_ProfileParticulier_information/",
          method: "POST",
          data: {
            token: this.$cookies.get("token"),
            email: this.particulierEmail,
          },
        })
          .then(async (response) => {
            let responseData = response.data;
            this.responseStatus = response.status;
            this.loading = false;
            this.dialog = true;
            if (this.responseStatus === 200) {
              switch (responseData.result) {
                case true:
                  this.dialog = false;
                  this.messageDialoge = "";
                  this.societe = responseData.data;
                  this.adressePostalDisabled = true;
                  this.e1 = this.e1 + 1;
                  this.errorModal = false;
                  break;
                case false:
                  this.e1 = this.e1 + 1;
                  this.societe.adresseEmail = this.particulierEmail;
                  this.adressePostalDisabled = false;
                  break;
                default:
                  break;
              }
            } else if (this.responseStatus === 401) {
              this.messageDialoge = responseData.result;
              this.errorModal = true;
            } else {
              this.messageDialoge = data.result;
              this.errorModal = true;
            }
          })
          .catch((err) => {
            this.dialog = true;
            this.loading = false;
            this.messageDialoge = "une erreur technique est survenue";
            this.errorModal = true;
          });
      }
    },
    async getSiretInformations() {
      if (this.$refs.formSiret.validate()) {
        this.dialog = true;
        this.loading = true;
        this.messageDialoge = "";
        this.errorModal = false;
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getSiretInformations/",
          method: "POST",
          data: {
            token: this.$cookies.get("token"),
            siret: this.siret,
          },
        })
          .then(async (response) => {
            let responseData = response.data;
            this.responseStatus = response.status;
            this.loading = false;
            this.dialog = true;
            if (this.responseStatus === 200) {
              switch (responseData.result) {
                case true:
                  this.dialog = false;
                  this.messageDialoge = "";
                  this.societe = responseData.data;
                  this.e1 = this.e1 + 1;
                  this.errorModal = false;
                  break;
                case false:
                  this.messageDialoge = "Verifer que votre siret est correct";
                  this.errorModal = true;
                  break;
                default:
                  break;
              }
            } else if (this.responseStatus === 401) {
              this.messageDialoge = responseData.result;
              this.errorModal = true;
            } else {
              this.messageDialoge = data.result;
              this.errorModal = true;
            }
          })
          .catch((err) => {
            this.dialog = true;
            this.loading = false;
            this.messageDialoge = "une erreur technique est survenue";
            this.errorModal = true;
          });
      }
    },
    async nextStep() {
      this.e1 = this.e1 + 1;
    },

    validateInfo() {
      if (this.$refs.formInfo.validate()) {
        this.e1 = this.e1 + 1;
      }
    },
    /*async recherchePointes() {
      this.dialog = true;
      this.loading = true;
      this.messageDialoge = "";
      this.errorModal = false;
      if (this.$refs.formRecherchePdls.validate()) {
        await fetch(process.env.VUE_APP_URL_API_CLIENT + "getListeDesPoints/", {
          method: "POST",
          body: JSON.stringify({
            adresseUser: this.societe.adressePostal,
            codeInsee: this.societe.codeInsee,
            raison: this.societe.raison,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (!("error" in data) && !("errorPostal" in data)) {
              this.listeCompteurs = data.listCompteur;
              this.dialog = false;
              this.loading = false;
              this.messageDialoge = "";
              this.errorModal = false;
            } else {
              this.listeCompteurs = data.listCompteur;
              this.dialog = true;
              this.loading = false;
              this.messageDialoge = "Il n'existe aucune PDL reliée à cette adresse.";
              this.errorModal = true;
            }
          })
          .catch((err) => {
            this.dialog = true;
            this.loading = false;
            this.messageDialoge = "une erreur technique est survenue";
            this.errorModal = true;
          });
      }
    },*/
    async verifierListeCompteurExiste() {
      if (this.num_comp) {
        this.dialog = true;
        this.loading = true;
        this.messageDialoge = "";
        this.errorModal = false;
        if (this.$refs.formValidePdls1.validate()) {
          var formDonne = new FormData();
          formDonne.append("PDLS", this.num_comp);
          await axios({
            url:
              process.env.VUE_APP_URL_API_CLIENT +
              "verifierListeCompteurExiste/",
            method: "POST",
            data: formDonne,
          })
            .then((response) => {
              var data = response.data;
              if (data["result"] == "valide") {
                this.dialog = false;
                this.loading = false;
                this.messageDialoge = "";
                this.errorModal = false;
                this.e1 = this.e1 + 1;
              } else {
                this.dialog = true;
                this.loading = false;
                this.messageDialoge =
                  "le compteur n°" + data.compteurError + " n'existe pas";
                this.errorModal = true;
              }
            })
            .catch((err) => {
              this.dialog = true;
              this.loading = false;
              this.messageDialoge = "une erreur technique est survenue";
              this.errorModal = true;
            });
        }
      } else {
        this.dialog = true;
        this.loading = false;
        this.messageDialoge = "Sélectionnez au moins un compteur!";
        this.errorModal = true;
      }
    },
  },
  computed: {
    valideEmail() {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      if (emailPattern.test(this.societe.adresseEmail)) {
        return true;
      } else {
        return "Email invalide";
      }
    },
    validePhoneNumber() {
      // phone numver pattern is a string that contains 10 numeric digits starting with 0
      const phonePattern = /^\d{10}$/;
      if (phonePattern.test(this.societe.numTele)) {
        return true;
      } else {
        return "Numéro de téléphone invalide";
      }
    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.wrapper {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background: #fff;
  .search-siret-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 32px;

    .search-siret-input {
      max-width: 450px;
      height: 50px !important;
    }
    .search-siret-btn {
      text-transform: capitalize;
      background-color: #009180;
      color: #fff;
      font-size: 14px;
      font-family: inherit;
      font-weight: 400;
      letter-spacing: 1px;
      border-radius: 4px;
      padding: 20px 24px;
      height: 55px;
    }
  }
  .card-white {
    width: 100%;
    margin-bottom: 32px;
    border-radius: 8px;
  }
  .card-info-societe {
    padding: 20px 24px;
    .header-title {
      margin-bottom: 20px;
      border-bottom: 1px solid #eaecf0;
      .title {
        font-size: 18px;
        font-family: inherit;
        font-weight: 500;
        color: #007991;
        margin-bottom: 10px;
      }
    }
    .content {
      .info-item-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 20px;

        .info-item {
          flex: 1;
          flex-basis: 180px;
        }
        .address {
          min-width: 280px;
        }
      }
      .info-item {
        .info-item-label {
          font-size: 12px;
          font-family: inherit;
          font-weight: 400;
          color: #667085;
          margin-bottom: 8px;
        }
        .info-item-value {
          font-size: 16px;
          font-family: inherit;
          font-weight: 600;
          color: #1e1e1e;
          margin-bottom: 0px;
        }
      }
    }
  }
  .light-table-wrapper {
    width: 100%;
    overflow-x: auto;
    .light-table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border: 0;

      .action {
        width: 50px;
      }

      thead {
        tr {
          background-color: #00b5a0;
          th {
            width: 13%;
            font-size: 14px;
            font-weight: 500;
            font-family: inherit;
            padding: 15px 15px;
            color: #fff;
            border-top: solid 1px #eaecf0;
            border-bottom: solid 1px #eaecf0;
          }
        }
      }

      tbody {
        tr {
          background-color: #fff;
          td {
            font-size: 14px;
            font-weight: 500;
            font-family: inherit;
            color: #272c36;
            text-align: left;
            padding: 20px 15px;
            vertical-align: middle;
            border-bottom: solid 1px #eaecf0;
          }
        }
      }
    }
  }
  .card-borne-list {
    .header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 20px 20px;
      .title {
        font-size: 18px;
        font-family: inherit;
        font-weight: 500;
        color: #007991;
        margin-bottom: 0px;
      }
      .add-borne-btn {
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
        padding: 2px 24px;
        color: #344054;
        height: 45px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 20px 20px;
      .valider-btn {
        display: block;
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
        padding: 2px 24px;
        color: #fff;
        height: 45px;
        background-color: #009180;
        border-radius: 8px;
        border: 1px solid #009180;
        &:disabled {
          background-color: #9cccc6;
          border: 1px solid #9cccc6;
        }
      }
    }
  }
  .card-borne-list-by-price {
    .header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 20px 20px;
      background-color: #d1e0e8;
      border-radius: 8px 8px 0px 0px;
      .title {
        font-size: 18px;
        font-family: inherit;
        font-weight: 500;
        color: #1e1e1e;
        margin-bottom: 0px;
      }
    }

    .questions-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 20px;
      // display: flex;
      // flex-direction: column;
      .question {
        display: flex;
        flex-direction: column;
        // flex-direction: row;
        // align-items: center;
        // flex-wrap: wrap;
        // margin-bottom: 15px;
        .question-label {
          font-size: 12px;
          font-family: inherit;
          font-weight: 400;
          color: #667085;
          margin: 0px;
          margin-right: 20px;
        }
        .question-field {
          margin: 0px;
          min-width: 80px;
          max-width: 250px;
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 20px 20px;
      .valider-btn {
        display: block;
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
        padding: 2px 24px;
        color: #fff;
        height: 45px;
        background-color: #009180;
        border-radius: 8px;
        border: 1px solid #009180;
      }
    }

    .signalitique-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .table-signalitique-wrapper {
        width: 100%;
        flex: 1;
        flex-basis: 400px;
        .table-signalitique {
          width: 100%;
          border-collapse: collapse;
          border-spacing: 0;
          border: 0;
          thead {
            tr {
              background-color: #14324f;
            }
            th,
            td {
              font-size: 16px;
              color: #fff;
              text-align: center;
              padding: 10px;
              border-top: solid 1px #eaecf0;
              border-bottom: solid 1px #eaecf0;
            }
          }
          tbody {
            tr {
              background-color: #fff;
            }
            th,
            td {
              font-size: 14px;
              font-weight: 500;
              font-family: inherit;
              color: #344054;
              text-align: center;
              vertical-align: middle;
              padding: 15px 20px;
              border-bottom: solid 1px #eaecf0;
            }
            .value-title {
              font-size: 14px;
              font-weight: 600;
              color: #00b5a0;
            }
          }
        }
      }
    }
  }
}

/* loader */
.loader {
  width: 24px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid #ffffff;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}
@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}
@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}
</style>
