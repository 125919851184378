import { render, staticRenderFns } from "./listAPD.vue?vue&type=template&id=6a4b4054&scoped=true&"
import script from "./listAPD.vue?vue&type=script&lang=js&"
export * from "./listAPD.vue?vue&type=script&lang=js&"
import style0 from "./listAPD.vue?vue&type=style&index=0&id=6a4b4054&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a4b4054",
  null
  
)

export default component.exports